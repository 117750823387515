import React from 'react';

export const useOnScroll = <T extends HTMLElement | Window>(
  ref: React.RefObject<T>,
  onScroll: (event: Event) => void,
) => {
  React.useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', onScroll);
      return () => currentRef.removeEventListener('scroll', onScroll);
    }
  }, [onScroll, ref]);
};
