import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export const EditorSessionIdContext = React.createContext({
  editorSessionId: '',
  refreshEditorSessionId: () => void 0,
});

export const useEditorSessionId = () => React.useContext(EditorSessionIdContext);

export const EditorSessionIdProvider: React.FC<{ initialEditorSessionId: string }> = ({
  children,
  initialEditorSessionId,
}) => {
  const [editorSessionId, setEditorSessionId] = React.useState(initialEditorSessionId);
  const refreshEditorSessionId = React.useCallback(() => {
    setEditorSessionId(uuidv4());
  }, []);

  return (
    <EditorSessionIdContext.Provider value={{ editorSessionId, refreshEditorSessionId }}>
      {children}
    </EditorSessionIdContext.Provider>
  );
};
