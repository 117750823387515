import React from 'react';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { RoutingStore } from '../../stores/RoutingStore';
import { SeoLink } from '../SeoLink/SeoLink';
import { EMPTY_FILTERS } from '../../stores/routes/sortFiltersParams';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { filtersNoResultsDataHooks } from './FiltersNoResults.dataHooks';
import styles from './FiltersNoResults.scss';

interface LayoutsFilterProps extends WithTranslations {
  routingStore: RoutingStore;
  dataHook?: string;
}

const FiltersNoResultsCmp: React.FC<LayoutsFilterProps> = ({ routingStore, dataHook, t }) => {
  const resetLink = routingStore.rebuildRoute({
    page: 1,
    filters: EMPTY_FILTERS,
  });

  return (
    <div data-hook={dataHook} className={styles.root}>
      <p data-hook={filtersNoResultsDataHooks.title()} className={styles.title}>
        {t('filters.noResults.title')}
      </p>
      <p data-hook={filtersNoResultsDataHooks.description()} className={styles.description}>
        {t('filters.noResults.description')}
      </p>
      <SeoLink to={resetLink} dataHook={filtersNoResultsDataHooks.resetLink()} className={styles.resetLink}>
        {t('filters.noResults.resetLink')}
      </SeoLink>
    </div>
  );
};

export const FiltersNoResults = withTranslations(injectStoresV2('routingStore')(FiltersNoResultsCmp));
