import React from 'react';
import { GeoData } from '@wix/wix-common-aspects';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import s from './CbdBanner.scss';

interface CbdBannerProps extends WithTranslations {
  geoData: GeoData;
  dataHook?: string;
}

type cbdBannerCtaKey = 'banner.cbd.other.cta' | 'banner.cbd.usa.cta';
type cbdBannerTextKey = 'banner.cbd.other.body' | 'banner.cbd.usa.body';

const getCBDBannerContent = (geoData: GeoData): { cta: cbdBannerCtaKey; text: cbdBannerTextKey } => {
  if (geoData?.['2lettersCountryCode']?.toLowerCase() === 'us') {
    return { cta: 'banner.cbd.usa.cta', text: 'banner.cbd.usa.body' };
  }

  return { cta: 'banner.cbd.other.cta', text: 'banner.cbd.other.body' };
};

const CbdBannerCmp: React.FC<CbdBannerProps> = ({ dataHook, geoData, t }) => {
  const onBannerClick = () => {
    window.open(t('banner.cbd.link'), '_blank');
  };

  const { cta, text } = getCBDBannerContent(geoData);

  return (
    <div className={s.bannerBody} onClick={onBannerClick} data-hook={dataHook}>
      <span className={s.text}>{t(text)}</span>
      <span className={s.cta}>{t(cta)}</span>
    </div>
  );
};

export const CbdBanner = withTranslations(CbdBannerCmp);
