import React from 'react';
import ArrowRight from '../../../Icons/ArrowRightLong.svg';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import s from './DesignSiteLaterButton.scss';

interface Props extends WithTranslations {
  msid: string;
  onClick: () => void;
}

const DesignSiteLaterButtonCmp: React.FC<Props> = ({ t, msid, onClick }) => {
  const title = t('header.designSiteLater.button');
  const href = `https://manage.wix.com/dashboard/${msid}/setup`;

  return (
    <div onClick={onClick} className={s.buttonWrapper}>
      <a className={s.button} href={href}>
        <span>{title}</span>
        <ArrowRight />
      </a>
    </div>
  );
};

export const DesignSiteLaterButton = withTranslations(DesignSiteLaterButtonCmp);
