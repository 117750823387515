import { RenamedCategory } from '../../../config';

const searchTermsToRoutesForSuggestions: { [key: string]: string } = {
  blank: 'blank',
  'blank template': 'blank',
  'blank templates': 'blank',
  blog: 'blog',
  education: 'community-education/education',
  'travel & tourism': 'travel-tourism',
  'landing page': 'landing-pages',
  art: 'creative-arts',
  video: 'video',
  portfolio: 'portfolio-cv/portfolios',
  music: 'music',
  fashion: 'fashion-and-style/fashion',
  business: 'business',
  'beauty & hair': 'beauty-and-hair',
  photography: 'photography',
  health: 'health-wellness/health',
  landing: 'landing-pages',
  event: 'events',
  'online store': 'online-store',
  events: 'events',
  design: 'design',
  shop: 'online-store',
  communities: 'communities',
  resume: 'portfolio-cv/resumes-cvs',
  cv: 'portfolio-cv/resumes-cvs',
  restaurant: 'restaurants-food/restaurant',
  designer: 'design/designer',
  'landing pages': 'landing-pages',
  wedding: 'events/weddings',
  'real estate': 'business/real-estate',
  'car business': 'business/automotive-cars',
  'coming soon': 'landing-pages/coming-soon',
  'creative arts': 'creative-arts',
  'automotive & cars': 'business/automotive-cars',
};

function getRouteBySearchTerm({
  criteria,
  exactMatch,
  isBucketsABTest = false,
  temporaryRenamedCategories,
}: {
  criteria: string;
  exactMatch: boolean;
  isBucketsABTest: boolean;
  temporaryRenamedCategories: RenamedCategory;
}): string | null {
  const trimmedSearchTerm = criteria.trim().toLowerCase();

  const exactRoute = searchTermsToRoutesForSuggestions[trimmedSearchTerm];
  if (exactRoute) {
    if (isBucketsABTest && temporaryRenamedCategories[exactRoute]) {
      return temporaryRenamedCategories[exactRoute];
    }
    return exactRoute;
  }

  if (!exactMatch && trimmedSearchTerm.length >= 4) {
    const routeStartedWith = Object.entries(searchTermsToRoutesForSuggestions)
      .map(([term, route]) => ({ term, route }))
      .find(({ term }) => term.startsWith(trimmedSearchTerm));

    if (routeStartedWith) {
      if (isBucketsABTest && temporaryRenamedCategories[routeStartedWith.route]) {
        return temporaryRenamedCategories[routeStartedWith.route];
      }
      return routeStartedWith.route;
    }
  }

  if (trimmedSearchTerm.split(' ').includes('cbd')) {
    return 'online-store/cbd';
  }

  return null;
}

export function getSuggestedCategoryOrSubCategorySlug({
  criteria,
  exactMatch,
  isBucketsABTest,
  temporaryRenamedCategories,
}: {
  criteria: string;
  exactMatch: boolean;
  isBucketsABTest: boolean;
  temporaryRenamedCategories: RenamedCategory;
}): string | null {
  const suggestedRoute = getRouteBySearchTerm({ criteria, exactMatch, isBucketsABTest, temporaryRenamedCategories });
  if (!suggestedRoute) {
    return null;
  }

  const [categorySlug, subCategorySlug] = suggestedRoute.split('/');
  return subCategorySlug ?? categorySlug;
}
