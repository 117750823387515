import { action, computed, makeObservable, observable, reaction } from 'mobx';
import escapeRegExp from 'lodash/escapeRegExp';
import { ExperimentsStore } from '../../stores/ExperimentsStore';
import { RoutingStore } from './RoutingStore';
import { ConfigStore } from './ConfigStore';

interface HeaderStoreParams {
  routingStore: RoutingStore;
  experimentsStore: ExperimentsStore;
  configStore: ConfigStore;
}

export class HeaderStore {
  private readonly routingStore: RoutingStore;
  private readonly experimentsStore: ExperimentsStore;
  private readonly configStore: ConfigStore;

  public isSticky: boolean = false;
  public searchCriteria: string = '';
  public isSearchInputFocused: boolean = false;

  constructor({ routingStore, experimentsStore, configStore }: HeaderStoreParams) {
    makeObservable(this, {
      isSticky: observable,
      searchCriteria: observable,
      isSearchInputFocused: observable,
      searchCriteriaRegExp: computed,
      setSearchCriteria: action,
      setIsSticky: action,
      setSearchInputFocused: action,
    });

    this.routingStore = routingStore;
    this.experimentsStore = experimentsStore;
    this.configStore = configStore;

    const criteria =
      this.routingStore.matchedRoute?.routeName === 'search' ? this.routingStore.matchedRoute?.params.criteria : '';

    this.setSearchCriteria(criteria);

    this.listenForRouteChanges();
  }

  private listenForRouteChanges() {
    reaction(
      () => this.routingStore.matchedRoute,
      () => {
        const isSearchRoute = this.routingStore.matchedRoute.routeName === 'search';

        if (!isSearchRoute && !this.isSearchInputFocused) {
          this.setSearchCriteria('');
        }
      },
    );
  }

  public get searchCriteriaRegExp(): RegExp {
    return new RegExp(`(${escapeRegExp(this.searchCriteria.trim())})`, 'gi');
  }

  public get isLargeSearchInput(): boolean {
    return !this.isSticky && this.experimentsStore.is('specs.funnel.TemplatesGalleryEnlargedSearchInput', 'true');
  }

  setSearchCriteria = (value: string) => {
    this.searchCriteria = value;
  };

  setIsSticky = (value: boolean) => {
    this.isSticky = value;
  };

  setSearchInputFocused = (isFocused: boolean) => {
    this.isSearchInputFocused = isFocused;
  };
}
