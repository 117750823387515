import React from 'react';
import cs from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { RouteLocation } from '../../../stores/routes/createRoute';
import { SeoLink } from '../../SeoLink/SeoLink';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import styles from './FilterDropdownContent.scss';
import { filtersDropdownContentDataHooks } from './FiltersDropdownContent.dataHooks';

interface FilterDropdownContentProps extends WithTranslations {
  clearLink: RouteLocation;
  className?: string;
  isBorder: boolean;
  expanded?: boolean;
  onClear: () => void;
  isSelected: boolean;
  dataHook?: string;
}

const FilterDropdownContentCmp: React.FC<FilterDropdownContentProps> = ({
  children,
  className,
  clearLink,
  expanded,
  isBorder,
  onClear,
  isSelected,
  dataHook,
  t,
}) => {
  const dropdownEnterTimeout = parseInt(styles.dropdownEnterTimeout, 10);
  const dropdownLeaveTimeout = parseInt(styles.dropdownLeaveTimeout, 10);

  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClear && onClear();
  };

  return (
    <CSSTransition
      in={expanded}
      classNames={{
        enter: styles.dropdownEnter,
        enterActive: styles.dropdownEnterActive,
        exit: styles.dropdownLeave,
        exitActive: styles.dropdownLeaveActive,
      }}
      timeout={{ enter: dropdownEnterTimeout, exit: dropdownLeaveTimeout }}
      mountOnEnter
      unmountOnExit
    >
      <div data-hook={dataHook} className={cs(styles.dropdown, className)} onClick={(event) => event.stopPropagation()}>
        <div data-hook={filtersDropdownContentDataHooks.content()}>{children}</div>
        <div className={cs(styles.footer, { [styles.withBorder]: isBorder })}>
          <SeoLink
            to={clearLink}
            className={cs(styles.clear, { [styles.disabled]: !isSelected })}
            onClick={(event) => handleOnClick(event)}
            data-hook={filtersDropdownContentDataHooks.clearLink()}
          >
            {t('filters.clear')}
          </SeoLink>
        </div>
      </div>
    </CSSTransition>
  );
};

export const FilterDropdownContent = withTranslations(FilterDropdownContentCmp);
