import { CategoryTemplatesResponse } from '../web-api/domain/templatesResponse';
import { Template } from '../web-api/domain/template';

export const mapTemplatesToJson = (templates: Template[] = []): string | null => {
  return JSON.stringify({
    templates: templates.map((template, i) => ({
      json_id: template.id,
      gallerydocindex: i,
      template_id: template.siteId,
    })),
  });
};

export const mapTemplatesResponseToTemplateData = ({
  items,
  pagination: { current, pageSize, total },
}: CategoryTemplatesResponse): String => {
  return JSON.stringify({
    templates: items?.map((template, i) => ({
      json_id: template.id,
      template_id: template.siteId,
      source: template.source,
      current_page: current,
      page_position: i,
      page_size: pageSize,
      total_pages: total,
      section: 1,
      score: template.score,
    })),
  });
};
