import React from 'react';
import cs from 'classnames';
import styles from './LayoutIcon.scss';

interface InlineLayoutProps {
  active: boolean;
}

export const InlineLayout = ({ active = false }: InlineLayoutProps) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="92px"
      height="58px"
      viewBox="0 0 92 58"
      enableBackground="new 0 0 92 58"
      className={cs(styles.layoutIcon, { [styles.active]: active })}
    >
      <switch>
        <g>
          <g>
            <path
              fill="#D6E6FF"
              className={styles.fillBlue}
              d="M20.834,57.287v-11.61c0-0.394,0.319-0.713,0.713-0.713h51.352c0.394,0,0.713,0.319,0.713,0.713v11.61
				c0,0.394-0.319,0.713-0.713,0.713H21.547C21.153,58,20.834,57.681,20.834,57.287z"
            />
          </g>
          <path
            fill="none"
            stroke="#0C6EFC"
            className={styles.strokeBlue}
            strokeMiterlimit="10"
            d="M3.5,57.5h85c1.657,0,3-1.343,3-3v-51c0-1.657-1.343-3-3-3h-85
			c-1.657,0-3,1.343-3,3v51C0.5,56.157,1.843,57.5,3.5,57.5z"
          />
          <g>
            <path
              fill="#D6E6FF"
              className={styles.fillBlue}
              d="M20.834,37.775V8c0-0.552,0.448-1,1-1h50.779c0.552,0,1,0.448,1,1v29.775c0,0.552-0.448,1-1,1H21.834
				C21.281,38.775,20.834,38.327,20.834,37.775z"
            />
          </g>
          <g>
            <path
              fill="#0C6EFC"
              className={styles.fillWhite}
              d="M50.533,29.75h-6.619c-1.045,0-1.892-0.847-1.892-1.892v0c0-1.045,0.847-1.892,1.892-1.892h6.619
				c1.045,0,1.892,0.847,1.892,1.892v0C52.424,28.903,51.577,29.75,50.533,29.75z"
            />
            <g>
              <line
                fill="none"
                stroke="#0C6EFC"
                className={styles.strokeWhite}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="59"
                y1="16.025"
                x2="35.446"
                y2="16.025"
              />

              <line
                fill="none"
                stroke="#0C6EFC"
                className={styles.strokeWhite}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="55.42"
                y1="19.525"
                x2="39.026"
                y2="19.525"
              />
            </g>
          </g>
        </g>
      </switch>
    </svg>
  );
};
