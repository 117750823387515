import cs from 'classnames';
import React from 'react';
import { SeoLink } from '../../../SeoLink/SeoLink';
import FilterCheckmark from '../../../../../Icons/FilterCheckmark.svg';
import { FilterColorName } from '../../../FilterColorName/FilterColorName';
import { COLORS } from '../ColorsFilter';
import { Color, ColorStyles, EMPTY_FILTERS } from '../../../../stores/routes/sortFiltersParams';
import { RoutingStore } from '../../../../stores/RoutingStore';
import { BILoggerStore } from '../../../../stores/BILoggerStore';
import { injectStoresV2 } from '../../../../stores/injectStoresV2';
import styles from './ColorsFilterItem.scss';
import { colorsFilterItemDataHooks } from './ColorsFilterItem.dataHooks';

const colorToClassNameMapping: Record<Color | ColorStyles, string> = {
  bright: styles.bright,
  dark: styles.dark,
  colorful: styles.colorful,
  white: styles.white,
  black: styles.black,
  blue: styles.blue,
  red: styles.red,
  yellow: styles.yellow,
  green: styles.green,
  pink: styles.pink,
  orange: styles.orange,
  brown: styles.brown,
  purple: styles.purple,
  grey: styles.grey,
};

interface FilterColorsItemProps {
  filterId: Color | ColorStyles;
  dataHook?: string;
  routingStore: RoutingStore;
  biLoggerStore: BILoggerStore;
}

const ColorsFilterItemCmp: React.FC<FilterColorsItemProps> = ({ filterId, dataHook, biLoggerStore, routingStore }) => {
  const appliedFilters = routingStore.appliedFilters ?? EMPTY_FILTERS;
  const colorClassName = colorToClassNameMapping[filterId];
  // @ts-expect-error: Argument of type 'Color | ColorStyle' is not assignable to parameter of type 'Color'
  const isColor = COLORS.includes(filterId);
  const isActiveFilter = isColor
    ? appliedFilters.colors.includes(filterId)
    : appliedFilters.colorStyles.includes(filterId);
  const filtersArray = isColor ? appliedFilters.colors : appliedFilters.colorStyles;
  const valuesOnFilterSelect = isActiveFilter
    ? filtersArray.filter((id) => id !== filterId)
    : [...filtersArray, filterId];
  const valuesOnSelect = isColor
    ? {
        colors: valuesOnFilterSelect,
      }
    : {
        colorStyles: valuesOnFilterSelect,
      };

  const link = routingStore.rebuildRoute({
    page: 1,
    filters: {
      ...appliedFilters,
      ...valuesOnSelect,
    },
  });

  const onSelect = (event: React.MouseEvent) => {
    event.stopPropagation();
    const params = {
      previousValues: appliedFilters,
      selectedValues: {
        ...appliedFilters,
        ...valuesOnSelect,
      },
      clickedItemValue: filterId,
    };

    isActiveFilter
      ? biLoggerStore.logUnselectFilter({ type: 'colors', ...params })
      : biLoggerStore.logSelectFilter({ type: 'colors', ...params });
  };

  return (
    <SeoLink
      rel="nofollow"
      className={cs(styles.filterLink, { [styles.filterLinkActive]: isActiveFilter })}
      to={link}
      dataHook={cs(dataHook, colorsFilterItemDataHooks.item({ active: isActiveFilter }))}
      onClick={(event) => onSelect(event)}
    >
      <span className={cs(styles.color, colorClassName)}>
        <FilterCheckmark fill="currentColor" width="1em" height="1em" className={styles.checkMarkIcon} />
      </span>
      <span className={styles.title}>
        <FilterColorName color={filterId} />
      </span>
    </SeoLink>
  );
};

export const ColorsFilterItem = injectStoresV2('routingStore', 'biLoggerStore')(ColorsFilterItemCmp);
