import React from 'react';
import { Tooltip } from '../Tooltip/Tooltip';
import { FilterCollection, EMPTY_FILTERS, Feature } from '../../../stores/routes/sortFiltersParams';
import { FilterColorName } from '../../FilterColorName/FilterColorName';
import { FilterFeatureName } from '../../FilterFeatureName/FilterFeatureName';
import { FilterLayoutName } from '../../FilterLayoutName/FilterLayoutName';
import { Apps, getAppNameByDefId, getFeatureByAppName } from '../../../utils/appsAndFeatures';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { TemplatesStore } from '../../../stores/TemplatesStore';
import { filtersCount } from '../../../stores/RoutingStore';
import { Template } from '../../../../web-api/domain/template';
import s from './TemplateFilters.scss';
import { templateFiltersDataHooks } from './TemplateFilters.dataHooks';

const VISIBLE_FILTERS = 2;

interface TemplateFiltersProps {
  dataHook?: string;
  template: Template;
  templatesStore: TemplatesStore;
}

const TemplateFiltersCmp = ({ dataHook, template, templatesStore }: TemplateFiltersProps) => {
  const { loadedFetchParams, apps } = templatesStore;
  const filters = loadedFetchParams ? loadedFetchParams.filters : EMPTY_FILTERS;

  const matchedFilters = getMatchedFilters({ filters, template, apps });

  const featureFilters = matchedFilters.features.map((feature) => <FilterFeatureName feature={feature} />);
  const colorStyleFilters = matchedFilters.colorStyles.map((color) => <FilterColorName color={color} />);
  const colorFilters = matchedFilters.colors.map((color) => <FilterColorName color={color} />);
  const layoutFilters = matchedFilters.layouts.map((layout) => <FilterLayoutName layout={layout} />);

  const allFilters = [...featureFilters, ...colorStyleFilters, ...colorFilters, ...layoutFilters];

  const visibleItems = allFilters.slice(0, VISIBLE_FILTERS);
  const tooltipItems = allFilters.slice(VISIBLE_FILTERS);

  const tooltipContent = tooltipItems.map((filter, index) => (
    <React.Fragment key={index}>
      {index !== 0 && ', '}
      <span className={s.tooltipItem}>{filter}</span>
    </React.Fragment>
  ));

  return (
    filtersCount(filters) >= 1 && (
      <div className={s.filters} data-hook={dataHook}>
        {visibleItems.map((filter, index) => (
          <span key={index} className={s.filterItem}>
            {filter}
          </span>
        ))}
        {tooltipItems.length !== 0 && (
          <Tooltip content={tooltipContent} dataHook={templateFiltersDataHooks.tooltip()}>
            …
          </Tooltip>
        )}
      </div>
    )
  );
};

export const TemplateFilters = injectStoresV2('templatesStore')(TemplateFiltersCmp);

const getMatchedFilters = ({
  apps,
  filters,
  template,
}: {
  apps: Apps;
  template: Template;
  filters: FilterCollection;
}): FilterCollection => {
  const { color, layout } = template;
  const templateFeatures = getMatchedTemplateFeatures({ template, apps });

  return {
    features: filters.features.filter((feature) => templateFeatures.includes(feature)),
    colorStyles: filters.colorStyles.filter((filter) => color && filter === color.colorStyle),
    colors: filters.colors.filter((filter) => color && [color.color1, color.color2].includes(filter)),
    layouts: filters.layouts.filter((filter) => layout && [layout.layout1, layout.layout2].includes(filter)),
  };
};

const getMatchedTemplateFeatures = ({ template, apps }: { apps: Apps; template: Template }): Feature[] =>
  (template.apps || [])
    .map((app) => app.appDefId)
    .map((appDefId) => getAppNameByDefId(apps, appDefId))
    .filter(Boolean)
    .map((appName) => getFeatureByAppName(appName))
    .filter(Boolean);
