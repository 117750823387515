import React from 'react';
import cs from 'classnames';
import { injectStoresV2 } from '../../../../stores/injectStoresV2';
import { usePreventFocusOnClick } from '../../../../hooks/usePreventFocusOnClick';
import { BILoggerStore } from '../../../../stores/BILoggerStore';
import { getEditorLink, getViewerLink } from '../../../../../utils/templateLinks';
import { RoutingStore } from '../../../../stores/RoutingStore';
import { BaseURLStore } from '../../../../stores/BaseURLStore';
import { ConfigStore } from '../../../../stores/ConfigStore';
import { WithTranslations, withTranslations } from '../../../../../utils/withTranslations';
import { Template } from '../../../../../web-api/domain/template';
import { useEditorSessionId } from '../../../../contexts/EditorSessionIdContext';
import { ExperimentsStore } from '../../../../../stores/ExperimentsStore';
import { hoverPanelDataHooks } from './HoverPanel.dataHooks';
import s from './HoverPanel.scss';

interface HoverPanelProps extends WithTranslations {
  biLoggerStore: BILoggerStore;
  routingStore: RoutingStore;
  baseURLStore: BaseURLStore;
  configStore: ConfigStore;
  experimentsStore: ExperimentsStore;
  template: Template;
  galleryDocIndex: number;
  className?: string;
  dataHook?: string;
  onInfoClick: () => void;
  isBestMatch?: boolean;
}

const HoverPanelCmp: React.FC<HoverPanelProps> = (props) => {
  const {
    template,
    t,
    galleryDocIndex,
    biLoggerStore,
    baseURLStore,
    routingStore,
    configStore,
    experimentsStore,
    className,
    dataHook,
    onInfoClick,
    isBestMatch,
  } = props;
  const { editorSessionId, refreshEditorSessionId } = useEditorSessionId();
  const { siteId, metaSiteId, score } = template;
  const originUrl = baseURLStore.buildURL(routingStore.location);
  const { currentLanguage } = configStore.config;
  const { petri_ovr, dashboardFirstMetaSiteId, siteCreationFolderId } = routingStore.getGlobalUrlParams();
  const isOpenTemplateInSameTab = !!dashboardFirstMetaSiteId;
  const isReversedTemplateButtonsOrder = experimentsStore.is(
    'specs.funnel.TemplatesGalleryReverseTemplateButtonsOrder',
    'true',
  );

  const editorLink = getEditorLink({
    template,
    editorSessionId,
    petri_ovr,
    dashboardFirstMetaSiteId,
    siteCreationFolderId,
  });
  const viewerLink = getViewerLink({
    templateSlug: template.id,
    language: currentLanguage,
    originUrl,
    editorSessionId,
    dashboardFirstMetaSiteId,
  });

  const handleEditButtonClick = () => {
    void biLoggerStore.logTemplateEdit({
      siteId,
      galleryDocIndex,
      openingBrowserUrl: editorLink,
      metaSiteId,
      editorSessionId,
      hasVideo: !!template.videoSrc,
      origin: 'create-page' as const,
      score,
      currentPageNumber: routingStore.currentPage,
      isBestMatch,
    });

    setTimeout(() => refreshEditorSessionId());
  };

  const logBITemplateView = (buttonName: 'try' | 'view') => {
    biLoggerStore.logTemplateView({
      siteId,
      galleryDocIndex,
      openingBrowserUrl: viewerLink,
      hasVideo: false,
      origin: 'thumbnail' as const,
      score,
      currentPageNumber: routingStore.currentPage,
      buttonName,
      isBestMatch,
    });

    setTimeout(() => refreshEditorSessionId());
  };

  const handlePreviewClick = () => {
    logBITemplateView('view');
  };

  const handleShowInfoKeyPress = (event: React.KeyboardEvent) => {
    if (['Enter', 'Space'].includes(event.nativeEvent.code)) {
      event.preventDefault();
      onInfoClick();
    }
  };

  const preventFocusOnClick = usePreventFocusOnClick();

  const editButton = (
    <a
      href={editorLink}
      target={isOpenTemplateInSameTab ? '_self' : '_blank'}
      className={isReversedTemplateButtonsOrder ? s.previewBtn : s.editBtn}
      data-hook={hoverPanelDataHooks.editorLink()}
      onClick={handleEditButtonClick}
      onFocus={preventFocusOnClick}
      role="button"
    >
      {t('template.edit')}
    </a>
  );

  const viewButton = (
    <a
      href={viewerLink}
      target={isOpenTemplateInSameTab ? '_self' : '_blank'}
      className={isReversedTemplateButtonsOrder ? s.editBtn : s.previewBtn}
      data-hook={hoverPanelDataHooks.previewLink()}
      onClick={handlePreviewClick}
      onFocus={preventFocusOnClick}
      role="button"
      aria-haspopup="true"
    >
      {t('template.view')}
    </a>
  );

  return (
    <div className={cs(s.hoverPanel, className)} data-hook={dataHook}>
      <div className={s.buttons}>
        {isReversedTemplateButtonsOrder ? (
          <>
            {viewButton}
            {editButton}
          </>
        ) : (
          <>
            {editButton}
            {viewButton}
          </>
        )}
      </div>
      <div
        className={s.showInfo}
        data-hook={hoverPanelDataHooks.showIconLabel()}
        aria-label={t('template.showInfo')}
        role="button"
        onKeyDown={handleShowInfoKeyPress}
        tabIndex={0}
        onFocus={preventFocusOnClick}
        onClick={onInfoClick}
      >
        {t('template.info')}
      </div>
    </div>
  );
};

export const HoverPanel = withTranslations(
  injectStoresV2('biLoggerStore', 'routingStore', 'baseURLStore', 'configStore', 'experimentsStore')(HoverPanelCmp),
);
