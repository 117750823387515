import { dataHooks } from '../../../libs/dataHooks';

export const filtersDataHooks = dataHooks<{
  filtersWrapper: {};
  colorsFilter: {};
  layoutsFilter: {};
  sortFilter: {};
  featuresFilter: {};
  resetLink: {};
  description: {};
  collapseButton: {};
  expandButton: {};
}>('filters');
