import { AppSpecs, defaultAppSpecs } from '../experiments/appSpecs';

export type ExperimentsStoreState = Partial<AppSpecs>;

export class ExperimentsStore {
  private readonly specsWithDefaults: AppSpecs;

  constructor(private conductedSpecs: ExperimentsStoreState) {
    this.specsWithDefaults = { ...defaultAppSpecs, ...conductedSpecs };
  }

  public value<T extends keyof AppSpecs>(key: T): AppSpecs[T] {
    return this.specsWithDefaults[key];
  }

  public is<T extends keyof AppSpecs>(key: T, val: AppSpecs[T]): boolean {
    return this.specsWithDefaults[key] === val;
  }

  public serialize(): ExperimentsStoreState {
    return this.conductedSpecs;
  }
}
