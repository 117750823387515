import React from 'react';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { isFiltersEmpty, RoutingStore } from '../../../stores/RoutingStore';
import { TemplatesStore } from '../../../stores/TemplatesStore';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import s from './SubText.scss';

interface SubTextProps extends WithTranslations {
  routingStore: RoutingStore;
  templatesStore: TemplatesStore;
  dataHook?: string;
}

const SubTextCmp: React.FC<SubTextProps> = ({ routingStore, templatesStore, t, dataHook }) => {
  return (
    routingStore.matchedRoute.routeName === 'search' &&
    templatesStore.loadedFetchParams?.type === 'search' &&
    isFiltersEmpty(templatesStore.loadedFetchParams?.filters) &&
    templatesStore.isNoResultSuggestions && (
      <p className={s.root} data-hook={dataHook} aria-live="assertive">
        {t('subHeader.subTitle.subText.noResults')}
      </p>
    )
  );
};

export const SubText = withTranslations(injectStoresV2('routingStore', 'templatesStore')(SubTextCmp));
