import { initI18n, Messages } from '@wix/wix-i18n-config';
import { ErrorReporter } from '../errorHandling/ErrorReporter';

export interface InitialI18n {
  locale: string;
  resources: Messages;
}

export function clientI18n({ locale, resources }: InitialI18n, errorReporter: ErrorReporter) {
  return initI18n({
    locale,
    messages: resources,
    parseErrorHandler: (err, key, res, options) => {
      errorReporter.reportError(err, { key, res: String(res), options: JSON.stringify(options) });
      return String(res);
    },
  });
}
