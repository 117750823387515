import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { SearchSuggestionsQueryParams } from '../web-api/controllers/searchSuggestionsController';
import { FetchQueryParams } from '../web-api/controllers/TemplateRequestParams';
import {
  ApiClientFetchParams,
  ApiClientBrowseParams,
  ApiClientParams,
  ApiClientSearchParams,
  ApiClientSearchSuggestionsParams,
} from './ApiClient';

export function buildFetchRequestQuery(params: ApiClientFetchParams) {
  return {
    category: params.category,
    'sub-category': params.subCategory,
    page: String(params.page),
  };
}

export function buildBrowseRequestQuery(params: ApiClientBrowseParams): FetchQueryParams {
  const { categoryIds, biData } = params;
  const { category, subCategory, criteria } = biData;
  return {
    'category-ids': joinArray(categoryIds),
    category,
    'sub-category': subCategory,
    criteria,
    page: String(params.biData.page),
    ...buildRequestQuery(params),
  };
}

export function buildSearchRequestQuery(params: ApiClientSearchParams): FetchQueryParams {
  return {
    criteria: params.criteria,
    page: String(params.biData.page),
    'search-type': params.biData.searchType,
    'search-suggestion-industry-id': params.biData.searchSuggestionIndustryId,
    'search-suggestion-structure-id': params.biData.searchSuggestionStructureId,
    ...buildRequestQuery(params),
  };
}

export function buildSearchSuggestionsRequestQuery(
  params: ApiClientSearchSuggestionsParams,
): SearchSuggestionsQueryParams {
  const { criteria, biData } = params;
  return {
    criteria,
    category: biData.category,
    'sub-category': biData.subCategory,
    'template-page-session-id': biData.templatePageSessionId,
  };
}
function buildRequestQuery(params: ApiClientParams) {
  const { filters, sortCategoryId, templatePageSessionId } = params;

  const query = {
    offset: String(params.offset ?? ''),
    limit: String(params.limit ?? ''),
    apps: joinArray(filters?.apps),
    features: joinArray(filters?.features),
    layouts: joinArray(filters?.layouts),
    colors: joinArray(filters?.colors),
    'color-styles': joinArray(filters?.colorStyles),
    'sort-category-id': sortCategoryId,
    'template-page-session-id': templatePageSessionId,
  };

  return omitBy(query, isEmpty);
}

function joinArray(arr?: string[]): string {
  return (arr || []).join(',');
}
