import urlParse from 'url-parse';
import { addTrailingStr, stripLeadingStr, stripTrailingStr } from './leadingTrailingStr';

export function getPathname(url: string) {
  return urlParse(url).pathname;
}

function addTrailingSlash(path: string) {
  return addTrailingStr(path, '/');
}

function stripLeadingSlash(path: string) {
  return stripLeadingStr(path, '/');
}

export function stripTrailingSlash(path: string) {
  return stripTrailingStr(path, '/');
}

export function joinUrlParts(...parts: string[]) {
  return parts.reduce((leftPart, part) => `${addTrailingSlash(leftPart)}${stripLeadingSlash(part)}`);
}

export function getBasename(baseURL: string) {
  return stripTrailingSlash(getPathname(baseURL));
}

export function getOrigin(baseURL: string) {
  return urlParse(baseURL).origin;
}
