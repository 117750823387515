import React from 'react';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { RoutingStore } from '../../../stores/RoutingStore';
import { FilterDropdown } from '../FilterDropdown/FilterDropdown';
import { EMPTY_FILTERS } from '../../../stores/routes/sortFiltersParams';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import { layoutsFilterDataHooks } from './LayoutsFilter.dataHooks';
import { FilterLayoutsItem } from './LayoutsFilterItem/LayoutsFilterItem';
import styles from './LayoutsFilter.scss';

export const LAYOUTS = ['hero-image', 'strip', 'framed', 'inline', 'split-screen', 'grid', 'text', 'unusual'] as const;

interface LayoutsFilterProps extends WithTranslations {
  routingStore: RoutingStore;
  dataHook?: string;
}

const LayoutsFilterCmp: React.FC<LayoutsFilterProps> = ({ routingStore, dataHook, t }) => {
  const appliedFilters = routingStore.appliedFilters ?? EMPTY_FILTERS;
  const appliedLayoutFilters = appliedFilters.layouts;
  const clearLink = routingStore.rebuildRoute({
    page: 1,
    filters: {
      ...appliedFilters,
      layouts: [],
    },
  });

  return (
    <FilterDropdown
      dataHook={dataHook}
      clearLink={clearLink}
      title={t('filters.title.layouts')}
      filterType="layouts"
      appliedFiltersCount={appliedLayoutFilters.length}
    >
      <ul className={styles.content}>
        {LAYOUTS.map((id) => (
          <li key={id}>
            <FilterLayoutsItem filterId={id} dataHook={layoutsFilterDataHooks.filterItem({ id })} />
          </li>
        ))}
      </ul>
    </FilterDropdown>
  );
};

export const LayoutsFilter = withTranslations(injectStoresV2('routingStore')(LayoutsFilterCmp));
