import { observable, action, makeObservable } from 'mobx';
import { Location, History, Path, LocationState } from 'history';
import { RouteLocation } from '../../v2/stores/routes/createRoute';

/**
 * Copied from https://github.com/alisd23/mobx-react-router.
 * Adapted to typescript and mobx6.
 */
export class RouterStore {
  location: Location = null;
  history: History = null;

  constructor() {
    makeObservable(this, {
      location: observable,
      _updateLocation: action,
      push: action.bound,
      replace: action.bound,
      go: action.bound,
      goBack: action.bound,
      goForward: action.bound,
    });
  }

  _updateLocation(newState) {
    this.location = newState;
  }

  /*
   * History methods
   */
  push = (location: string | Path | RouteLocation, state?: LocationState) => {
    this.history.push(location, state);
  };
  replace = (location: string | Path | Partial<RouteLocation>, state?: LocationState) => {
    this.history.replace(location, state);
  };
  go = (n: number) => {
    this.history.go(n);
  };
  goBack = () => {
    this.history.goBack();
  };
  goForward = () => {
    this.history.goForward();
  };
}
