import { ErrorReporter } from '../errorHandling/ErrorReporter';
import { ExperimentsStoreState } from '../stores/ExperimentsStore';

export function initWixRecorder(experiments: ExperimentsStoreState, errorReporter?: ErrorReporter) {
  window.addEventListener('wixRecorderReady', async () => {
    try {
      await window.wixRecorder.addLabel('marketing-templates');
      await window.wixRecorder.withExperiments(experiments);
    } catch (error) {
      errorReporter && errorReporter.reportError(error);
    }
  });
}
