import React from 'react';
import { FedopsLoggerStore } from '../../stores/FedopsLoggerStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { BILoggerStore } from '../../stores/BILoggerStore';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { TemplatesGrid } from './TemplatesGrid/TemplatesGrid';

interface TemplatesProps {
  fedopsLoggerStore: FedopsLoggerStore;
  templatesStore: TemplatesStore;
  biLoggerStore: BILoggerStore;
  dataHook?: string;
}

const TemplatesCmp: React.FC<TemplatesProps> = ({ templatesStore, dataHook, biLoggerStore, fedopsLoggerStore }) => {
  const { items, responseUuid } = templatesStore;

  React.useEffect(() => {
    fedopsLoggerStore.stopActiveInteraction();
    biLoggerStore.logPageFullyRendered(biLoggerStore.getLastInteractionKey());
    biLoggerStore.logTemplatesLoaded();
  }, [fedopsLoggerStore, responseUuid, biLoggerStore]);

  return <TemplatesGrid dataHook={dataHook} items={items} responseUuid={responseUuid} />;
};

export const Templates = injectStoresV2('templatesStore', 'biLoggerStore', 'fedopsLoggerStore')(TemplatesCmp);
