import React from 'react';
import { Layout } from '../../stores/routes/sortFiltersParams';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { TranslationKey } from '../../../i18n/i18n';

const layoutTranslationMapping: Record<Layout, TranslationKey> = {
  'hero-image': 'layout.heroImage',
  strip: 'layout.strip',
  framed: 'layout.framed',
  inline: 'layout.inline',
  'split-screen': 'layout.splitScreen',
  grid: 'layout.grid',
  text: 'layout.text',
  unusual: 'layout.unusual',
};

interface FilterLayoutNameProps extends WithTranslations {
  layout: Layout;
}

export const FilterLayoutNameCmp = ({ layout, t }: FilterLayoutNameProps) => {
  const translationKey = layoutTranslationMapping[layout];
  return <>{translationKey ? t(translationKey) : layout}</>;
};

export const FilterLayoutName = withTranslations(FilterLayoutNameCmp);
