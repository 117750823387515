import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import cs from 'classnames';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import { GallerySearchSuggestionItem, SearchWithSuggestionsStore } from '../../../stores/SearchWithSuggestionsStore';
import { SuggestionsList } from '../SuggestionsList/SuggestionsList';
import { SearchSubmitButton } from '../SearchSubmitButton/SearchSubmitButton';
import { HeaderStore } from '../../../stores/HeaderStore';
import { suggestionsDropdown } from './SuggestionsDropdown.dataHooks';

import styles from './SuggestionsDropdown.scss';

export const dropdownId = 'search-dropdown';

interface SuggestionsDropdownProps extends WithTranslations {
  searchWithSuggestionsStore: SearchWithSuggestionsStore;
  headerStore: HeaderStore;
  dataHook?: string;
  onSearch: () => void;
}
const dropdownMenuEnterTimeout = parseInt(styles.dropdownEnterTimeout, 10);
const dropdownMenuLeaveTimeout = parseInt(styles.dropdownLeaveTimeout, 10);

const SuggestionsDropdownCmp: React.FC<SuggestionsDropdownProps> = ({
  dataHook,
  searchWithSuggestionsStore,
  headerStore,
  onSearch,
  t,
}) => {
  const { searchSuggestions, isDropdownOpened, preSelectedSuggestionIndex } = searchWithSuggestionsStore;
  const { searchCriteria, isSearchInputFocused, isLargeSearchInput } = headerStore;
  const isOpened = searchCriteria.trim() !== '' && isDropdownOpened && isSearchInputFocused;
  const dropdownRef = React.createRef<HTMLDivElement>();

  const searchSuggestionResults: GallerySearchSuggestionItem[] = [];
  const searchSuggestionRelatedResults: GallerySearchSuggestionItem[] = [];

  searchSuggestions.forEach((suggestion) => {
    if (suggestion.resultType === 'related_result') {
      searchSuggestionRelatedResults.push(suggestion);
    } else {
      searchSuggestionResults.push(suggestion);
    }
  });

  const noResults = searchSuggestions.length === 0;
  const hasSuggestions = searchSuggestionResults.length > 0;
  const hasRelatedSuggestions = searchSuggestionRelatedResults.length > 0;

  useEffect(() => {
    if (!preSelectedSuggestionIndex && dropdownRef.current) {
      dropdownRef.current.scrollTop = 0;
    }
  });

  return (
    <CSSTransition
      in={isOpened}
      classNames={{
        enter: styles.dropdownEnter,
        enterActive: styles.dropdownEnterActive,
        enterDone: styles.dropdownEnterDone,
        exit: styles.dropdownLeave,
        exitActive: styles.dropdownLeaveActive,
      }}
      timeout={{ enter: dropdownMenuEnterTimeout, exit: dropdownMenuLeaveTimeout }}
      mountOnEnter
      unmountOnExit
    >
      <div
        id={dropdownId}
        className={cs(styles.dropdownWrapper, {
          [styles.leftAligned]: isLargeSearchInput,
          [styles.extraTopMargin]: isLargeSearchInput,
        })}
        data-hook={cs(dataHook, suggestionsDropdown.dropdown({ opened: isOpened }))}
        aria-expanded={isOpened}
      >
        <div
          className={cs(styles.dropdown, {
            [styles.noResultsDropdown]: noResults,
            [styles.roundedBorders]: isLargeSearchInput,
          })}
        >
          <SearchSubmitButton dataHook={suggestionsDropdown.searchButton()} />
          <div className={styles.dropdownList} ref={dropdownRef}>
            {hasSuggestions && (
              <SuggestionsList
                dataHook={suggestionsDropdown.resultsList()}
                suggestions={searchSuggestionResults}
                onSearch={onSearch}
              />
            )}
            {hasRelatedSuggestions && (
              <>
                <p className={cs(styles.heading, { [styles.bordered]: hasSuggestions })}>
                  {t('search.dropdown.relatedResults')}
                </p>
                <SuggestionsList
                  dataHook={suggestionsDropdown.relatedResultsList()}
                  suggestions={searchSuggestionRelatedResults}
                  onSearch={onSearch}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export const SuggestionsDropdown = withTranslations(
  injectStoresV2('searchWithSuggestionsStore', 'headerStore')(SuggestionsDropdownCmp),
);
