import cs from 'classnames';
import React, { KeyboardEvent } from 'react';
import Close from '../../../../../Icons/Close.svg';
import { usePreventFocusOnClick } from '../../../../hooks/usePreventFocusOnClick';
import { WithTranslations, withTranslations } from '../../../../../utils/withTranslations';
import { infoPanelDataHooks } from './InfoPanel.dataHooks';
import s from './InfoPanel.scss';

interface InfoPanelProps extends WithTranslations {
  style?: object;
  className?: string;
  dataHook?: string;
  goodFor?: string;
  description?: string;
  onCloseClick: () => void;
}

interface DetailsProps {
  title: React.ReactNode;
  text: React.ReactNode;
  titleDataHook?: string;
  textDataHook?: string;
}

const Details: React.FC<DetailsProps> = ({ title, text, titleDataHook, textDataHook }) => (
  <>
    <p data-hook={titleDataHook} className={s.detailsTitle}>
      {title}
    </p>
    <p data-hook={textDataHook} className={s.detailsText}>
      {text}
    </p>
  </>
);

const InfoPanelCmp: React.FC<InfoPanelProps> = (props) => {
  const { className, dataHook, goodFor, description, t, onCloseClick } = props;
  const preventFocusOnClick = usePreventFocusOnClick();

  const handleLabelKeyPress = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onCloseClick();
    }
  };

  const handleInfoPanelKeyPress = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      onCloseClick();
    }
  };

  return (
    <div data-hook={dataHook} className={cs(className, s.panel)} onKeyUp={handleInfoPanelKeyPress}>
      <div tabIndex={-1}>
        {goodFor && (
          <Details
            title={t('template.goodFor')}
            text={goodFor}
            titleDataHook={infoPanelDataHooks.goodForTitle()}
            textDataHook={infoPanelDataHooks.goodFor()}
          />
        )}
        {description && (
          <Details
            title={t('template.description')}
            text={description}
            titleDataHook={infoPanelDataHooks.descriptionTitle()}
            textDataHook={infoPanelDataHooks.description()}
          />
        )}
      </div>
      <div
        className={s.hideInfo}
        data-hook={infoPanelDataHooks.hideInfoLabel()}
        aria-label={t('template.hideInfo')}
        onKeyPress={handleLabelKeyPress}
        tabIndex={0}
        role="button"
        onFocus={preventFocusOnClick}
        onClick={onCloseClick}
      >
        <Close fill="currentColor" width="1em" height="1em" />
      </div>
    </div>
  );
};

export const InfoPanel = withTranslations(InfoPanelCmp);
