import { Location } from 'history';

export interface RouteLocation {
  pathname: Location['pathname'];
  search?: Location['search'];
}

export interface Route<T, R extends keyof T = keyof T> {
  pattern: string;
  build: (params: Partial<T> & Required<Pick<T, R>>) => RouteLocation;
  match: (location: RouteLocation) => T | null;
}

interface CreateRouteParams<T, R extends keyof T = keyof T> {
  pattern: string;
  build: (pattern: string, params: Partial<T> & Required<Pick<T, R>>) => RouteLocation;
  match: (pattern: string, location: RouteLocation) => T | null;
}

export function createRoute<T, R extends keyof T = keyof T>({
  pattern,
  build,
  match,
}: CreateRouteParams<T, R>): Route<T, R> {
  return {
    pattern,
    build: (params) => build(pattern, params),
    match: (location) => match(pattern, location),
  };
}
