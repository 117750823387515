import { action, computed, observable, reaction, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { CategoryTemplatesResponse, SearchTemplatesResponse } from '../../web-api/domain/templatesResponse';
import { ApiClient, ApiClientParams } from '../../apiClient/ApiClient';
import { ErrorReporter } from '../../errorHandling/ErrorReporter';
import { ExperimentsStore } from '../../stores/ExperimentsStore';
import { Apps, APPS_BY_GALLERY_FEATURE, CMS_FEATURES_BY_GALLERY_FEATURE } from '../utils/appsAndFeatures';
import { isOnline } from '../utils/isOnline';
import { mapArrayToValues } from '../../utils/mapArrayToValues';
import { Category } from '../../web-api/domain/category';
import { Template } from '../../web-api/domain/template';
import { isIncludesA11yEnglishWords } from '../utils/isIncludesA11yEnglishWords';
import { BaseURLStore } from './BaseURLStore';
import { CategoriesStore } from './CategoriesStore';
import { FilterCollection } from './routes/sortFiltersParams';
import { isFiltersEmpty, MatchedRoute, RoutingStore } from './RoutingStore';
import { InteractionType, BILoggerStore } from './BILoggerStore';
import { FedopsLoggerStore } from './FedopsLoggerStore';
import { HeaderStore } from './HeaderStore';
import { SearchWithSuggestionsStore, SelectedSuggestion } from './SearchWithSuggestionsStore';
import { ConfigStore } from './ConfigStore';

export const ITEMS_PER_PAGE = 12;

export interface TemplatesStoreState {
  itemsTotal: number;
  items: Template[];
  hasMore: boolean;
  searchId?: string;
  loadedFetchParams: TemplateStoreFetchParams | null;
  bestMatchItemIds: string[];
}

interface TemplatesStoreParams {
  experimentsStore: ExperimentsStore;
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
  apiClient: ApiClient;
  errorReporter: ErrorReporter;
  apps: Apps;
  baseURLStore: BaseURLStore;
  biLoggerStore: BILoggerStore;
  fedopsLoggerStore: FedopsLoggerStore;
  headerStore: HeaderStore;
  searchWithSuggestionsStore: SearchWithSuggestionsStore;
  configStore: ConfigStore;
}

type FetchCategoryParams = {
  type: 'category';
  criteria: null;
  categorySlug: string;
  subCategorySlug: string | null;
  page: number;
  filters: FilterCollection;
  sortCategorySlug: string | null;
};

type FetchSearchParams = {
  type: 'search';
  criteria: string;
  categorySlug: null;
  subCategorySlug: null;
  page: number;
  filters: FilterCollection;
  sortCategorySlug: string | null;
};

export type TemplateStoreFetchParams = FetchCategoryParams | FetchSearchParams;

export enum NetworkErrorType {
  NoConnection = 'NO_CONNECTION',
  ServerSide = 'SERVER_SIDE',
}

export class TemplatesStore {
  private readonly experimentsStore: ExperimentsStore;
  private readonly routingStore: RoutingStore;
  private readonly categoriesStore: CategoriesStore;
  private readonly apiClient: ApiClient;
  private readonly errorReporter: ErrorReporter;
  private readonly biLoggerStore: BILoggerStore;
  private readonly fedopsLoggerStore: FedopsLoggerStore;
  private readonly searchWithSuggestionsStore: SearchWithSuggestionsStore;
  private readonly headerStore: HeaderStore;
  private readonly baseURLStore: BaseURLStore;
  private readonly configStore: ConfigStore;
  public readonly apps: Apps;

  public responseUuid: string = uuidv4();
  public viewedTemplateIds: string[] = [];
  public isLoadingTemplates: boolean = false;
  public items: Template[] = [];
  public itemsTotal: number = 0;
  public hasMore: boolean = false;
  public searchId?: string = undefined;
  public loadedFetchParams: TemplateStoreFetchParams | null = null;
  public redirectedCriteria: string | null = null;
  public isNetworkErrorModalVisible: boolean = false;
  public networkErrorType: NetworkErrorType | null = null;
  public networkError: Error | null = null;
  public bestMatchItemIds: string[] = [];

  constructor(
    {
      experimentsStore,
      routingStore,
      categoriesStore,
      apiClient,
      errorReporter,
      apps,
      baseURLStore,
      biLoggerStore,
      fedopsLoggerStore,
      headerStore,
      searchWithSuggestionsStore,
      configStore,
    }: TemplatesStoreParams,
    initialState?: TemplatesStoreState,
  ) {
    makeObservable<
      TemplatesStore,
      'setLoadingTemplates' | 'isOnlyPageNumberChanged' | 'setNetworkErrorType' | 'setNetworkError' | 'setResponse'
    >(this, {
      responseUuid: observable,
      viewedTemplateIds: observable,
      isLoadingTemplates: observable,
      items: observable,
      itemsTotal: observable,
      hasMore: observable,
      searchId: observable,
      loadedFetchParams: observable,
      redirectedCriteria: observable,
      isNetworkErrorModalVisible: observable,
      networkErrorType: observable,
      networkError: observable,
      fetchParams: computed,
      setLoadingTemplates: action,
      isOnlyPageNumberChanged: computed,
      setNetworkErrorType: action,
      setIsNetworkErrorModalVisible: action,
      setNetworkError: action,
      setResponse: action,
      resetViewedTemplateIds: action,
      addViewedTemplateId: action,
      setRedirectedCriteria: action,
      clearRedirectedCriteria: action,
      isNoResultSuggestions: computed,
      isNoFiltersResult: computed,
      totalPages: computed,
      activeCategory: computed,
      activeSubCategory: computed,
      hasSubCategories: computed,
      bestMatchItemIds: observable,
    });

    this.experimentsStore = experimentsStore;
    this.routingStore = routingStore;
    this.categoriesStore = categoriesStore;
    this.apiClient = apiClient;
    this.errorReporter = errorReporter;
    this.apps = apps;
    this.baseURLStore = baseURLStore;
    this.biLoggerStore = biLoggerStore;
    this.fedopsLoggerStore = fedopsLoggerStore;
    this.headerStore = headerStore;
    this.searchWithSuggestionsStore = searchWithSuggestionsStore;
    this.configStore = configStore;

    if (initialState) {
      this.hasMore = initialState.hasMore;
      this.itemsTotal = initialState.itemsTotal;
      this.searchId = initialState.searchId;
      this.items = initialState.items;
      this.loadedFetchParams = initialState.loadedFetchParams;
      this.bestMatchItemIds = initialState.bestMatchItemIds ?? [];
    }

    this.listenForRouteChanges();
  }

  public async initialFetch() {
    await this.fetch();
  }

  public serialize(): TemplatesStoreState {
    const { items, itemsTotal, hasMore, loadedFetchParams, bestMatchItemIds } = this;
    return { items, itemsTotal, hasMore, loadedFetchParams, bestMatchItemIds };
  }

  public get fetchParams(): TemplateStoreFetchParams | null {
    const { matchedRoute } = this.routingStore;

    if (!matchedRoute) {
      return null;
    }

    if (matchedRoute.routeName === 'home') {
      return {
        type: 'category',
        criteria: null,
        categorySlug: this.routingStore.primaryCategorySlug,
        subCategorySlug: null,
        page: 1,
        filters: { features: [], colors: [], colorStyles: [], layouts: [] },
        sortCategorySlug: null,
      };
    }

    const { page, filters, sortCategorySlug } = matchedRoute.params;
    if (matchedRoute.routeName === 'search') {
      return {
        type: 'search' as const,
        criteria: matchedRoute.params.criteria,
        categorySlug: null,
        subCategorySlug: null,
        page,
        filters,
        sortCategorySlug,
      };
    }

    return {
      type: 'category' as const,
      criteria: null,
      categorySlug: matchedRoute.params.categorySlug,
      subCategorySlug: matchedRoute.routeName === 'subCategory' ? matchedRoute.params.subCategorySlug : null,
      page,
      filters,
      sortCategorySlug,
    };
  }

  private setLoadingTemplates(isLoading: boolean = false) {
    this.isLoadingTemplates = isLoading;
  }

  private get isOnlyPageNumberChanged() {
    const matchedRoute: MatchedRoute = this.routingStore.matchedRoute;
    if (!this.routingStore.previousMatchedRoute || matchedRoute.routeName === 'home') {
      return false;
    }

    if (this.routingStore.previousMatchedRoute.routeName === 'home' && matchedRoute.routeName !== 'search') {
      return (
        matchedRoute.params.categorySlug === this.routingStore.primaryCategorySlug && matchedRoute.params.page === 2
      );
    }

    const prevRoute = JSON.parse(JSON.stringify(this.routingStore.previousMatchedRoute));
    const isSamePage = prevRoute.params.page === matchedRoute.params.page;
    prevRoute.params.page = matchedRoute.params.page;
    return JSON.stringify(matchedRoute) === JSON.stringify(prevRoute) && !isSamePage;
  }

  public getInteractionType(): InteractionType {
    let interactionType: InteractionType;
    if (this.routingStore.matchedRoute.routeName === 'search') {
      interactionType = 'search';
    } else if (this.isOnlyPageNumberChanged) {
      interactionType = 'pagination';
    } else {
      interactionType = 'category_switch';
    }

    return interactionType;
  }

  private listenForRouteChanges() {
    reaction(
      () => this.routingStore.matchedRoute,
      () => this.fetch(),
    );
  }

  private async fetch(): Promise<void> {
    const { selectedSuggestion } = this.searchWithSuggestionsStore;
    const previousUrl = this.routingStore.previousLocation
      ? this.baseURLStore.buildURL(this.routingStore.previousLocation)
      : undefined;

    const { fetchParams, redirectedCriteria } = this;

    if (!fetchParams) {
      return;
    }

    this.clearRedirectedCriteria();
    this.searchWithSuggestionsStore.clearSelectedSuggestion();

    this.setIsNetworkErrorModalVisible(false);
    this.setNetworkError(null);
    this.setNetworkErrorType(null);
    this.setLoadingTemplates(true);
    let response: CategoryTemplatesResponse | SearchTemplatesResponse | null = null;

    const interactionType = this.getInteractionType();
    const interaction = this.biLoggerStore.initInteraction(interactionType);
    this.biLoggerStore.logStartedLoadingThePage(interaction.key);

    if (interactionType === 'category_switch') {
      this.fedopsLoggerStore.startCategoryInteraction();
    }

    if (interactionType === 'pagination') {
      this.fedopsLoggerStore.startPaginationInteraction();
    }

    try {
      response =
        fetchParams.type === 'search'
          ? await this.searchTemplates(fetchParams, previousUrl, selectedSuggestion)
          : await this.browseTemplates(fetchParams, previousUrl);
    } catch (error) {
      this.setNetworkError(error);
      this.errorReporter.reportError(error);
      this.setIsNetworkErrorModalVisible(true);
      this.setNetworkErrorType(isOnline() ? NetworkErrorType.ServerSide : NetworkErrorType.NoConnection);
    }

    if (response && (fetchParams.type === 'search' || redirectedCriteria)) {
      this.biLoggerStore.logSearch({
        criteria: fetchParams.criteria ?? redirectedCriteria,
        totalPages: response.pagination.total,
        currentPage: response.pagination.current,
        numberOfTemplatesInCurrentPage: response.itemsTotal,
        source: this.configStore.config.introReferrer === 'ai_assistant' ? 'template-assistant' : 'search-box',
        searchId: 'searchId' in response ? response.searchId : undefined,
        searchType: selectedSuggestion?.searchType ?? (redirectedCriteria ? 'category' : 'free_text'),
        selectedSuggestion: selectedSuggestion?.searchSuggestion,
      });
    }

    if (response) {
      this.setBestMatchedTemplates(response.items);
    }

    this.biLoggerStore.logFinishedLoadingThePage(interaction.key);
    response && this.setResponse(response, fetchParams);
    this.setLoadingTemplates(false);
  }

  private setNetworkErrorType(value: NetworkErrorType | null) {
    this.networkErrorType = value;
  }

  setIsNetworkErrorModalVisible(value: boolean) {
    this.isNetworkErrorModalVisible = value;
  }

  private setNetworkError(error: Error) {
    this.networkError = error;
  }

  private static getPageOffsetLimit(params: TemplateStoreFetchParams): { offset: number; limit: number } {
    const pagesOffset = Math.max(0, params.page - 1);
    const firstPageLimit = ITEMS_PER_PAGE;
    const offset = pagesOffset === 0 ? 0 : firstPageLimit + (pagesOffset - 1) * ITEMS_PER_PAGE;
    const limit = params.page === 1 ? firstPageLimit : ITEMS_PER_PAGE;
    return { offset, limit };
  }

  private searchTemplates(
    params: FetchSearchParams,
    previousUrl?: string,
    selectedSearchSuggestion?: SelectedSuggestion,
  ): Promise<SearchTemplatesResponse> {
    const { criteria, filters, page, sortCategorySlug } = params;

    const sortCategoryId = sortCategorySlug
      ? this.categoriesStore.getCategoryBySlug(sortCategorySlug)?.categoryId
      : undefined;
    const isSearchWithSuggestions = this.configStore.config.currentLanguage === 'en';
    const { searchType, searchSuggestion } = selectedSearchSuggestion || {};

    return this.apiClient.search(
      {
        ...TemplatesStore.getPageOffsetLimit(params),
        criteria,
        sortCategoryId,
        filters: this.buildApiFilters(criteria, filters),
        templatePageSessionId: this.biLoggerStore.templatePageSessionId,
        biData: {
          page,
          searchType: isSearchWithSuggestions ? searchType : undefined,
          searchSuggestionIndustryId: searchSuggestion?.industryId,
          searchSuggestionStructureId: searchSuggestion?.structureId,
        },
      },
      previousUrl,
    );
  }

  private browseTemplates(
    params: FetchCategoryParams,
    previousUrl?: string,
  ): Promise<CategoryTemplatesResponse | null> {
    const { categorySlug, subCategorySlug, filters, page, sortCategorySlug } = params;
    const categoryId = this.categoriesStore.getCategoryBySlug(subCategorySlug || categorySlug)?.categoryId;
    const categoryIds = categoryId ? [categoryId] : [];
    const sortCategoryId = sortCategorySlug
      ? this.categoriesStore.getCategoryBySlug(sortCategorySlug)?.categoryId
      : undefined;

    if (categoryIds.length === 0) {
      return Promise.resolve(null);
    }

    const criteria = this.headerStore.searchCriteria.trim();

    return this.apiClient.browse(
      {
        ...TemplatesStore.getPageOffsetLimit(params),
        categoryIds,
        sortCategoryId,
        filters: this.buildApiFilters(criteria, filters),
        templatePageSessionId: this.biLoggerStore.templatePageSessionId,
        biData: {
          page,
          bookName: this.categoriesStore.bookName,
          category: this.routingStore.activeCategorySlug,
          subCategory: this.routingStore.activeSubCategorySlug,
          criteria,
        },
      },
      previousUrl,
    );
  }

  private setResponse(
    { pagination, itemsTotal, items, ...response }: CategoryTemplatesResponse | SearchTemplatesResponse,
    loadedFetchParams: TemplateStoreFetchParams,
  ): void {
    this.responseUuid = uuidv4();
    this.items = items;
    this.itemsTotal = itemsTotal;
    this.hasMore = pagination.current < pagination.total;
    this.searchId = 'searchId' in response ? response.searchId : undefined;
    this.resetViewedTemplateIds();
    this.loadedFetchParams = loadedFetchParams;
  }

  private buildApiFilters(criteria: string, filters: FilterCollection): ApiClientParams['filters'] {
    const appNames = mapArrayToValues(filters.features, APPS_BY_GALLERY_FEATURE);
    const cmsFeatures = mapArrayToValues(filters.features, CMS_FEATURES_BY_GALLERY_FEATURE);

    if (isIncludesA11yEnglishWords(criteria, this.configStore.config.currentLanguage)) {
      cmsFeatures.push('accessibility');
    }

    return {
      apps: appNames.map((appName) => this.apps[appName]),
      features: cmsFeatures,
      colors: filters.colors,
      colorStyles: filters.colorStyles,
      layouts: filters.layouts,
    };
  }

  public wasTemplateInView(id: string): boolean {
    return this.viewedTemplateIds.includes(id);
  }

  public resetViewedTemplateIds() {
    this.viewedTemplateIds = [];
  }

  public addViewedTemplateId(id: string) {
    this.viewedTemplateIds.push(id);
  }

  setRedirectedCriteria(criteria: string) {
    this.redirectedCriteria = criteria;
  }

  clearRedirectedCriteria() {
    this.redirectedCriteria = null;
  }

  public get isNoResultSuggestions(): boolean {
    return this.items.every((it) => it.source === 'NoResultSuggestion');
  }

  public get isNoFiltersResult(): boolean {
    return !isFiltersEmpty(this.loadedFetchParams?.filters) && (this.items.length === 0 || this.isNoResultSuggestions);
  }

  public get totalPages(): number {
    return Math.ceil(this.itemsTotal / 12);
  }

  public get activeCategory(): Category | undefined {
    const currentCategorySlug = this.fetchParams?.categorySlug;
    const loadedCategorySlug = this.loadedFetchParams?.categorySlug;

    const categorySlug = currentCategorySlug || loadedCategorySlug;

    return this.categoriesStore.getCategoryBySlug(categorySlug);
  }

  public get activeSubCategory(): Category | undefined {
    return this.categoriesStore.getCategoryBySlug(this.routingStore.activeSubCategorySlug);
  }

  public get hasSubCategories() {
    return !!this.activeCategory?.subCategories?.length;
  }

  private setBestMatchedTemplates(templates: Template[]) {
    if (
      this.bestMatchItemIds.length === 0 &&
      this.routingStore.matchedRoute.routeName !== 'home' &&
      ['customize_site_from_dashboard', 'intro', 'new_site', 'ai_assistant'].includes(
        this.configStore.config.introReferrer,
      )
    ) {
      this.bestMatchItemIds = templates.slice(0, 2).map((template) => template.id);
    }
  }
}
