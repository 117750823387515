import webBiLogger from '@wix/web-bi-logger';
import { templatesFullLoadingTimes } from '@wix/bi-logger-spettatore-bi/v2';

const logger = webBiLogger.factory().logger();

interface PerformanceEvent {
  basic_loading_time: number;
  connect_end: number;
  connect_start: number;
  domComplete_ts: number;
  dom_content_loaded_event_end: number;
  dom_content_loaded_event_start: number;
  dom_interactive: number;
  domLoading_ts: number;
  domain_lookup_end: number;
  domain_lookup_start: number;
  fetch_start: number;
  load_event_end: number;
  load_event_start: number;
  navigation_start: number;
  redirect_end: number;
  redirect_start: number;
  request_start: number;
  response_end: number;
  response_start: number;
  secure_connection_start: number;
  tpsi?: string;
  unload_event_end: number;
  unload_event_start: number;
  window_height: number;
  window_width: number;
  screen_height: number;
  screen_width: number;
  avail_height: number;
  avail_width: number;
}

function logPerformanceEvent(props: PerformanceEvent) {
  if (typeof window === 'object') {
    return logger.report(templatesFullLoadingTimes(props));
  }
  return Promise.resolve();
}

function extractData(basicTimeValue: number, tpsi?: string): PerformanceEvent {
  const time: PerformanceTiming = window?.performance?.timing ?? ({} as any);
  const basicTime: number = basicTimeValue || 0;

  const nonZeroable = {
    connect_start: time.connectStart,
    connect_end: time.connectEnd,
    domComplete_ts: time.domComplete,
    dom_content_loaded_event_end: time.domContentLoadedEventEnd,
    dom_content_loaded_event_start: time.domContentLoadedEventStart,
    dom_interactive: time.domInteractive,
    domLoading_ts: time.domLoading,
    domain_lookup_end: time.domainLookupEnd,
    domain_lookup_start: time.domainLookupStart,
    fetch_start: time.fetchStart,
    request_start: time.requestStart,
    response_end: time.responseEnd,
    response_start: time.responseStart,
  };

  const zeroable = {
    load_event_end: time.loadEventEnd,
    load_event_start: time.loadEventStart,
    redirect_end: time.redirectEnd,
    redirect_start: time.redirectStart,
    secure_connection_start: time.secureConnectionStart,
    unload_event_end: time.unloadEventEnd,
    unload_event_start: time.unloadEventStart,
  };

  const windowParams = {
    window_width: window.innerWidth,
    window_height: window.innerHeight,
    screen_width: screen.width,
    screen_height: screen.height,
    avail_width: screen.availWidth,
    avail_height: screen.availHeight,
  };

  const navigationStart = time.navigationStart || 0;

  return {
    basic_loading_time: Math.ceil(basicTime),
    navigation_start: navigationStart,
    ...windowParams,
    ...nonZeroable,
    ...Object.assign(
      {},
      ...Object.keys(nonZeroable).map((key: keyof typeof nonZeroable) => {
        const value = nonZeroable[key] || 0;
        return { [key]: value - navigationStart };
      }),
    ),
    ...Object.assign(
      {},
      ...Object.keys(zeroable).map((key: keyof typeof zeroable) => {
        const value = zeroable[key] || 0;
        return { [key]: value === 0 ? 0 : value - navigationStart };
      }),
    ),
    tpsi,
  };
}

export function logPerformance(basicTime: number, tpsi?: string): Promise<void> {
  function reportBi() {
    const eventData = extractData(basicTime, tpsi);
    return logPerformanceEvent(eventData);
  }

  return new Promise((resolve) => {
    if (document.readyState === 'complete') {
      resolve(reportBi());
    } else {
      window.addEventListener('load', () => {
        setTimeout(() => {
          const res = reportBi();
          resolve(res);
        }, 0);
      });
    }
  });
}
