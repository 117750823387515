import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { HelmetProvider } from 'react-helmet-async';
import { HttpClient } from '@wix/http-client';
import { I18nextProvider } from '@wix/wix-i18n-config';

import { reportWebVitals } from '../BI/webVitals';
import { logPerformance } from '../BI/performance';
import { clientI18n } from '../i18n/client-i18n';
import { getBasename, getOrigin } from '../libs/urlUtils';
import { initCookieAndTagManagers } from '../utils/initCookieAndTagManagers';
import { initWixRecorder } from '../utils/initWixRecorder';
import { prefetchSantaEditor } from '../utils/prefetchSantaEditor';
import { ConsoleErrorReporter, SentryErrorReporter } from '../errorHandling/ErrorReporter';
import { BrowserApiClient } from '../apiClient/BrowserApiClient';
import { App } from './components/App/App';
import { createStores } from './stores/initStores';
import { RenderErrorsHandler } from './components/RenderErrorsHandler/RenderErrorsHandler';
import { EditorSessionIdProvider } from './contexts/EditorSessionIdContext';

const initialI18n = window.__INITIAL_I18N__;
const initialState = window.__INITIAL_STORES_STATE__;
const editorSessionId = window.__EDITOR_SESSION_ID__;

const baseURL = initialState.config.baseURL;
const basename = getBasename(baseURL);

(() => {
  if (window.location.origin !== getOrigin(baseURL)) {
    console.warn(`Application runs on wrong origin`);
    return;
  }

  const errorReporter = window.Sentry ? new SentryErrorReporter(window.Sentry) : new ConsoleErrorReporter();

  try {
    const httpClient = new HttpClient({ baseURL });
    const apiClient = new BrowserApiClient(httpClient);
    const history = createBrowserHistory({ basename });

    const i18nInstance = clientI18n(initialI18n, errorReporter);
    const stores = createStores({ i18nInstance, initialState, history, apiClient, errorReporter });
    const { baseURL: baseUrl, currentLanguage: language, consentPolicy } = stores.configStore.config;
    initCookieAndTagManagers({ baseUrl, language, consentPolicy }, errorReporter);
    initWixRecorder(initialState.experiments, errorReporter);

    window.addEventListener('load', () => {
      prefetchSantaEditor(stores.configStore.config.santaEditorBaseUrl);
    });

    const application = (
      <RenderErrorsHandler errorReporter={errorReporter}>
        <HelmetProvider>
          <Provider {...stores}>
            <Router history={history}>
              <I18nextProvider i18n={i18nInstance}>
                <EditorSessionIdProvider initialEditorSessionId={editorSessionId}>
                  <App />
                </EditorSessionIdProvider>
              </I18nextProvider>
            </Router>
          </Provider>
        </HelmetProvider>
      </RenderErrorsHandler>
    );

    if (stores.experimentsStore.is('specs.funnel.TemplatesGalleryUseReactDomHydrate', 'true')) {
      ReactDOM.hydrate(application, document.getElementById('root'));
    } else {
      ReactDOM.render(application, document.getElementById('root'));
    }

    stores.fedopsLoggerStore.reportAppLoaded();
    void logPerformance(window.appReady, initialState.config.templatePageSessionId);
    reportWebVitals(stores.biLoggerStore.logWebVitals.bind(stores.biLoggerStore));
  } catch (error) {
    errorReporter.reportError(error);
  }
})();
