import { stringify } from '../libs/queryString';
import { Template } from '../web-api/domain/template';

export function getEditorLink({
  template,
  editorSessionId,
  petri_ovr,
  dashboardFirstMetaSiteId,
  siteCreationFolderId,
}: {
  template: Template;
  editorSessionId: string;
  petri_ovr?: string;
  dashboardFirstMetaSiteId?: string;
  siteCreationFolderId?: string;
}): string {
  const { metaSiteId } = template;

  return `https://manage.wix.com/edit-template/from-intro${dashboardFirstMetaSiteId ? '-siteless' : ''}?${stringify({
    originTemplateId: metaSiteId,
    editorSessionId,
    petri_ovr,
    siteCreationFolderId,
    metaSiteId: dashboardFirstMetaSiteId,
  })}`;
}

export function getViewerLink({
  templateSlug,
  language,
  originUrl,
  clickedElement = 'view_button',
  editorSessionId,
  dashboardFirstMetaSiteId,
}: {
  templateSlug: string;
  language: string;
  originUrl: string;
  clickedElement?: 'view_button' | 'thumbnail';
  editorSessionId: string;
  dashboardFirstMetaSiteId?: string;
}): string {
  return `https://${
    language === 'en' ? 'www' : language
  }.wix.com/website-template/view/html/${templateSlug}?${stringify({
    originUrl,
    tpClick: clickedElement,
    esi: editorSessionId,
    ...(dashboardFirstMetaSiteId ? { dashboardFirstMetaSiteId } : null),
  })}`;
}
