import cs from 'classnames';
import React from 'react';
import { useDebounceCallback } from '@react-hook/debounce';
import { TemplateImage } from '../TemplateImage/TemplateImage';
import { BILoggerStore } from '../../../stores/BILoggerStore';
import { TemplatesStore } from '../../../stores/TemplatesStore';
import { isElementInView } from '../../../../utils/isElementInView';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { TemplateFilters } from '../TemplateFilters/TemplateFilters';
import { useOnWindowScroll } from '../../../hooks/useOnWindowScroll';
import { useOnWindowResize } from '../../../hooks/useOnWindowResize';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { Template as ApiTemplate } from '../../../../web-api/domain/template';
import { withTranslations, WithTranslations } from '../../../../utils/withTranslations';
import { ConfigStore } from '../../../stores/ConfigStore';
import AiBestMatchIcon from '../../../../Icons/AiBestMatch.svg';
import s from './Template.scss';
import { HoverPanel } from './HoverPanel/HoverPanel';
import { InfoPanel } from './InfoPanel/InfoPanel';
import { templateDataHooks } from './Template.dataHooks';

interface TemplateProps extends WithTranslations {
  template: ApiTemplate;
  galleryDocIndex: number;
  dataHook?: string;
  biLoggerStore: BILoggerStore;
  templatesStore: TemplatesStore;
  configStore: ConfigStore;
  isLastItemHidden?: boolean;
  isHiddenOnScreenM?: boolean;
  hasMatchBadge?: boolean;
}

const TemplateCmp: React.FC<TemplateProps> = (props) => {
  const {
    dataHook,
    galleryDocIndex,
    template,
    templatesStore,
    biLoggerStore,
    configStore,
    isLastItemHidden,
    isHiddenOnScreenM,
    t,
    hasMatchBadge,
  } = props;
  const isMounted = useIsMounted();
  const [hoverStartTime, setHoverStartTime] = React.useState<number | null>(null);
  const [isImageLoading, setIsImageLoading] = React.useState(false);
  const [isImageDelayLoading, setIsImageDelayLoading] = React.useState(false);
  const [isInfoShown, setIsInfoShown] = React.useState(false);

  const thumbnailRef = React.useRef<HTMLDivElement>();

  const reportIfVisibleToUser = (): void => {
    if (templatesStore.wasTemplateInView(template.id)) {
      return;
    }

    if (isElementInView(thumbnailRef.current)) {
      templatesStore.addViewedTemplateId(template.id);
      biLoggerStore.logTemplateInViewport({
        template_id: template.siteId,
        galleryDocIndex,
      });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(reportIfVisibleToUser, [templatesStore.responseUuid]);
  const debouncedReportIfVisibleToUser = useDebounceCallback(reportIfVisibleToUser, 500);
  useOnWindowScroll(debouncedReportIfVisibleToUser);
  useOnWindowResize(debouncedReportIfVisibleToUser);

  const onThumbnailEnter = () => {
    setHoverStartTime(Date.now());
  };

  const onThumbnailLeave = () => {
    toggleInfoPanel(false);

    biLoggerStore.logTemplateHover({
      galleryDocIndex,
      duration: Date.now() - (hoverStartTime || 0),
      product: template.title,
      templateId: template.siteId,
      siteId: template.siteId,
      hasVideo: !!template.videoSrc,
    });
  };

  const toggleInfoPanel = (changedIsInfoShown: boolean) => {
    if (changedIsInfoShown === isInfoShown) {
      return;
    }

    setIsInfoShown(changedIsInfoShown);
    if (changedIsInfoShown) {
      biLoggerStore.logTemplateInfoShown();
    } else {
      biLoggerStore.logTemplateInfoHidden();
    }
  };
  const templateTitleId = `template-title-${template.id}--${galleryDocIndex}`;

  const imageLoadingAnimationDelayTimer = React.useRef<number>();

  React.useEffect(() => () => window.clearTimeout(imageLoadingAnimationDelayTimer.current), [template.id]);

  const imageLoadingStarted = () => {
    setIsImageLoading(true);
    setIsImageDelayLoading(true);
    window.clearTimeout(imageLoadingAnimationDelayTimer.current);
    imageLoadingAnimationDelayTimer.current = window.setTimeout(() => setIsImageDelayLoading(false), 300);
  };

  const isAiBadge = configStore.config.introReferrer === 'ai_assistant';

  return (
    <li
      className={cs({ [s.lastItemHidden]: isLastItemHidden, [s.hiddenOnScreenM]: isHiddenOnScreenM })}
      data-hook={dataHook}
      data-bi-element="template_thumbnail"
      data-bi-element-value={template.siteId}
      data-bi-gallery-doc-index={galleryDocIndex}
      data-bi-template-has-video={template.videoSrc ? 'true' : 'false'}
    >
      <div aria-labelledby={templateTitleId} role="group">
        <div className={s.hoverArea} onMouseEnter={onThumbnailEnter} onMouseLeave={onThumbnailLeave}>
          {hasMatchBadge ? (
            <div
              className={isAiBadge ? s.bestAiMatchBadge : s.bestMatchBadge}
              data-hook={templateDataHooks.bestMatchBadge()}
            >
              {isAiBadge && <AiBestMatchIcon />}
              <span>{t('sort.bestMatch.title')}</span>
            </div>
          ) : null}
          <div className={s.browserHead} />
          <div className={s.thumbnailWrapper} ref={thumbnailRef}>
            <div className={s.thumbnail}>
              <div
                className={cs(s.image, {
                  [s.animationOverImage]: isMounted,
                  [s.loading]: isImageLoading || isImageDelayLoading,
                })}
              >
                <TemplateImage
                  template={template}
                  dataHook={templateDataHooks.image()}
                  lazyLoad={galleryDocIndex > 5}
                  onLoadStart={() => imageLoadingStarted()}
                  onLoad={() => setIsImageLoading(false)}
                />
              </div>
              <HoverPanel
                dataHook={templateDataHooks.hoverPanel()}
                className={s.hoverPanel}
                template={template}
                galleryDocIndex={galleryDocIndex}
                onInfoClick={() => toggleInfoPanel(true)}
                isBestMatch={!!hasMatchBadge}
              />
              <InfoPanel
                dataHook={templateDataHooks.infoPanel()}
                className={cs({
                  [s.infoPanel]: !isInfoShown,
                  [s.infoPanelShown]: isInfoShown,
                })}
                goodFor={template.info.goodFor}
                description={template.info.description}
                onCloseClick={() => toggleInfoPanel(false)}
              />
            </div>
          </div>
        </div>
        <div
          className={s.title}
          data-hook={templateDataHooks.title()}
          data-bi-element="template_title"
          data-bi-element-value={template.siteId}
          aria-hidden="true"
          id={templateTitleId}
        >
          <div>{template.title}</div>
        </div>
        <TemplateFilters dataHook={templateDataHooks.matchedFilters()} template={template} />
      </div>
    </li>
  );
};

export const Template = withTranslations(injectStoresV2('templatesStore', 'biLoggerStore', 'configStore')(TemplateCmp));
