import { joinSearchQuery, stringify, parse } from '../../../libs/queryString';

export const WHITELIST_SEARCH_QUERY_PARAMS = [
  'criteria',
  'page',
  'features',
  'layouts',
  'colors',
  'colorStyles',
  'sort',
] as const;
type QueryKey = (typeof WHITELIST_SEARCH_QUERY_PARAMS)[number];
type QueryParams = Partial<{ [key in QueryKey]: string }>;

export function stripUnknownQueryParams(search?: string): string {
  const paramsEntries = Object.entries(parse(search || ''));
  const newParams = Object.assign(
    Object.create(null),
    ...paramsEntries.filter(([key]) => WHITELIST_SEARCH_QUERY_PARAMS.includes(key as QueryKey)),
  );
  return joinSearchQuery(stringify(newParams));
}

export function stringifyQuery(params: QueryParams): string {
  return joinSearchQuery(stringify(params, { keysOrder: [...WHITELIST_SEARCH_QUERY_PARAMS] }));
}

export function parseQuery(search?: string): QueryParams {
  return parse(search || '') as QueryParams;
}
