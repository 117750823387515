import React from 'react';
import cs from 'classnames';
import styles from './LayoutIcon.scss';

interface HeroImageLayoutProps {
  active: boolean;
}

export const HeroImageLayout = ({ active = false }: HeroImageLayoutProps) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="92px"
      height="58px"
      viewBox="0 0 92 58"
      enableBackground="new 0 0 92 58"
      className={cs(styles.layoutIcon, { [styles.active]: active })}
    >
      <switch>
        <g>
          <path
            fill="#D6E6FF"
            stroke="#0C6EFC"
            className={cs(styles.fillBlue, styles.strokeBlue)}
            strokeMiterlimit="10"
            d="M3.5,57.5h85c1.657,0,3-1.343,3-3v-51c0-1.657-1.343-3-3-3h-85
          c-1.657,0-3,1.343-3,3v51C0.5,56.157,1.843,57.5,3.5,57.5z"
          />
          <g>
            <path
              fill="#0C6EFC"
              className={styles.fillWhite}
              d="M49.236,35.709h-6.471c-1.022,0-1.85-0.828-1.85-1.85l0,0c0-1.022,0.828-1.85,1.85-1.85h6.471
            c1.022,0,1.85,0.828,1.85,1.85v0C51.085,34.881,50.257,35.709,49.236,35.709z"
            />
            <g>
              <line
                fill="none"
                stroke="#0C6EFC"
                className={styles.strokeWhite}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="59.122"
                y1="22.291"
                x2="32.878"
                y2="22.291"
              />
              <line
                fill="none"
                stroke="#0C6EFC"
                className={styles.strokeWhite}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="55.133"
                y1="25.713"
                x2="36.867"
                y2="25.713"
              />
            </g>
          </g>
        </g>
      </switch>
    </svg>
  );
};
