import React from 'react';
import { ErrorReporterContext } from '../../contexts/ErrorReporterContext';
import { ErrorReporter } from '../../../errorHandling/ErrorReporter';

export class RenderErrorsHandler extends React.Component<{ errorReporter: ErrorReporter; children?: React.ReactNode }> {
  static displayName = 'RenderErrorsHandler';

  componentDidMount() {
    if (!this.props.errorReporter) {
      console.error(`<RenderErrorsHandler/> mount without ErrorReporter instance. Check mobx stores inject.`);
    }
  }

  componentDidCatch(error: Error, { componentStack }: React.ErrorInfo): void {
    if (this.props.errorReporter) {
      this.props.errorReporter.reportError(error, { componentStack });
    }
  }

  render() {
    const { children, errorReporter } = this.props;
    if (!children) {
      return null;
    }

    return <ErrorReporterContext.Provider value={errorReporter}>{children}</ErrorReporterContext.Provider>;
  }
}
