import { generatePath, matchPath } from 'react-router';
import { buildSortFilterSearchQuery, parseSortFiltersSearchQuery, SortFiltersParams } from './sortFiltersParams';
import { createRoute } from './createRoute';

export interface SubCategoryPageParams extends SortFiltersParams {
  page: number;
  categorySlug: string;
  subCategorySlug: string;
}

export interface SubCategory {
  routeName: 'subCategory';
  params: SubCategoryPageParams;
}

export const subCategoryRoute = createRoute<SubCategoryPageParams, 'categorySlug' | 'subCategorySlug'>({
  pattern: '/html/:categorySlug/:subCategorySlug/:page?',
  match: (pattern, location): SubCategoryPageParams => {
    const { pathname, search } = location;
    const match = matchPath<{ categorySlug: string; subCategorySlug: string; page: string }>(pathname, {
      path: pattern,
      exact: true,
      sensitive: false,
    });

    if (!match) {
      return null;
    }

    const pageNumber = Number(match.params.page);

    return {
      ...parseSortFiltersSearchQuery(search),
      categorySlug: decodeURIComponent(match.params.categorySlug ?? ''),
      subCategorySlug: decodeURIComponent(match.params.subCategorySlug ?? ''),
      page: pageNumber > 1 ? pageNumber : 1,
    };
  },
  build: (pattern, params) => {
    const { page, categorySlug, subCategorySlug } = params;
    const pathParams = {
      page: page === 1 ? undefined : page,
      categorySlug: (categorySlug || '').toLowerCase(),
      subCategorySlug: (subCategorySlug || '').toLowerCase(),
    };

    const sortFiltersSearchQuery = buildSortFilterSearchQuery(params);

    return {
      pathname: generatePath(pattern, pathParams),
      search: sortFiltersSearchQuery,
    };
  },
});
