import { getImageExtensionFromType, ImageMime } from '../../utils/imageTypes';
import { getImageFilenameAndExtension } from './getImageFilenameAndExtension';

interface ImageURLProps {
  imagePath: string;
  width: number;
  height: number;
  type: ImageMime;
  filters: string;
  imagesDomain: string;
}
export function getMediaPlatformImageUrl({
  type,
  imagePath,
  imagesDomain,
  width,
  height,
  filters,
}: ImageURLProps): string {
  const extension = getImageExtensionFromType(type);
  const { filename } = getImageFilenameAndExtension(imagePath);
  const allFilters = encodeURIComponent(`w_${width},h_${height},${filters}`);

  return `//${imagesDomain}${imagePath}/v1/fill/${allFilters}/${filename}.${extension}`;
}
