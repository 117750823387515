import { IHttpClient } from '@wix/http-client';
import { stringify } from '../libs/queryString';
import { CategoryTemplatesResponse, SearchTemplatesResponse } from '../web-api/domain/templatesResponse';
import { SearchSuggestionsResponse } from '../web-api/domain/searchSuggestionsResponse';
import { CategoriesResponse } from '../web-api/domain/categoriesResponse';
import {
  ApiClient,
  ApiClientBrowseParams,
  ApiClientSearchParams,
  ApiClientFetchParams,
  ApiClientSearchSuggestionsParams,
  TemplateMailerSendParams,
} from './ApiClient';
import {
  buildBrowseRequestQuery,
  buildFetchRequestQuery,
  buildSearchRequestQuery,
  buildSearchSuggestionsRequestQuery,
} from './buildRequestQuery';

export class BrowserApiClient implements ApiClient {
  constructor(private httpClient: IHttpClient) {}

  search(params: ApiClientSearchParams, previousUrl?: string): Promise<SearchTemplatesResponse> {
    const query = buildSearchRequestQuery(params);
    return this.fetch<SearchTemplatesResponse>(`/api/search?${stringify(query)}`, previousUrl);
  }

  browse(params: ApiClientBrowseParams, previousUrl?: string): Promise<CategoryTemplatesResponse> {
    const query = buildBrowseRequestQuery(params);
    return this.fetch<CategoryTemplatesResponse>(`/api/v2/templates?${stringify(query)}`, previousUrl);
  }

  fetchTemplates(params: ApiClientFetchParams, previousUrl?: string): Promise<CategoryTemplatesResponse> {
    const query = buildFetchRequestQuery(params);

    return this.fetch<CategoryTemplatesResponse>(`/api/templates?${stringify(query)}`, previousUrl);
  }

  fetchCategories(): Promise<CategoriesResponse> {
    return Promise.reject(new Error('Fetching categories from /api is not possible'));
  }

  fetchSearchSuggestions(params: ApiClientSearchSuggestionsParams): Promise<SearchSuggestionsResponse> {
    const criteria = encodeURIComponent(params.criteria);
    const query = buildSearchSuggestionsRequestQuery({ ...params, criteria });
    return this.fetch<SearchSuggestionsResponse>(`/api/search-suggestions?${stringify(query)}`);
  }

  private async fetch<T>(url: string, previousUrl?: string) {
    const { data } = await this.httpClient.get<T>(url, {
      headers: {
        'x-templates-gallery-referrer': previousUrl || '',
      },
    });
    return data;
  }

  async templateMailerSend(params: TemplateMailerSendParams): Promise<void> {
    await this.httpClient.post('/api/template-mailer/send', params);
  }
}
