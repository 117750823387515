import { MatchedRoute } from '../RoutingStore';
import { TemplateStoreFetchParams } from '../TemplatesStore';

export const getActiveCategorySlug = (route: MatchedRoute | null, primaryCategorySlug: string): string | undefined => {
  if (!route) {
    return undefined;
  }

  if (route.routeName === 'home') {
    return primaryCategorySlug;
  }

  if (route.routeName === 'category' || route.routeName === 'subCategory') {
    return route.params.categorySlug;
  }

  return undefined;
};

export const getActiveSubCategorySlug = (route: MatchedRoute | null): string | undefined => {
  if (!route) {
    return undefined;
  }
  if (route.routeName === 'subCategory') {
    return route.params.subCategorySlug;
  }

  return undefined;
};

export const getFilterAndSortParameters = (fetchParams: TemplateStoreFetchParams | null) => {
  return {
    styles: fetchParams?.filters.colorStyles.join(',') || '',
    colors: fetchParams?.filters.colors.join(',') || '',
    layouts: fetchParams?.filters.layouts.join(',') || '',
    features: fetchParams?.filters.features.join(',') || '',
    sorting: fetchParams?.sortCategorySlug || '',
  };
};
