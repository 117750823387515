import { getPathname, joinUrlParts, stripTrailingSlash } from '../../libs/urlUtils';
import { RouteLocation } from './routes/createRoute';
import { ConfigStore } from './ConfigStore';

export class BaseURLStore {
  constructor(private readonly configStore: ConfigStore) {}

  buildURL(location: RouteLocation): string {
    const { baseURL } = this.configStore.config;
    return createPath(baseURL, location);
  }

  buildLangURL(location: RouteLocation, lang?: string): string {
    const { baseURL, domain, currentLanguage } = this.configStore.config;
    const language = lang || currentLanguage;
    const basePathname = getPathname(baseURL);
    const langDomain = `${language === 'en' ? 'www' : language}.${domain}`;
    return createPath(joinUrlParts('https://', langDomain, basePathname), location);
  }
}

function createPath(baseURL: string, location: RouteLocation): string {
  const { pathname, search } = location;

  let path = stripTrailingSlash(joinUrlParts(baseURL, pathname));

  if (search && search !== '?') {
    path += search.charAt(0) === '?' ? search : `?${search}`;
  }

  return path;
}
