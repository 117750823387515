interface WebVitalsMetric {
  name: 'CLS' | 'FCP' | 'FID' | 'LCP' | 'TTFB';
  value: number;
  delta: number;
  id: string;
}

type ReportHandler = (metric: WebVitalsMetric) => void;

async function reportWebVitals(onReportReady: ReportHandler) {
  const moduleData = await import(/* webpackChunkName: "webVitals" */ 'web-vitals');
  const { getCLS, getFCP, getFID, getLCP, getTTFB } = moduleData;

  getCLS(onReportReady);
  getFCP(onReportReady);
  getFID(onReportReady);
  getLCP(onReportReady);
  getTTFB(onReportReady);
}

export { WebVitalsMetric, reportWebVitals };
