import React from 'react';
import cs from 'classnames';
import styles from './LayoutIcon.scss';

interface FramedLayoutProps {
  active: boolean;
}

export const FramedLayout = ({ active = false }: FramedLayoutProps) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="92px"
      height="58px"
      viewBox="0 0 92 58"
      enableBackground="new 0 0 92 58"
      className={cs(styles.layoutIcon, { [styles.active]: active })}
    >
      <switch>
        <g>
          <g>
            <path
              fill="#D6E6FF"
              className={styles.fillBlue}
              d="M6.5,50.5v-43c0-0.552,0.448-1,1-1h77c0.552,0,1,0.448,1,1v43c0,0.552-0.448,1-1,1h-77
      C6.948,51.5,6.5,51.052,6.5,50.5z"
            />
          </g>
          <path
            fill="none"
            stroke="#0C6EFC"
            className={styles.strokeBlue}
            strokeMiterlimit="10"
            d="M3.5,57.5h85c1.657,0,3-1.343,3-3v-51c0-1.657-1.343-3-3-3h-85
    c-1.657,0-3,1.343-3,3v51C0.5,56.157,1.843,57.5,3.5,57.5z"
          />
          <g>
            <path
              fill="#0C6EFC"
              className={styles.fillWhite}
              d="M49.253,30.375h-6.619c-1.045,0-1.892-0.847-1.892-1.892v0c0-1.045,0.847-1.892,1.892-1.892h6.619
      c1.045,0,1.892,0.847,1.892,1.892v0C51.145,29.528,50.298,30.375,49.253,30.375z"
            />
            <g>
              <line
                fill="none"
                stroke="#0C6EFC"
                className={styles.strokeWhite}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="59.366"
                y1="16.65"
                x2="32.522"
                y2="16.65"
              />

              <line
                fill="none"
                stroke="#0C6EFC"
                className={styles.strokeWhite}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="55.286"
                y1="20.15"
                x2="36.602"
                y2="20.15"
              />
            </g>
          </g>
        </g>
      </switch>
    </svg>
  );
};
