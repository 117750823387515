import React from 'react';
import cs from 'classnames';
import styles from './LayoutIcon.scss';

interface UniqueLayoutProps {
  active: boolean;
}

export const UniqueLayout = ({ active = false }: UniqueLayoutProps) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="92px"
      height="58px"
      viewBox="0 0 92 58"
      enableBackground="new 0 0 92 58"
      className={cs(styles.layoutIcon, { [styles.active]: active })}
    >
      <switch>
        <g>
          <g>
            <path
              fill="#D6E6FF"
              className={styles.fillBlue}
              d="M90.56,43.895H58.078c-0.552,0-1-0.448-1-1V1.233c0-0.552,0.448-1,1-1H87.56c2.209,0,4,1.791,4,4v38.662
				C91.56,43.447,91.112,43.895,90.56,43.895z"
            />
          </g>
          <path
            fill="none"
            stroke="#0C6EFC"
            className={styles.strokeBlue}
            strokeMiterlimit="10"
            d="M3.5,57.5h85c1.657,0,3-1.343,3-3v-51c0-1.657-1.343-3-3-3h-85
			c-1.657,0-3,1.343-3,3v51C0.5,56.157,1.843,57.5,3.5,57.5z"
          />
          <g>
            <path
              fill="#D6E6FF"
              className={styles.fillBlue}
              d="M45.282,57H7.174V33.815c0-0.692,0.561-1.252,1.252-1.252H44.03c0.692,0,1.252,0.561,1.252,1.252V57z"
            />
          </g>
          <g>
            <path
              fill="#0C6EFC"
              className={styles.fillBlue}
              d="M16.271,22.745H8.923c-1.16,0-2.1-0.94-2.1-2.1v0c0-1.16,0.94-2.1,2.1-2.1h7.348c1.16,0,2.1,0.94,2.1,2.1
				v0C18.371,21.804,17.431,22.745,16.271,22.745z"
            />
            <g>
              <line
                fill="none"
                stroke="#0C6EFC"
                className={styles.strokeBlue}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="36.622"
                y1="7.509"
                x2="6.823"
                y2="7.509"
              />

              <line
                fill="none"
                stroke="#0C6EFC"
                className={styles.strokeBlue}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="27.563"
                y1="11.395"
                x2="6.823"
                y2="11.395"
              />
            </g>
          </g>
        </g>
      </switch>
    </svg>
  );
};
