import { dataHooks } from '../../../../libs/dataHooks';

export const templateDataHooks = dataHooks<{
  image: {};
  hoverPanel: {};
  infoPanel: {};
  title: {};
  matchedFilters: {};
  hoverArea: {};
  bestMatchBadge: {};
}>('template');
