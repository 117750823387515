import React from 'react';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { RoutingStore } from '../../../stores/RoutingStore';
import { FilterDropdown } from '../FilterDropdown/FilterDropdown';
import { Color, ColorStyles, EMPTY_FILTERS } from '../../../stores/routes/sortFiltersParams';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import { colorsFilterDataHooks } from './ColorsFilter.dataHooks';
import { ColorsFilterItem } from './ColorsFilterItem/ColorsFilterItem';
import styles from './ColorsFilter.scss';

export const COLORS: Color[] = [
  'blue',
  'red',
  'yellow',
  'pink',
  'purple',
  'green',
  'orange',
  'brown',
  'grey',
  'white',
  'black',
];
export const COLOR_STYLES: ColorStyles[] = ['bright', 'dark', 'colorful'];

interface ColorFilterProps extends WithTranslations {
  routingStore: RoutingStore;
  dataHook?: string;
}

const ColorsFilterCmp: React.FC<ColorFilterProps> = ({ routingStore, dataHook, t }) => {
  const appliedFilters = routingStore.appliedFilters ?? EMPTY_FILTERS;
  const activeColorsFilters = [...appliedFilters.colors, ...appliedFilters.colorStyles];
  const clearLink = routingStore.rebuildRoute({
    page: 1,
    filters: {
      ...appliedFilters,
      colors: [],
      colorStyles: [],
    },
  });

  return (
    <FilterDropdown
      dataHook={dataHook}
      clearLink={clearLink}
      title={t('filters.title.colors')}
      filterType="colors"
      appliedFiltersCount={activeColorsFilters.length}
    >
      <ul className={styles.content}>
        {[...COLOR_STYLES, ...COLORS].map((id) => (
          <li key={id}>
            <ColorsFilterItem filterId={id} dataHook={colorsFilterDataHooks.filterItem({ id })} />
          </li>
        ))}
      </ul>
    </FilterDropdown>
  );
};

export const ColorsFilter = withTranslations(injectStoresV2('routingStore')(ColorsFilterCmp));
