import cs from 'classnames';
import React from 'react';
import { injectStoresV2 } from '../../../../stores/injectStoresV2';
import { RoutingStore } from '../../../../stores/RoutingStore';
import { WithTranslations, withTranslations } from '../../../../../utils/withTranslations';
import { categoryTagListDataHooks } from './CategoryTagList.dataHooks';
import { CategoryProps, CategoryTag } from './CategoryTag/CategoryTag';
import s from './CategoryTagList.scss';

interface CategoryTagListProps extends WithTranslations {
  categories: Pick<CategoryProps, 'slug' | 'title' | 'location' | 'isActive' | 'isAll'>[];
  routingStore: RoutingStore;
  dataHook?: string;
}

const CategoryTagListCmp: React.FC<CategoryTagListProps> = (props) => {
  const { categories, dataHook, routingStore, t } = props;

  const ulRef = React.useRef<HTMLUListElement>();
  const itemRefs = React.useRef<HTMLLIElement[]>([]);
  const { activeCategorySlug } = routingStore;

  return (
    <nav
      className={s.root}
      data-hook={dataHook}
      aria-label={t('categoryTagList.nav.ariaLabel', { activeCategorySlug })}
    >
      <ul className={cs(s.list, s.expanded)} ref={ulRef}>
        {categories.map((category, index) => (
          <CategoryTag
            {...category}
            key={category.slug}
            itemRef={itemRefs.current ? (ref) => (itemRefs.current[index] = ref) : undefined}
            dataHook={categoryTagListDataHooks.category({ slug: category.slug })}
            order={index}
          />
        ))}
      </ul>
    </nav>
  );
};

export const CategoryTagList = withTranslations(injectStoresV2('routingStore')(CategoryTagListCmp));
