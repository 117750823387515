import React, { FC, useEffect, useRef } from 'react';

import { useTranslation } from '@wix/wix-i18n-config';
import { useStores } from '../../hooks/useStores';

import AiBestMatchIcon from '../../../Icons/AiBestMatch.svg';

import { useInViewport } from '../../hooks/useInViewport';
import s from './SiteGeneratorBanner.scss';

const SITE_GENERATOR_LP_URL = 'https://manage.wix.com/ai-assistant?flow=sg-lp';
const ENCODED_SITE_GENERATOR_LP_URL = encodeURIComponent(SITE_GENERATOR_LP_URL);
const SITE_GENERATOR_LOGIN_URL = `https://users.wix.com/signin?view=sign-up&loginCompName=SignUp_H&postSignUp=${ENCODED_SITE_GENERATOR_LP_URL}&postLogin=${ENCODED_SITE_GENERATOR_LP_URL}&forceRender=true`;
const SITE_GENERATOR_EDITOR_URL =
  'https://manage.wix.com/edit-template/wizard?flow=templates_page&origin=site_generator&editor=sg';
const BLANK_TEMPLATE_ID = 'f5d37d37-5242-4647-ad0d-dfaf72e91b14';

export const SiteGeneratorBanner: FC = () => {
  const { t } = useTranslation();
  const { configStore, routingStore, biLoggerStore } = useStores();
  const bannerRef = useRef<HTMLDivElement>(null);
  const isInViewport = useInViewport(bannerRef);

  useEffect(() => {
    if (isInViewport) {
      biLoggerStore.logSawElement({ elementName: 'sg_entry_point' });
    }
  }, [isInViewport]);

  const handleCtaClick = () => {
    biLoggerStore.logClickElement({ clicked_element: 'sg_entry_point', clicked_element_identifier: 'sg_entry_point' });
  };

  let ctaHref = SITE_GENERATOR_LOGIN_URL;
  const isLoggedIn = configStore.config.identity.loggedIn;
  if (isLoggedIn) {
    const { dashboardFirstMetaSiteId } = routingStore.getGlobalUrlParams();
    ctaHref = dashboardFirstMetaSiteId
      ? `https://manage.wix.com/edit-template/from-intro-siteless?flow=templates_page&metaSiteId=${dashboardFirstMetaSiteId}&originTemplateId=${BLANK_TEMPLATE_ID}&editor=sg`
      : SITE_GENERATOR_EDITOR_URL;
  }

  return (
    <section className={s.banner} ref={bannerRef}>
      <div className={s.title}>{t('banner.sg.title')}</div>
      <div className={s.subTitle}>{t('banner.sg.subTitle')}</div>
      <a className={s.cta} href={ctaHref} target={isLoggedIn ? '_self' : '_blank'} onClick={handleCtaClick}>
        <span>{t('banner.sg.cta')}</span>
        <AiBestMatchIcon className={s.ctaIcon} />
      </a>
    </section>
  );
};
