type BasicOption = boolean | number | string | null | undefined;
type BasicDataHooks<Hooks extends { [key: string]: object }> = {
  [Elem in keyof Hooks]: {
    [Option in keyof Hooks[Elem]]: Hooks[Elem][Option];
  };
};

function handler<DataHooks extends BasicDataHooks<DataHooks>>(componentName: string) {
  return {
    get<Elem extends keyof DataHooks & string>(obj: any, element: Elem) {
      return (options?: Partial<DataHooks[Elem]>) =>
        [componentName + '__' + element, ...getStrOptions(options).map((strOption) => `${element}--${strOption}`)].join(
          ' ',
        );
    },
  };
}

type Proxify<DataHooks extends BasicDataHooks<DataHooks>> = {
  [Elem in keyof DataHooks]: (options?: Partial<DataHooks[Elem]>) => string;
};

export function dataHooks<DataHooks extends BasicDataHooks<DataHooks>>(componentName: string): Proxify<DataHooks> {
  return new Proxy({}, handler<DataHooks>(componentName));
}

function getStrOptions<Options extends { [key: string]: BasicOption }>(options: Options): string[] {
  return Object.entries(options || {})
    .map(([key, value]) => {
      if (typeof value === 'boolean' || value === null || value === undefined) {
        return value ? key : null;
      }

      return `${key}_${value}`;
    })
    .filter(Boolean);
}
