import { joinSearchQuery } from '../../../libs/queryString';
import { parseQuery, stringifyQuery } from './queryParams';
import { RouteLocation } from './createRoute';

export type Color =
  | 'blue'
  | 'red'
  | 'yellow'
  | 'pink'
  | 'purple'
  | 'green'
  | 'orange'
  | 'brown'
  | 'grey'
  | 'white'
  | 'black';
export type ColorStyles = 'bright' | 'dark' | 'colorful';
export type Layout = 'hero-image' | 'strip' | 'framed' | 'inline' | 'split-screen' | 'grid' | 'text' | 'unusual';
export type Feature =
  | 'gallery'
  | 'blog'
  | 'booking'
  | 'store'
  | 'events'
  | 'pricing'
  | 'video'
  | 'instagram'
  | 'music'
  | 'forum'
  | 'restaurant';
export type FilterKey = Feature | Color | ColorStyles | Layout;

export const EMPTY_FILTERS = {
  colors: [],
  features: [],
  colorStyles: [],
  layouts: [],
};

export interface FilterCollection {
  features: Feature[];
  colors: Color[];
  colorStyles: ColorStyles[];
  layouts: Layout[];
}

export interface SortFiltersParams {
  sortCategorySlug: string | null;
  filters: FilterCollection;
}

export function isLocationsSame(first: RouteLocation, second: RouteLocation) {
  return first.pathname === second.pathname && joinSearchQuery(first.search) === joinSearchQuery(second.search);
}

export function parseSortFiltersSearchQuery(search?: string): SortFiltersParams {
  const { features, colors, colorStyles, layouts, sort } = parseQuery(search);

  return {
    sortCategorySlug: sort ? sort : null,
    filters: {
      features: features ? (features.split(',') as Feature[]) : [],
      colors: colors ? (colors.split(',') as Color[]) : [],
      colorStyles: colorStyles ? (colorStyles.split(',') as ColorStyles[]) : [],
      layouts: layouts ? (layouts.split(',') as Layout[]) : [],
    },
  };
}

export function buildSortFilterSearchQuery(params: Partial<SortFiltersParams>): string {
  const { sortCategorySlug, filters = { colors: [], colorStyles: [], features: [], layouts: [] } } = params;
  const { features = [], colors = [], layouts = [], colorStyles = [] } = filters;

  const query = {
    sort: sortCategorySlug === null ? undefined : sortCategorySlug,
    features: features.length === 0 ? undefined : [...features].sort().join(','),
    colors: colors.length === 0 ? undefined : [...colors].sort().join(','),
    colorStyles: colorStyles.length === 0 ? undefined : [...colorStyles].sort().join(','),
    layouts: layouts.length === 0 ? undefined : [...layouts].sort().join(','),
  };

  return stringifyQuery(query);
}
