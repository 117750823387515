import React from 'react';

import { injectStoresV2 } from '../../stores/injectStoresV2';
import { ConfigStore } from '../../stores/ConfigStore';
import { useErrorReporter } from '../../contexts/ErrorReporterContext';
import { useIsMounted } from '../../hooks/useIsMounted';

import styles from './DealerBanner.scss';

function getDealerViewComponent() {
  if (typeof window === 'undefined') {
    return null;
  }

  return window.wixReactDealerViewer?.DealerViewer ?? null;
}

interface DealerBannerProps {
  configStore: ConfigStore;
  bannerPosition: string;
}

const DealerBannerCmp: React.FC<DealerBannerProps> = ({ configStore, bannerPosition }) => {
  const isMounted = useIsMounted();
  const dealerViewComponent = React.useRef(getDealerViewComponent());
  const DealerViewer = dealerViewComponent.current;
  const errorReporter = useErrorReporter();

  if (!isMounted || !DealerViewer) {
    return null;
  }

  const handleError = () => errorReporter.reportError(new Error('Error in DealerViewer component'));

  const { dealerCmsTranslationsUrl, currentLanguage } = configStore.config;
  // DealerViewer may call onError if onEmpty is not provided
  // Therefore, we have to stub it ¯\_(ツ)_/¯
  const onEmptyStub = () => {};

  return (
    <div className={styles.wrapper}>
      {/* @ts-expect-error */}
      <DealerViewer
        bannerPosition={bannerPosition}
        translationsUrl={dealerCmsTranslationsUrl}
        locale={currentLanguage}
        onError={handleError}
        onEmpty={onEmptyStub}
      />
    </div>
  );
};

export const DealerBanner = injectStoresV2('configStore')(DealerBannerCmp);
