import React from 'react';
import { RoutingStore } from '../../../stores/RoutingStore';
import { ExperimentsStore } from '../../../../stores/ExperimentsStore';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { Template } from '../Template/Template';
import { Banner } from '../Banner/Banner';
import { ConfigStore } from '../../../stores/ConfigStore';
import { Template as TemplateType } from '../../../../web-api/domain/template';
import { TemplatesStore } from '../../../stores/TemplatesStore';
import { templatesGridDataHooks } from './TemplatesGrid.dataHooks';
import s from './TemplatesGrid.scss';

interface TemplatesGridProps {
  routingStore: RoutingStore;
  experimentsStore: ExperimentsStore;
  dataHook?: string;
  items: TemplateType[];
  responseUuid: string;
  configStore: ConfigStore;
  templatesStore: TemplatesStore;
}

const TemplatesGridCmp = (props: TemplatesGridProps) => {
  const {
    configStore,
    templatesStore: { loadedFetchParams, bestMatchItemIds },
    dataHook,
    items,
  } = props;

  const shouldRenderBanner = (index: number) => {
    const page = loadedFetchParams.page ?? 1;
    const isEnglishLanguage = configStore.config.currentLanguage === 'en';
    const isFirstPage = page === 1;
    const isBanerEnabled = props.experimentsStore.is('specs.funnel.TemplatesGalleryEnableBanner', 'true');
    const isThirdItem = index === 2;

    return isBanerEnabled && isEnglishLanguage && isFirstPage && isThirdItem;
  };

  return (
    <ul className={s.templates} data-hook={dataHook}>
      {items.map((template, index) => (
        <React.Fragment key={template.id}>
          <Template
            dataHook={templatesGridDataHooks.template()}
            template={template}
            galleryDocIndex={index}
            isHiddenOnScreenM={shouldRenderBanner(index)}
            hasMatchBadge={bestMatchItemIds.includes(template.id)}
          />
          {shouldRenderBanner(index) && <Banner dataHook={templatesGridDataHooks.banner()} />}
        </React.Fragment>
      ))}
    </ul>
  );
};

export const TemplatesGrid = injectStoresV2(
  'routingStore',
  'experimentsStore',
  'configStore',
  'routingStore',
  'templatesStore',
)(TemplatesGridCmp);
