import { dataHooks } from '../../../libs/dataHooks';

export const numericPaginationDataHooks = dataHooks<{
  pageLink: { page: number };
  firstArrowLink: {};
  previousArrowLink: {};
  nextArrowLink: {};
  lastArrowLink: {};
  dots: {};
  spacer: {};
}>('numericPagination');
