export function addLeadingStr(path: string, str: string) {
  return (path.startsWith(str) ? '' : str) + path;
}

export function addTrailingStr(path: string, str: string) {
  return path + (path.endsWith(str) ? '' : str);
}

export function stripLeadingStr(path: string, str: string) {
  return path.startsWith(str) ? path.substring(str.length) : path;
}

export function stripTrailingStr(path: string, str: string) {
  return path.endsWith(str) ? path.slice(0, -str.length) : path;
}
