import React, { ReactElement } from 'react';
import cs from 'classnames';
import { Trans } from '@wix/wix-i18n-config';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { CategoriesStore } from '../../../stores/CategoriesStore';
import { OverriddenCategoryNamesStore } from '../../../../stores/OverriddenCategoryNamesStore';
import { isFiltersEmpty, RoutingStore } from '../../../stores/RoutingStore';
import { TemplatesStore } from '../../../stores/TemplatesStore';
import { getActiveCategorySlug, getActiveSubCategorySlug } from '../../../stores/routes/route';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import { ExperimentsStore } from '../../../../stores/ExperimentsStore';
import { TranslationKey } from '../../../../i18n/i18n';
import { subTitleDataHooks } from './SubTitle.dataHooks';
import s from './SubTitle.scss';

interface SubTitleProps extends WithTranslations {
  overriddenCategoryNamesStore: OverriddenCategoryNamesStore;
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
  templatesStore: TemplatesStore;
  experimentsStore: ExperimentsStore;
  dataHook?: string;
}

const SubTitleCmp: React.FC<SubTitleProps> = ({
  overriddenCategoryNamesStore,
  templatesStore,
  t,
  dataHook,
  categoriesStore,
  routingStore,
  experimentsStore,
}) => {
  const { primaryCategorySlug } = routingStore;
  const isEnlargedSearchEnabled = experimentsStore.is('specs.funnel.TemplatesGalleryEnlargedSearchInput', 'true');
  const searchSubTitleTranslationKey: TranslationKey = isEnlargedSearchEnabled
    ? 'subHeader.subTitle.search.bold'
    : 'subHeader.subTitle.search';

  const getTitle = (): string | ReactElement => {
    const activeSubCategorySlug = getActiveSubCategorySlug(routingStore.matchedRoute);
    const activeCategorySlug = getActiveCategorySlug(routingStore.matchedRoute, primaryCategorySlug);

    if (activeCategorySlug || activeSubCategorySlug) {
      return getCategoryTitle({ categorySlug: activeCategorySlug, subCategorySlug: activeSubCategorySlug });
    }

    if (routingStore.matchedRoute.routeName === 'search' && templatesStore.loadedFetchParams?.type === 'search') {
      const searchTextKey: TranslationKey =
        isFiltersEmpty(templatesStore.loadedFetchParams.filters) && templatesStore.isNoResultSuggestions
          ? 'subHeader.subTitle.noResults'
          : searchSubTitleTranslationKey;

      return <Trans i18nKey={searchTextKey} values={{ query: templatesStore.loadedFetchParams.criteria }} />;
    }

    if (routingStore.matchedRoute.routeName === 'search' && templatesStore.loadedFetchParams?.type === 'category') {
      const { categorySlug, subCategorySlug } = templatesStore.loadedFetchParams;
      return getCategoryTitle({ categorySlug, subCategorySlug });
    }

    return t('subHeader.subTitle.allTemplates');
  };

  const getCategoryTitle = ({
    categorySlug,
    subCategorySlug,
  }: {
    categorySlug: string;
    subCategorySlug: string | null;
  }) => {
    const isPrimaryCategory = routingStore.primaryCategorySlug === categorySlug;
    const category = categorySlug && categoriesStore.getCategoryBySlug(categorySlug);
    const subCategory = subCategorySlug && categoriesStore.getCategoryBySlug(subCategorySlug);
    const categoryToShow = subCategory || category;

    if (isPrimaryCategory || !categoryToShow) {
      return t('subHeader.subTitle.allTemplates');
    }

    const categoryName = overriddenCategoryNamesStore.getPageTitleCategoryName(categoryToShow);

    return t('subHeader.subTitle.category.v2', { category: categoryName });
  };

  const TitleTagName = routingStore.matchedRoute.routeName === 'home' ? 'h2' : 'h1';

  return (
    <div tabIndex={-1} className={s.root} data-hook={dataHook}>
      <TitleTagName
        className={cs(s.heading, { [s.bold]: !isEnlargedSearchEnabled })}
        data-hook={subTitleDataHooks.title()}
        aria-live="assertive"
        aria-atomic="true"
      >
        {getTitle()}
      </TitleTagName>
    </div>
  );
};

export const SubTitle = withTranslations(
  injectStoresV2(
    'routingStore',
    'categoriesStore',
    'templatesStore',
    'overriddenCategoryNamesStore',
    'experimentsStore',
  )(SubTitleCmp),
);
