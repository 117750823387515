import cs from 'classnames';
import React from 'react';
import { SeoLink } from '../../../SeoLink/SeoLink';
import FilterCheckmark from '../../../../../Icons/FilterCheckmark.svg';
import { EMPTY_FILTERS, Feature } from '../../../../stores/routes/sortFiltersParams';
import { FilterFeatureName } from '../../../FilterFeatureName/FilterFeatureName';
import { FilterFeatureDescription } from '../../../FilterFeatureDescription/FilterFeatureDescription';
import { injectStoresV2 } from '../../../../stores/injectStoresV2';
import { RoutingStore } from '../../../../stores/RoutingStore';
import { BILoggerStore } from '../../../../stores/BILoggerStore';
import styles from './FeatureFiltersItem.scss';
import { featuresFilterItemDataHooks } from './FeaturesFilterItem.dataHooks';

interface FilterFeaturesItemProps {
  routingStore: RoutingStore;
  feature: Feature;
  dataHook?: string;
  biLoggerStore: BILoggerStore;
}

export const FilterFeaturesItemCmp: React.FC<FilterFeaturesItemProps> = ({
  feature,
  dataHook,
  routingStore,
  biLoggerStore,
}) => {
  const appliedFilters = routingStore.appliedFilters ?? EMPTY_FILTERS;
  const isActiveFilter = appliedFilters.features.includes(feature);
  const valuesOnFilterSelect = isActiveFilter
    ? appliedFilters.features.filter((id) => id !== feature)
    : [...appliedFilters.features, feature];

  const link = routingStore.rebuildRoute({
    page: 1,
    filters: {
      ...appliedFilters,
      features: valuesOnFilterSelect,
    },
  });

  const onSelect = (event: React.MouseEvent) => {
    event.stopPropagation();

    const params = {
      previousValues: appliedFilters,
      selectedValues: {
        ...appliedFilters,
        features: valuesOnFilterSelect,
      },
      clickedItemValue: feature,
    };

    isActiveFilter
      ? biLoggerStore.logUnselectFilter({ type: 'features', ...params })
      : biLoggerStore.logSelectFilter({ type: 'features', ...params });
  };

  return (
    <SeoLink
      rel="nofollow"
      className={cs(styles.filterLink, { [styles.filterLinkActive]: isActiveFilter })}
      to={link}
      dataHook={dataHook}
      onClick={(event) => onSelect(event)}
    >
      <FilterCheckmark fill="currentColor" width="1em" height="1em" className={styles.checkbox} />
      <span className={styles.title} data-hook={featuresFilterItemDataHooks.title()}>
        <FilterFeatureName feature={feature} />
      </span>
      <span className={styles.description} data-hook={featuresFilterItemDataHooks.description()}>
        <FilterFeatureDescription feature={feature} />
      </span>
    </SeoLink>
  );
};

export const FilterFeaturesItem = injectStoresV2('routingStore', 'biLoggerStore')(FilterFeaturesItemCmp);
