import React from 'react';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { ConfigStore } from '../../stores/ConfigStore';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { NumericPagination } from '../NumericPagination/NumericPagination';
import { Filters } from '../Filters/Filters';
import { Templates } from '../Templates/Templates';
import { SubHeader } from '../SubHeader/SubHeader';
import { CbdBanner } from '../CbdBanner/CbdBanner';
import { FiltersNoResults } from '../FiltersNoResults/FiltersNoResults';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { NetworkErrorModal } from '../NetworkErrorModal/NetworkErrorModal';
import { templatesPageDataHooks } from './TemplatesPage.dataHooks';
import '../../styles/global.scss';
import styles from './TemplatesPage.scss';

interface TemplatesPageProps {
  configStore: ConfigStore;
  experimentsStore: ExperimentsStore;
  templatesStore: TemplatesStore;
  dataHook?: string;
}

const TemplatesPageCmp = (props: TemplatesPageProps) => {
  const { experimentsStore, templatesStore, dataHook, configStore } = props;
  const isCBDCategory = templatesStore.loadedFetchParams?.subCategorySlug === 'cbd';
  const isFiltersShown = experimentsStore.is('specs.funnel.TemplatesGalleryEnableFilters', 'true');

  return (
    <div data-hook={dataHook}>
      <SubHeader dataHook={templatesPageDataHooks.subHeader()} />
      <div className={styles.templatesWrapper}>
        <div className={styles.templates}>
          {isCBDCategory && (
            <div className={styles.cbdBannerWrapper}>
              <CbdBanner geoData={configStore.config.geoData} dataHook={templatesPageDataHooks.cbdBanner()} />
            </div>
          )}
          {isFiltersShown && <Filters dataHook={templatesPageDataHooks.filters()} />}

          {templatesStore.isNoFiltersResult ? (
            <FiltersNoResults dataHook={templatesPageDataHooks.filtersNoResults()} />
          ) : (
            <Templates dataHook={templatesPageDataHooks.templates()} />
          )}
        </div>
      </div>

      <NumericPagination dataHook={templatesPageDataHooks.numericPagination()} />
      <NetworkErrorModal dataHook={templatesPageDataHooks.networkErrorModal()} />
    </div>
  );
};

export const TemplatesPage = injectStoresV2('experimentsStore', 'templatesStore', 'configStore')(TemplatesPageCmp);
