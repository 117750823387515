import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { NetworkErrorType, TemplatesStore } from '../../stores/TemplatesStore';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import Close from '../../../Icons/Close.svg';
import { usePreventFocusOnClick } from '../../hooks/usePreventFocusOnClick';
import { useScrollToTop } from '../../contexts/ScrollToTopContext';
import styles from './NetworkErrorModal.scss';
import { networkErrorModalDataHooks } from './NetworkErrorModal.dataHooks';

interface NetworkErrorModalProps extends WithTranslations {
  templatesStore: TemplatesStore;
  dataHook: string;
}

export const modalEnterTimeout = parseInt(styles.modalEnterTimeout, 10);
export const modalExitTimeout = parseInt(styles.modalExitTimeout, 10);

const NetworkErrorModalCmp: React.FC<NetworkErrorModalProps> = ({ templatesStore, t, dataHook }) => {
  const { networkErrorType, isNetworkErrorModalVisible } = templatesStore;
  const preventFocusOnClick = usePreventFocusOnClick();
  const { scrollToTop } = useScrollToTop();
  const closeModal = () => {
    templatesStore.setIsNetworkErrorModalVisible(false);
  };

  const handleCtaButtonClick = () => {
    scrollToTop();
    location.reload();
  };

  const isServerSideError = networkErrorType === NetworkErrorType.ServerSide;

  return (
    <CSSTransition
      timeout={{ enter: modalEnterTimeout, exit: modalExitTimeout }}
      classNames={{
        enter: styles.modalEnter,
        enterActive: styles.modalEnterActive,
        enterDone: styles.modalEnterDone,
        exit: styles.modalExit,
        exitActive: styles.modalExitActive,
        exitDone: styles.modalExitDone,
      }}
      in={isNetworkErrorModalVisible}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.modal} data-hook={dataHook}>
        <div className={styles.backdrop} onClick={closeModal} data-hook={networkErrorModalDataHooks.backdrop()} />
        <div className={styles.box}>
          <div className={styles.title} data-hook={networkErrorModalDataHooks.title()}>
            {isServerSideError ? t('networkErrorModal.serverSide.title') : t('networkErrorModal.noConnection.title')}
          </div>
          <div className={styles.description} data-hook={networkErrorModalDataHooks.description()}>
            {isServerSideError
              ? t('networkErrorModal.serverSide.description')
              : t('networkErrorModal.noConnection.description')}
          </div>
          <button
            onClick={handleCtaButtonClick}
            onFocus={preventFocusOnClick}
            className={styles.ctaButton}
            data-hook={networkErrorModalDataHooks.ctaButton()}
          >
            {t('networkErrorModal.cta')}
          </button>
          <button
            onClick={closeModal}
            onFocus={preventFocusOnClick}
            className={styles.closeButton}
            data-hook={networkErrorModalDataHooks.closeButton()}
          >
            <Close fill="currentColor" className={styles.closeIcon} />
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};

export const NetworkErrorModal = withTranslations(injectStoresV2('templatesStore')(NetworkErrorModalCmp));
