import * as React from 'react';
import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
import { TFunction } from '../i18n/i18n';

export type WithTranslations = Omit<WithTranslation, 't'> & { t: TFunction };

const translator = withTranslation();
export const withTranslations = <P extends WithTranslations>(
  Cmp: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof WithTranslations>> => translator(Cmp);

export { Trans } from '@wix/wix-i18n-config';
