import { create, FedopsLogger as Logger } from '@wix/fedops-logger';

export type Interaction = 'search' | 'pagination' | 'category-switch' | 'embed-chat' | '';

export type FedopsLogger = Logger<Interaction>;

export class FedopsLoggerStore {
  private activeInteraction: Interaction = '';
  public logger: FedopsLogger = create('marketing-templates', { reportBlackbox: true });

  reportAppLoaded() {
    this.logger.appLoaded();
  }

  startSearchInteraction() {
    this.activeInteraction = 'search';
    this.logger.interactionStarted(this.activeInteraction);
  }

  startPaginationInteraction() {
    this.activeInteraction = 'pagination';
    this.logger.interactionStarted(this.activeInteraction);
  }

  startCategoryInteraction() {
    this.activeInteraction = 'category-switch';
    this.logger.interactionStarted(this.activeInteraction);
  }

  stopActiveInteraction() {
    if (this.activeInteraction) {
      this.logger.interactionEnded(this.activeInteraction);
      this.activeInteraction = '';
    }
  }

  getActiveInteraction() {
    return this.activeInteraction;
  }
}
