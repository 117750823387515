import { generatePath, matchPath } from 'react-router';
import { buildSortFilterSearchQuery, parseSortFiltersSearchQuery, SortFiltersParams } from './sortFiltersParams';
import { createRoute } from './createRoute';

export interface CategoryPageParams extends SortFiltersParams {
  page: number;
  categorySlug: string;
}

export interface CategoryPage {
  routeName: 'category';
  params: CategoryPageParams;
}

export const categoryRoute = createRoute<CategoryPageParams, 'categorySlug'>({
  pattern: '/html/:categorySlug/:page(\\d+)?',
  build: (pattern, params) => {
    const { page, categorySlug } = params;

    const pathParams = {
      page: page === 1 ? undefined : page,
      categorySlug: (categorySlug || '').toLowerCase(),
    };

    const searchQuery = buildSortFilterSearchQuery(params);
    return {
      pathname: generatePath(pattern, pathParams),
      search: searchQuery,
    };
  },
  match: (pattern, location) => {
    const { pathname, search } = location;
    const match = matchPath<{ categorySlug: string; page: string }>(pathname, {
      path: pattern,
      exact: true,
      sensitive: false,
    });

    if (!match) {
      return null;
    }

    const requestedPage = Number(match.params.page);
    return {
      ...parseSortFiltersSearchQuery(search),
      categorySlug: decodeURIComponent(match.params.categorySlug ?? ''),
      page: requestedPage || 1,
    };
  },
});
