import React from 'react';
import cs from 'classnames';
import styles from './LayoutIcon.scss';

interface TextLayoutProps {
  active: boolean;
}

export const TextLayout = ({ active = false }: TextLayoutProps) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="92px"
      height="58px"
      viewBox="0 0 92 58"
      enableBackground="new 0 0 92 58"
      className={cs(styles.layoutIcon, { [styles.active]: active })}
    >
      <switch>
        <g>
          <path
            fill="none"
            stroke="#0C6EFC"
            className={styles.strokeBlue}
            strokeMiterlimit="10"
            d="M3.5,57.5h85c1.657,0,3-1.343,3-3v-51c0-1.657-1.343-3-3-3h-85
			c-1.657,0-3,1.343-3,3v51C0.5,56.157,1.843,57.5,3.5,57.5z"
          />
          <g>
            <path
              fill="#0C6EFC"
              className={styles.fillBlue}
              d="M39.106,21.429h2.19l5.819,14.893h-2.19l-1.627-4.484H37l-1.668,4.484h-2.044L39.106,21.429z
				 M37.626,30.168h5.048l-2.482-6.945h-0.042L37.626,30.168z"
            />
            <path
              opacity="0.5"
              fill="#0C6EFC"
              className={styles.fillBlue}
              d="M58.713,36.28c-0.321,0.195-0.745,0.292-1.273,0.292c-0.459,0-0.824-0.132-1.095-0.396
				c-0.271-0.264-0.407-0.688-0.407-1.272c-0.486,0.584-1.053,1.008-1.699,1.272c-0.647,0.264-1.346,0.396-2.096,0.396
				c-0.487,0-0.949-0.056-1.387-0.167c-0.438-0.111-0.817-0.288-1.136-0.531c-0.32-0.243-0.574-0.556-0.761-0.939
				c-0.188-0.382-0.281-0.845-0.281-1.387c0-0.612,0.104-1.112,0.313-1.502c0.209-0.389,0.483-0.702,0.824-0.939
				c0.341-0.236,0.73-0.42,1.168-0.553c0.438-0.132,0.886-0.24,1.345-0.323c0.487-0.097,0.949-0.17,1.387-0.219
				c0.438-0.048,0.824-0.118,1.157-0.209c0.334-0.09,0.598-0.222,0.793-0.396c0.195-0.173,0.292-0.427,0.292-0.761
				c0-0.389-0.073-0.702-0.219-0.939c-0.146-0.237-0.334-0.417-0.563-0.543c-0.229-0.125-0.487-0.209-0.772-0.25
				c-0.285-0.042-0.567-0.062-0.845-0.062c-0.751,0-1.377,0.143-1.877,0.427c-0.501,0.285-0.772,0.824-0.814,1.617h-1.773
				c0.028-0.668,0.167-1.231,0.417-1.69c0.25-0.459,0.584-0.831,1.001-1.116c0.417-0.285,0.897-0.49,1.44-0.615
				c0.542-0.125,1.112-0.188,1.71-0.188c0.486,0,0.97,0.035,1.45,0.105s0.914,0.212,1.304,0.427
				c0.389,0.216,0.702,0.518,0.939,0.907c0.236,0.39,0.355,0.897,0.355,1.523v5.548c0,0.417,0.024,0.723,0.073,0.918
				c0.048,0.195,0.211,0.292,0.49,0.292c0.167,0,0.348-0.034,0.543-0.104V36.28z M55.834,30.752
				c-0.167,0.125-0.376,0.223-0.626,0.292c-0.25,0.07-0.515,0.129-0.792,0.177c-0.278,0.049-0.574,0.091-0.886,0.125
				c-0.313,0.035-0.616,0.08-0.908,0.136c-0.292,0.056-0.57,0.125-0.835,0.209s-0.494,0.198-0.688,0.344
				c-0.195,0.146-0.351,0.331-0.469,0.553c-0.118,0.223-0.177,0.5-0.177,0.834c0,0.292,0.059,0.539,0.177,0.741
				c0.118,0.202,0.271,0.365,0.459,0.49c0.188,0.125,0.407,0.215,0.657,0.271c0.25,0.056,0.515,0.083,0.793,0.083
				c0.584,0,1.084-0.08,1.501-0.24c0.417-0.16,0.758-0.362,1.022-0.605c0.264-0.243,0.459-0.507,0.584-0.792
				c0.125-0.285,0.188-0.553,0.188-0.803V30.752z"
            />
          </g>
        </g>
      </switch>
    </svg>
  );
};
