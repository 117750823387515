const searchTermsToRoutes: { [key: string]: string } = {
  blank: 'blank',
  'blank template': 'blank',
  'blank templates': 'blank',
  blog: 'blog',
  education: 'community-education/education',
  travel: 'travel-tourism',
  'landing page': 'landing-pages',
  art: 'creative-arts',
  video: 'video',
  portfolio: 'portfolio-cv/portfolios',
  music: 'music',
  fashion: 'fashion-and-style/fashion',
  business: 'business',
  beauty: 'beauty-and-hair',
  photography: 'photography',
  health: 'health-wellness/health',
  landing: 'landing-pages',
  event: 'events',
  'online store': 'online-store',
  events: 'events',
  design: 'design',
  shop: 'online-store',
  community: 'communities',
  resume: 'portfolio-cv/resumes-cvs',
  cv: 'portfolio-cv/resumes-cvs',
  restaurant: 'restaurants-food/restaurant',
  designer: 'design/designer',
  'landing pages': 'landing-pages',
  wedding: 'events/weddings',
  'real estate': 'business/real-estate',
  'car business': 'business/automotive-cars',
  'coming soon': 'landing-pages/coming-soon',
};

function getRouteBySearchTerm(searchTerm: string, exactMatch: boolean): string | null {
  const trimmedSearchTerm = searchTerm.trim().toLowerCase();
  if (searchTermsToRoutes[trimmedSearchTerm]) {
    return searchTermsToRoutes[trimmedSearchTerm];
  }

  if (!exactMatch && trimmedSearchTerm.length >= 4) {
    const routeStartedWith = Object.entries(searchTermsToRoutes)
      .map(([term, route]) => ({ term, route }))
      .find(({ term }) => term.startsWith(trimmedSearchTerm));

    if (routeStartedWith) {
      return routeStartedWith.route;
    }
  }

  if (trimmedSearchTerm.split(' ').includes('cbd')) {
    return 'online-store/cbd';
  }

  return null;
}

export function getSuggestedCategory({
  searchTerm,
  exactMatch,
}: {
  searchTerm: string;
  exactMatch: boolean;
}): { category: string; subcategory?: string } | null {
  const suggestedRoute = getRouteBySearchTerm(searchTerm, exactMatch);
  if (suggestedRoute) {
    const [category, subcategory] = suggestedRoute.split('/');
    return {
      category,
      subcategory,
    };
  }
  return null;
}
