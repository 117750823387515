import { matchPath } from 'react-router';
import { joinSearchQuery } from '../../../libs/queryString';
import { buildSortFilterSearchQuery, parseSortFiltersSearchQuery, SortFiltersParams } from './sortFiltersParams';
import { createRoute } from './createRoute';
import { parseQuery, stringifyQuery } from './queryParams';

export interface SearchPageParams extends SortFiltersParams {
  page: number;
  criteria: string;
  ref?: string;
}

export interface SearchPage {
  routeName: 'search';
  params: SearchPageParams;
}

export const searchRoute = createRoute<SearchPageParams, 'criteria'>({
  pattern: '/',
  match: (pattern, location): SearchPageParams => {
    const { pathname, search } = location;
    const queryParams = parseQuery(search);
    const match = matchPath(pathname, { path: pattern, exact: true });

    if (!match || !queryParams.criteria) {
      return null;
    }

    const page = Number(queryParams.page) || 1;

    return {
      ...parseSortFiltersSearchQuery(search),
      page: page > 1 ? page : 1,
      criteria: String(queryParams.criteria || ''),
    };
  },
  build: (pattern, params) => {
    const { page = 1, criteria, ref } = params;
    const query = {
      criteria: criteria || '',
      page: page === 1 ? undefined : String(page),
    } as any;
    if (ref) {
      query.ref = ref;
    }
    const search = joinSearchQuery(stringifyQuery(query), buildSortFilterSearchQuery(params));

    return {
      pathname: '/',
      search,
    };
  },
});
