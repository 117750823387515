import React from 'react';
import cs from 'classnames';
import { useHistory } from 'react-router';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { RoutingStore } from '../../stores/RoutingStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { usePreventFocusOnClick } from '../../hooks/usePreventFocusOnClick';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import DropdownArrow from '../../../Icons/DropdownArrow.svg';
import { LogSortParams, BILoggerStore } from '../../stores/BILoggerStore';
import styles from './Sort.scss';
import { sortDataHooks } from './Sort.dataHooks';

interface SortProps extends WithTranslations {
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
  biLoggerStore: BILoggerStore;
  dataHook?: string;
}

export const SortCmp = ({ t, routingStore, categoriesStore, biLoggerStore, dataHook }: SortProps) => {
  const history = useHistory();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

  const { activeCategorySlug, activeSubCategorySlug, activeSortCategorySlug, activeSearchCriteria } = routingStore;

  const selectedSortCategory = categoriesStore.sortCategories.find(({ name }) => name === activeSortCategorySlug);

  const preventFocusOnClick = usePreventFocusOnClick();

  const logSort = (params: LogSortParams) => {
    const selectedSort = params.selectedSort ?? 'recommended';

    if (activeCategorySlug) {
      activeSubCategorySlug
        ? biLoggerStore.logSubCategorySort({
            actionName: params.actionName,
            selectedSort,
            categorySlug: activeCategorySlug,
            subCategorySlug: activeSubCategorySlug,
          })
        : biLoggerStore.logCategorySort({
            actionName: params.actionName,
            selectedSort,
            categorySlug: activeCategorySlug,
          });
      return;
    }

    if (activeSearchCriteria) {
      biLoggerStore.logSearchSort({
        actionName: params.actionName,
        selectedSort,
        searchCriteria: activeSearchCriteria,
      });
    }
  };

  const openDropdown = () => {
    setIsDropdownOpen(true);
    logSort({
      actionName: 'expand',
      selectedSort: selectedSortCategory?.name,
    });
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const onSortClick = (sortCategorySlug: string | null) => {
    closeDropdown();
    logSort({ actionName: 'execute', selectedSort: sortCategorySlug });
    history.push(routingStore.rebuildRoute({ sortCategorySlug, page: 1 }));
  };

  const closeTimeoutId = React.useRef<number | null>(null);
  const closeDropdownWithDelay = () => {
    closeTimeoutId.current = window.setTimeout(closeDropdown, 200);
  };
  const resetCloseTimeout = () => {
    clearTimeout(closeTimeoutId.current);
  };
  React.useEffect(() => resetCloseTimeout, []);

  const sortItems = [
    { slug: null, text: t('sort.recommended.title') },
    ...categoriesStore.sortCategories.map(({ name, displayName }) => ({
      slug: name,
      text: displayName,
    })),
  ];

  return (
    <div
      className={styles.root}
      data-hook={dataHook}
      onMouseEnter={resetCloseTimeout}
      onMouseLeave={closeDropdownWithDelay}
    >
      <span className={styles.label}>{t('filters.panel.sort.label')}</span>
      <div className={cs(styles.dropdownWrapper)} onFocus={preventFocusOnClick} tabIndex={0}>
        <span onClick={openDropdown} className={styles.dropdownToggle} data-hook={sortDataHooks.dropdownToggle()}>
          <span data-hook={sortDataHooks.dropdownToggleLabel()}>
            {selectedSortCategory?.displayName ?? t('sort.recommended.title')}
          </span>
          <DropdownArrow
            fill="currentColor"
            className={cs(styles.dropdownArrow, { [styles.flipped]: isDropdownOpen })}
          />
        </span>
        <div
          className={cs(styles.dropdown, { [styles.dropdownExpanded]: isDropdownOpen })}
          data-hook={sortDataHooks.dropdown({ isOpen: isDropdownOpen })}
        >
          <div className={styles.dropdownContent}>
            {sortItems.map(({ text, slug }) => (
              <li key={slug} className={styles.sortLinkWrapper}>
                <span
                  className={styles.sortLink}
                  onClick={() => onSortClick(slug)}
                  data-hook={sortDataHooks.dropdownOption({ slug: slug ?? 'recommended' })}
                >
                  {text}
                </span>
              </li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Sort = withTranslations(injectStoresV2('routingStore', 'categoriesStore', 'biLoggerStore')(SortCmp));
