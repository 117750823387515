import React from 'react';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { TranslationKey } from '../../../i18n/i18n';
import { Feature } from '../../stores/routes/sortFiltersParams';

const featureDescriptionTranslationMapping: Partial<Record<Feature, TranslationKey>> = {
  store: 'feature.store.description',
  booking: 'feature.booking.description',
  blog: 'feature.blog.description',
  forum: 'feature.forum.description',
  gallery: 'feature.gallery.description',
  music: 'feature.music.description',
  video: 'feature.video.description',
  instagram: 'feature.instagram.description',
  restaurant: 'feature.restaurant.description',
  events: 'feature.events.description',
  pricing: 'feature.pricing.description',
};

interface FilterFeatureDescriptionProps extends WithTranslations {
  feature: Feature;
}

export const FilterFeatureDescriptionCmp = ({ feature, t }: FilterFeatureDescriptionProps) => {
  const translationKey = featureDescriptionTranslationMapping[feature];
  return <>{translationKey ? t(translationKey) : feature}</>;
};

export const FilterFeatureDescription = withTranslations(FilterFeatureDescriptionCmp);
