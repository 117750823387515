import { matchPath } from 'react-router';
import { createRoute } from './createRoute';
import { parseQuery } from './queryParams';

export interface HomePageParams {}

export interface HomePage {
  routeName: 'home';
  params: HomePageParams;
}

export const homeRoute = createRoute<HomePageParams>({
  pattern: '/',
  build: () => ({ pathname: '/', search: '' }),
  match: (pattern, location) => {
    const { pathname, search } = location;
    const match = matchPath(pathname, { path: pattern, exact: true });
    const queryParams = parseQuery(search);

    if (!match || queryParams.criteria) {
      return null;
    }

    return {};
  },
});
