import React from 'react';
import cs from 'classnames';
import { injectStoresV2 } from '../../../../../stores/injectStoresV2';
import { HeaderStore } from '../../../../../stores/HeaderStore';
import styles from './HighlightSearchCriteria.scss';
import { highlightSearchCriteriaDataHooks } from './HighlightSearchCriteria.dataHooks';

interface HighlightSearchCriteriaProps {
  headerStore: HeaderStore;
  text: string;
  className: string;
  isBlue: boolean;
  dataHook: string;
}

const HighlightSearchCriteriaCmp: React.FC<HighlightSearchCriteriaProps> = ({
  text,
  headerStore,
  className,
  dataHook,
  isBlue,
}) => {
  const { searchCriteriaRegExp } = headerStore;

  const textChunks = React.useMemo(() => {
    return text.split(searchCriteriaRegExp);
  }, [searchCriteriaRegExp, text]);

  return (
    <span data-hook={dataHook}>
      {textChunks.map((value, index) =>
        index === 1 ? (
          <span
            className={cs(styles.highlight, className, { [styles.blue]: isBlue })}
            data-hook={highlightSearchCriteriaDataHooks.highlighted()}
            key={`${index}-${value}`}
          >
            {value}
          </span>
        ) : (
          <span key={`${index}-${value}`}>{value}</span>
        ),
      )}
    </span>
  );
};

export const HighlightSearchCriteria = injectStoresV2('headerStore')(HighlightSearchCriteriaCmp);
