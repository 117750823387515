import React from 'react';
import { ColorStyles, Color } from '../../stores/routes/sortFiltersParams';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { TranslationKey } from '../../../i18n/i18n';

const colorTranslationMapping: Record<Color | ColorStyles, TranslationKey> = {
  bright: 'color.bright',
  dark: 'color.dark',
  colorful: 'color.colorful',
  white: 'color.white',
  black: 'color.black',
  blue: 'color.blue',
  red: 'color.red',
  yellow: 'color.yellow',
  green: 'color.green',
  pink: 'color.pink',
  orange: 'color.orange',
  brown: 'color.brown',
  purple: 'color.purple',
  grey: 'color.grey',
};

interface FilterColorNameProps extends WithTranslations {
  color: Color | ColorStyles;
}

export const FilterColorNameCmp = ({ color, t }: FilterColorNameProps) => {
  const translationKey = colorTranslationMapping[color];
  return <>{translationKey ? t(translationKey) : color}</>;
};

export const FilterColorName = withTranslations(FilterColorNameCmp);
