import { action, computed, makeObservable, observable } from 'mobx';
import { ApiClient } from '../../apiClient/ApiClient';
import { ErrorReporter } from '../../errorHandling/ErrorReporter';
import { SearchSuggestion } from '../../web-api/domain/searchSuggestion';
import { ExperimentsStore } from '../../stores/ExperimentsStore';
import { getSuggestedCategoryOrSubCategorySlug } from './searchTermsToRoutes/searchTermsToRoutesForSuggestions';
import { RouteLocation } from './routes/createRoute';
import { RoutingStore } from './RoutingStore';
import { CategoriesStore } from './CategoriesStore';
import { BILoggerStore } from './BILoggerStore';
import { FedopsLoggerStore } from './FedopsLoggerStore';
import { getSuggestedCategory } from './searchTermsToRoutes/searchTermsToRoutes';
import { ConfigStore } from './ConfigStore';

interface SearchWithSuggestionsStoreParams {
  apiClient: ApiClient;
  errorReporter: ErrorReporter;
  routingStore: RoutingStore;
  biLoggerStore: BILoggerStore;
  categoriesStore: CategoriesStore;
  fedopsLoggerStore: FedopsLoggerStore;
  configStore: ConfigStore;
  experimentsStore: ExperimentsStore;
}

export interface GallerySearchSuggestionItem extends SearchSuggestion {
  index: number;
  categoryOrSubCategorySlug: string | null;
  resultType: 'result' | 'related_result' | 'pushed_category';
}

export type SearchType = 'free_text' | 'category' | 'result' | 'related_result';

export interface SelectedSuggestion {
  searchSuggestion: GallerySearchSuggestionItem | null;
  searchType: SearchType;
}

export class SearchWithSuggestionsStore {
  private apiClient: ApiClient;
  private errorReporter: ErrorReporter;
  private routingStore: RoutingStore;
  private biLoggerStore: BILoggerStore;
  private categoriesStore: CategoriesStore;
  private fedopsLoggerStore: FedopsLoggerStore;
  private configStore: ConfigStore;
  private experimentsStore: ExperimentsStore;

  public searchSuggestions: GallerySearchSuggestionItem[] = [];
  public isDropdownOpened: boolean = false;
  public preSelectedSuggestionIndex: number | null = null;

  public selectedSuggestion: SelectedSuggestion | null = null;

  constructor({
    apiClient,
    errorReporter,
    routingStore,
    biLoggerStore,
    categoriesStore,
    fedopsLoggerStore,
    configStore,
    experimentsStore,
  }: SearchWithSuggestionsStoreParams) {
    makeObservable<SearchWithSuggestionsStore, 'setSuggestions'>(this, {
      searchSuggestions: observable,
      isDropdownOpened: observable,
      preSelectedSuggestionIndex: observable,
      selectedSuggestion: observable,
      setSuggestions: action,
      setIsSearchSuggestionDropdownOpened: action,
      setHighlightedSuggestionIndex: action,
      preSelectNextSuggestion: action,
      preSelectPreviousSuggestion: action,
      setSelectedSuggestion: action,
      clearSelectedSuggestion: action,
      preSelectedSuggestion: computed,
    });

    this.apiClient = apiClient;
    this.errorReporter = errorReporter;
    this.routingStore = routingStore;
    this.biLoggerStore = biLoggerStore;
    this.categoriesStore = categoriesStore;
    this.fedopsLoggerStore = fedopsLoggerStore;
    this.configStore = configStore;
    this.experimentsStore = experimentsStore;
  }

  public async fetchSearchSuggestions(criteria: string) {
    try {
      const { results, relatedResults, totalResults } = await this.apiClient.fetchSearchSuggestions({
        criteria,
        biData: {
          category: this.routingStore.activeCategorySlug ?? 'search',
          subCategory: this.routingStore.activeSubCategorySlug,
          templatePageSessionId: this.biLoggerStore.templatePageSessionId,
        },
      });
      this.setSuggestions(results, relatedResults, criteria);
      this.setIsSearchSuggestionDropdownOpened(true);

      this.biLoggerStore.logAdiSearchTermChangeAdiBiEvent({
        criteria,
        suggestionsTotalCount: totalResults,
      });
    } catch (error) {
      this.errorReporter.reportError(error);
      this.setSuggestions([], [], null);
    }
  }

  private setSuggestions = (
    results: SearchSuggestion[],
    relatedResults: SearchSuggestion[],
    criteria: string | null,
  ) => {
    const foundCategories = new Set<string>();
    const suggestionsWithCategory: Omit<GallerySearchSuggestionItem, 'index'>[] = [
      ...results.map((item) => ({ ...item, resultType: 'result' as const })),
      ...relatedResults.map((item) => ({ ...item, resultType: 'related_result' as const })),
    ].map((item) => {
      const isSearchInLanguages = this.configStore.config.currentLanguage !== 'en';
      const isUseBusinessTermToBookCategoryMappingEnabled = this.experimentsStore.is(
        'specs.funnel.UseBusinessTermToBookCategoryMapping',
        'true',
      );
      const category = this.categoriesStore.getCategoryById(item.categoryId);
      let categoryOrSubCategorySlug: string | null = !isSearchInLanguages
        ? isUseBusinessTermToBookCategoryMappingEnabled
          ? category?.name || null
          : getSuggestedCategoryOrSubCategorySlug({
              criteria: item.text,
              exactMatch: true,
              isBucketsABTest: this.categoriesStore.bookName === 'Buckets Test 2',
              temporaryRenamedCategories: this.configStore.config.temporaryRenamedCategories,
            })
        : null;
      if (categoryOrSubCategorySlug && foundCategories.has(categoryOrSubCategorySlug)) {
        categoryOrSubCategorySlug = null;
      } else if (categoryOrSubCategorySlug) {
        foundCategories.add(categoryOrSubCategorySlug);
      }
      return { ...item, categoryOrSubCategorySlug };
    });

    if (criteria) {
      const categoryAndSubCategory = getSuggestedCategory({
        searchTerm: criteria,
        exactMatch: false,
      });

      const categoryOrSubCategorySlug = categoryAndSubCategory?.subcategory ?? categoryAndSubCategory?.category;
      const isCategoryInSuggestions = foundCategories.has(categoryOrSubCategorySlug);
      const suggestedCategory =
        categoryOrSubCategorySlug && this.categoriesStore.getCategoryBySlug(categoryOrSubCategorySlug);

      if (suggestedCategory && !isCategoryInSuggestions) {
        suggestionsWithCategory.unshift({
          text: suggestedCategory.displayName,
          score: 0,
          industryId: '',
          postRegVertical: '',
          structureId: '',
          categoryId: '',
          categoryOrSubCategorySlug,
          resultType: 'pushed_category' as const,
        });
      }
    }
    this.searchSuggestions = suggestionsWithCategory.map((item, index) => ({ ...item, index }));
  };

  public setIsSearchSuggestionDropdownOpened = (isOpened: boolean) => {
    this.isDropdownOpened = isOpened;
  };

  public setHighlightedSuggestionIndex = (index: number | null) => {
    const maxIndex = this.searchSuggestions.length - 1;

    if (index !== null) {
      index = index > maxIndex ? null : index;
      index = index < 0 ? null : index;
    }

    this.preSelectedSuggestionIndex = index;
  };

  public preSelectNextSuggestion = () => {
    this.setHighlightedSuggestionIndex((this.preSelectedSuggestionIndex ?? -1) + 1);
  };

  public preSelectPreviousSuggestion = () => {
    this.setHighlightedSuggestionIndex((this.preSelectedSuggestionIndex ?? 0) - 1);
  };

  public setSelectedSuggestion(selectedSuggestion: GallerySearchSuggestionItem | null) {
    this.selectedSuggestion = {
      searchType: this.getSearchType(selectedSuggestion),
      searchSuggestion: selectedSuggestion,
    };
  }

  public clearSelectedSuggestion() {
    this.selectedSuggestion = null;
  }

  public get preSelectedSuggestion(): GallerySearchSuggestionItem | null {
    return this.searchSuggestions.find(({ index }) => index === this.preSelectedSuggestionIndex) ?? null;
  }

  public getSuggestedCategoryLocation = (criteria: string, categoryId?: string | null): RouteLocation | null => {
    const isUseBusinessTermToBookCategoryMappingEnabled = this.experimentsStore.is(
      'specs.funnel.UseBusinessTermToBookCategoryMapping',
      'true',
    );
    const suggestedCategorySlug = getSuggestedCategoryOrSubCategorySlug({
      criteria,
      exactMatch: false,
      isBucketsABTest: this.categoriesStore.bookName === 'Buckets Test 2',
      temporaryRenamedCategories: this.configStore.config.temporaryRenamedCategories,
    });
    const suggestedCategory = isUseBusinessTermToBookCategoryMappingEnabled
      ? this.categoriesStore.getCategoryById(categoryId)
      : suggestedCategorySlug
      ? this.categoriesStore.getCategoryBySlug(suggestedCategorySlug)
      : null;

    if (!suggestedCategory) {
      return null;
    }

    const parentCategory = this.categoriesStore.getParentCategoryBySlug(suggestedCategory.name);

    if (parentCategory) {
      return this.routingStore.locationBuilders.subCategory({
        categorySlug: parentCategory.name,
        subCategorySlug: suggestedCategory.name,
      });
    }

    return this.routingStore.locationBuilders.category({ categorySlug: suggestedCategory.name });
  };

  private getSearchType(selectedSuggestion: GallerySearchSuggestionItem | null): SearchType {
    if (!this.preSelectedSuggestion || !selectedSuggestion) {
      return 'free_text';
    }

    if (selectedSuggestion.categoryOrSubCategorySlug) {
      return 'category';
    }

    return selectedSuggestion.resultType === 'pushed_category' ? 'result' : selectedSuggestion.resultType;
  }

  public getSuggestionByText = (criteria: string): GallerySearchSuggestionItem | null =>
    this.searchSuggestions.find(({ text }) => text.toLowerCase() === criteria.toLowerCase()) ?? null;
}
