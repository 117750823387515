import React from 'react';
import cs from 'classnames';
import styles from './LayoutIcon.scss';

interface SplitScreenLayoutProps {
  active: boolean;
}

export const SplitScreenLayout = ({ active = false }: SplitScreenLayoutProps) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="92px"
      height="58px"
      viewBox="0 0 92 58"
      enableBackground="new 0 0 92 58"
      className={cs(styles.layoutIcon, { [styles.active]: active })}
    >
      <switch>
        <g>
          <path
            fill="#D6E6FF"
            className={styles.fillBlue}
            d="M46,57.5h42.5c1.657,0,3-1.343,3-3v-51c0-1.657-1.343-3-3-3H46V57.5z"
          />
          <path
            fill="none"
            stroke="#0C6EFC"
            className={styles.strokeBlue}
            strokeMiterlimit="10"
            d="M3.5,57.5h85c1.657,0,3-1.343,3-3v-51c0-1.657-1.343-3-3-3h-85
			c-1.657,0-3,1.343-3,3v51C0.5,56.157,1.843,57.5,3.5,57.5z"
          />
          <g>
            <path
              fill="#0C6EFC"
              className={styles.fillBlue}
              d="M26.06,35.863H19.44c-1.045,0-1.892-0.847-1.892-1.892v0c0-1.045,0.847-1.892,1.892-1.892h6.619
				c1.045,0,1.892,0.847,1.892,1.892v0C27.952,35.015,27.105,35.863,26.06,35.863z"
            />
            <g>
              <line
                fill="none"
                stroke="#0C6EFC"
                className={styles.strokeBlue}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="34.527"
                y1="22.137"
                x2="10.973"
                y2="22.137"
              />

              <line
                fill="none"
                stroke="#0C6EFC"
                className={styles.strokeBlue}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="30.947"
                y1="25.638"
                x2="14.553"
                y2="25.638"
              />
            </g>
          </g>
        </g>
      </switch>
    </svg>
  );
};
