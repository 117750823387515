import React from 'react';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { Feature } from '../../stores/routes/sortFiltersParams';
import { TranslationKey } from '../../../i18n/i18n';

const translationKeysByFeature: Record<Feature, TranslationKey> = {
  store: 'feature.store',
  booking: 'feature.booking',
  blog: 'feature.blog',
  forum: 'feature.forum',
  gallery: 'feature.gallery',
  music: 'feature.music',
  video: 'feature.video',
  instagram: 'feature.instagram',
  restaurant: 'feature.restaurant',
  events: 'feature.events',
  pricing: 'feature.pricing',
};

interface FilterFeatureNameProps extends WithTranslations {
  feature: Feature;
}

export const FilterFeatureNameCmp = ({ feature, t }: FilterFeatureNameProps) => {
  const translationKey = translationKeysByFeature[feature];
  return <>{translationKey ? t(translationKey) : feature}</>;
};

export const FilterFeatureName = withTranslations(FilterFeatureNameCmp);
