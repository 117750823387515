export function isIncludesA11yEnglishWords(criteria: string, language: string): boolean {
  const A11Y_KEYWORDS = ['a11y', 'accessibility', 'wcag', 'high contrast', 'accessible', '508'];

  const isEnglish = language === 'en';
  if (!isEnglish) {
    return false;
  }

  const criteriaInLowercase = criteria.toLowerCase();
  return A11Y_KEYWORDS.some((keyword) => criteriaInLowercase.includes(keyword));
}
