import { BreadcrumbList, ListItem } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { RoutingStore } from '../../stores/RoutingStore';
import { BaseURLStore } from '../../stores/BaseURLStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { TFunction } from '../../../i18n/i18n';
import { ConfigStore } from '../../stores/ConfigStore';

interface GenerateActiveCategoryUrlParams {
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
  baseURLStore: BaseURLStore;
  t: TFunction;
}

function generateActiveCategoryListItemProps({
  routingStore,
  categoriesStore,
  baseURLStore,
}: GenerateActiveCategoryUrlParams): Pick<ListItem, 'item' | 'name'> | null {
  const { matchedRoute } = routingStore;
  const activeCategory =
    matchedRoute.routeName === 'category' || matchedRoute.routeName === 'subCategory'
      ? categoriesStore.getCategoryBySlug(matchedRoute.params.categorySlug)
      : null;

  if (!activeCategory) {
    return null;
  }

  return {
    item: baseURLStore.buildURL(routingStore.locationBuilders.category({ categorySlug: activeCategory.name })),
    name: activeCategory.displayName,
  };
}

function generateActiveSubCategoryListItemProps({
  routingStore,
  categoriesStore,
  baseURLStore,
}: GenerateActiveCategoryUrlParams): Pick<ListItem, 'item' | 'name'> | null {
  const { matchedRoute } = routingStore;
  const activeCategory =
    matchedRoute.routeName === 'category' || matchedRoute.routeName === 'subCategory'
      ? categoriesStore.getCategoryBySlug(matchedRoute.params.categorySlug)
      : null;

  const activeSubCategory =
    matchedRoute.routeName === 'subCategory'
      ? categoriesStore.getCategoryBySlug(matchedRoute.params.subCategorySlug)
      : undefined;

  if (!activeCategory || !activeSubCategory) {
    return null;
  }

  return {
    item: baseURLStore.buildURL(
      routingStore.locationBuilders.subCategory({
        categorySlug: activeCategory.name,
        subCategorySlug: activeSubCategory.name,
      }),
    ),
    name: activeSubCategory.displayName,
  };
}

function buildHomePageURL(configStore: ConfigStore) {
  const { domain, currentLanguage } = configStore.config;
  const langDomain = `${currentLanguage === 'en' ? 'www' : currentLanguage}.${domain}`;
  return `https://${langDomain}`;
}

interface GenerateBreadcrumbListScriptPropsParams {
  routingStore: RoutingStore;
  baseURLStore: BaseURLStore;
  categoriesStore: CategoriesStore;
  configStore: ConfigStore;
  t: TFunction;
}

type BreadcrumbListScriptProps = ReturnType<typeof helmetJsonLdProp>;

export function generateBreadcrumbListScriptProps({
  t,
  baseURLStore,
  routingStore,
  categoriesStore,
  configStore,
}: GenerateBreadcrumbListScriptPropsParams): BreadcrumbListScriptProps {
  return helmetJsonLdProp<BreadcrumbList>({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        name: t('breadcrumbs.wixcom'),
        item: buildHomePageURL(configStore),
      },
      {
        name: t('breadcrumbs.templates'),
        item: baseURLStore.buildURL(routingStore.locationBuilders.home({})),
      },
      generateActiveCategoryListItemProps({ routingStore, categoriesStore, baseURLStore, t }),
      generateActiveSubCategoryListItemProps({ routingStore, categoriesStore, baseURLStore, t }),
    ]
      .filter((item) => item !== null)
      .map((params, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        ...params,
      })),
  });
}
