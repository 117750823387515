import React from 'react';

export const useIsMousePressed = (): (() => boolean) => {
  const isMousePressed = React.useRef(false);

  React.useEffect(() => {
    const onMouseDown = () => (isMousePressed.current = true);
    window.document.addEventListener('mousedown', onMouseDown);
    return () => window.document.removeEventListener('mousedown', onMouseDown);
  });

  React.useEffect(() => {
    const onMouseUp = () => (isMousePressed.current = false);
    window.document.addEventListener('mouseup', onMouseUp);
    return () => window.document.removeEventListener('mouseup', onMouseUp);
  });

  return () => (isMousePressed ? isMousePressed.current : false);
};
