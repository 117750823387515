import React from 'react';

export const useIsMounted = (): boolean => {
  const [isMounted, setMountedState] = React.useState<boolean>(false);

  React.useEffect(() => {
    setMountedState(true);
  }, []);

  return isMounted;
};
