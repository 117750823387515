import { ConsentPolicy } from '@wix/wix-bootstrap-api';
import { GeoData } from '@wix/wix-common-aspects';
import { AppConfig } from '../../config';
import { Identity } from '../../utils/fetchIdentity';
import { ProfileInfo } from '../../utils/fetchProfileInfo';

export interface ConfigStoreState {
  currentLanguage: string;
  nativeLanguage: string;
  supportedLanguages: string[];
  baseURL: string;
  domain: string;
  geoData: GeoData;
  renamedCategories: AppConfig['renamedCategory'];
  temporaryRenamedCategories: AppConfig['temporaryRenamedCategory'];
  templatePageSessionId: string;
  imagesMediaPlatformDomain: string;
  filesMediaPlatformDomain: string;
  dealerCmsTranslationsUrl: string;
  santaEditorBaseUrl: string;
  consentPolicy?: ConsentPolicy;
  introReferrer: string;
  introQueryParams: Record<string, string>;
  identity: Identity;
  hirePartnerBanner?: boolean;
  profileInfo?: ProfileInfo;
  dashboardFirstBusinessTerm?: string;
}

export class ConfigStore {
  constructor(public readonly config: ConfigStoreState) {}

  serialize(): ConfigStoreState {
    return this.config;
  }

  setIntroReferrer(value: string) {
    this.config.introReferrer = value;
  }
}
