import React from 'react';
import { MegaMenuDropdownSection, MegaMenuDropdownItem } from '../MegaMenuDropdownSection/MegaMenuDropdownSection';
import { DropdownWrapper } from '../DropdownWrapper/DropdownWrapper';
import { megaMenuDropdownDataHooks } from './MegaMenuDropdown.dataHooks';
import styles from './MegaMenuDropdown.scss';

export interface MegaMenuDropdownProps {
  isOpen: boolean;
  items: MegaMenuDropdownItem[];
  groupLabel: string;
  adjustWidth?: boolean;
  className?: string;
  onItemClick: (e: React.SyntheticEvent) => void;
  dataHook?: string;
}

function updateDropdownWidth(dropdownContentElement: HTMLUListElement, dropdownWrapperElement: HTMLDivElement) {
  const { children: dropdownContentChildren } = dropdownContentElement;

  if (dropdownContentChildren.length === 0) {
    return;
  }

  let minLeftPos = null;
  let maxRightPos = null;

  for (const child of Array.from(dropdownContentChildren)) {
    const { left, right } = child.getBoundingClientRect();
    if (minLeftPos === null || left < minLeftPos) {
      minLeftPos = left;
    }
    if (maxRightPos === null || right > maxRightPos) {
      maxRightPos = right;
    }
  }

  const contentElementWidth = maxRightPos - minLeftPos;
  dropdownWrapperElement.style.width = `${contentElementWidth}px`;
}

export const MegaMenuDropdown: React.FC<MegaMenuDropdownProps> = ({
  isOpen,
  items,
  groupLabel,
  onItemClick,
  className,
  adjustWidth,
  dataHook,
}) => {
  const dropdownWrapperRef = React.useRef<HTMLDivElement>(null);
  const dropdownContentRef = React.useRef<HTMLUListElement>(null);

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (adjustWidth) {
      dropdownContentRef.current && updateDropdownWidth(dropdownContentRef.current, dropdownWrapperRef.current);
    }
  }, [adjustWidth, isOpen]);

  return (
    <DropdownWrapper isOpen={isOpen} className={className} dropdownRef={dropdownWrapperRef} dataHook={dataHook}>
      <div className={styles.dropdownContent}>
        <ul className={styles.dropdownSections} ref={dropdownContentRef}>
          {items.map(({ label, id, link, items }) => (
            <MegaMenuDropdownSection
              key={id}
              id={id}
              label={label}
              link={link}
              items={items}
              groupLabel={groupLabel}
              onItemClick={onItemClick}
              dataHook={megaMenuDropdownDataHooks.dropdownSection({ id })}
            />
          ))}
        </ul>
      </div>
    </DropdownWrapper>
  );
};
