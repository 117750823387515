import React from 'react';
import { RoutingStore } from '../../../stores/RoutingStore';
import { CategoriesStore } from '../../../stores/CategoriesStore';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { createBreakpoints, Picture } from '../../Picture/Picture';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import { Template as ApiTemplate } from '../../../../web-api/domain/template';

const SOURCE_IMAGE_SIZES = {
  preview: {
    width: 3840,
    height: 2160,
  },
  '16x9': {
    width: 1920,
    height: 1080,
  },
};

const BREAKPOINTS_CONFIG = [
  {
    maxScreenWidth: 768,
    imageWidth: 322,
    densityList: [1, 2],
  },
  {
    maxScreenWidth: 1024,
    imageWidth: 450,
    densityList: [1, 2],
  },
  {
    maxScreenWidth: 1280,
    imageWidth: 538,
    densityList: [1, 2],
  },
  {
    maxScreenWidth: 1365,
    imageWidth: 393.656,
    densityList: [1, 2],
  },
  {
    maxScreenWidth: 1366,
    imageWidth: 367.33,
    densityList: [1, 2],
  },
  {
    maxScreenWidth: 1680,
    imageWidth: 472,
    densityList: [1, 2],
  },
  {
    maxScreenWidth: 1920,
    imageWidth: 536,
    densityList: [1, 2], // 1x, 2x
  },
  {
    maxScreenWidth: 2560,
    imageWidth: 749.33,
    densityList: [1, 2], // 1x
  },
];
const BREAKPOINTS_CONFIG_LARGE_IMAGE = [
  {
    maxScreenWidth: 1280,
    imageWidth: 640,
    densityList: [1, 2],
  },
  {
    maxScreenWidth: 2560,
    imageWidth: 704,
    densityList: [1, 2],
  },
];

interface TemplateImageProps extends WithTranslations {
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
  template: ApiTemplate;
  className?: string;
  dataHook?: string;
  onLoad?: () => void;
  onLoadStart?: () => void;
  isLargeImage?: boolean;
  lazyLoad?: boolean;
}

const TemplateImageCmp: React.FC<TemplateImageProps> = ({
  template,
  dataHook,
  className,
  onLoad,
  onLoadStart,
  isLargeImage,
  routingStore,
  categoriesStore,
  lazyLoad,
  t,
}) => {
  const imagePath = template.desktop16x9Img || template.previewImg;
  const sourceImageSize = template.desktop16x9Img ? SOURCE_IMAGE_SIZES['16x9'] : SOURCE_IMAGE_SIZES.preview;
  const activeCategory = categoriesStore.getCategoryBySlug(
    routingStore.activeSubCategorySlug || routingStore.activeCategorySlug,
  );
  const category = activeCategory?.displayName || '';
  const breakPointsConfig = isLargeImage ? BREAKPOINTS_CONFIG_LARGE_IMAGE : BREAKPOINTS_CONFIG;
  const breakpoints = createBreakpoints(breakPointsConfig, SOURCE_IMAGE_SIZES['16x9']);

  return (
    <Picture
      imagePath={imagePath}
      sourceImageSize={sourceImageSize}
      breakpoints={breakpoints}
      alt={t('templates.img.altText', { title: template.title, category })}
      dataHook={dataHook}
      onLoad={onLoad}
      lazyLoad={lazyLoad}
      onLoadStart={onLoadStart}
      imageClassName={className}
    />
  );
};

export const TemplateImage = withTranslations(injectStoresV2('routingStore', 'categoriesStore')(TemplateImageCmp));
