import { ConsentPolicy } from '@wix/wix-bootstrap-api';
import { ErrorReporter } from '../errorHandling/ErrorReporter';

function initConsentPolicyManager(baseUrl: string, consentPolicy?: ConsentPolicy, errorReporter?: ErrorReporter) {
  try {
    window.consentPolicyManager.init({
      baseUrl,
      consentPolicy,
    });
  } catch (error) {
    if (errorReporter) {
      errorReporter.reportError(error);
    }
  }
}

function initWixTagManager(language: string, errorReporter?: ErrorReporter) {
  try {
    window.wixTagManager.init('TEMPLATES', language);
  } catch (error) {
    if (errorReporter) {
      errorReporter.reportError(error);
    }
  }
}

export function initCookieAndTagManagers(
  {
    baseUrl,
    consentPolicy,
    language,
  }: {
    baseUrl: string;
    consentPolicy?: ConsentPolicy;
    language: string;
  },
  errorReporter?: ErrorReporter,
) {
  window.addEventListener('consentPolicyManagerReady', () => {
    initConsentPolicyManager(baseUrl, consentPolicy, errorReporter);
  });

  window.addEventListener('TagManagerLoaded', () => {
    initWixTagManager(language, errorReporter);
  });
}
