import React from 'react';
import { ConfigStore } from '../../../stores/ConfigStore';
import { stringify } from '../../../../libs/queryString';
import { BILoggerStore } from '../../../stores/BILoggerStore';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import s from './Banner.scss';

interface BannerProps {
  configStore: ConfigStore;
  dataHook?: string;
  biLoggerStore: BILoggerStore;
}

const BannerCmp = ({ dataHook, configStore, biLoggerStore }: BannerProps) => {
  const getBannerLink = () => {
    const { requestedApps, ...introQueryParams } = configStore.config.introQueryParams;

    if (requestedApps) {
      introQueryParams.appsToInstall = requestedApps;
    }

    const query = stringify({
      ...introQueryParams,
      originTemplateId: '69be06e3-3fe3-4ab3-97e4-bec926804865&',
      siteCreationWizard: 'true',
    });

    return `https://manage.wix.com/edit-template/from-intro?${query}`;
  };

  const logBannerClick = () => {
    biLoggerStore.logAdiBannerClick({ banner_name: 'adi' });
  };

  return (
    <li className={s.wrapper} data-hook={dataHook}>
      <div className={s.banner}>
        <div>
          <div className={s.title}>Not sure what to choose?</div>
          <div className={s.description}>Get a ready to go website in minutes based on your style & needs.</div>
          <a href={getBannerLink()} target="_blank" onClick={logBannerClick} className={s.button}>
            Get Started
          </a>
        </div>
      </div>
    </li>
  );
};

export const Banner = injectStoresV2('configStore', 'biLoggerStore')(BannerCmp);
