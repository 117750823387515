import React from 'react';
import cs from 'classnames';
import { EMPTY_FILTERS, FilterCollection } from '../../../stores/routes/sortFiltersParams';
import { FilterDropdownTitle } from '../FilterDropdownTitle/FilterDropdownTitle';
import { FilterDropdownContent } from '../FilterDropdownContent/FilterDropdownContent';
import { usePreventFocusOnClick } from '../../../hooks/usePreventFocusOnClick';
import { RouteLocation } from '../../../stores/routes/createRoute';
import { BILoggerStore } from '../../../stores/BILoggerStore';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { RoutingStore } from '../../../stores/RoutingStore';
import { filterDropdownDataHooks } from './FilterDropdown.dataHooks';
import styles from './FilterDropdown.scss';

interface FilterDropdownProps {
  filterType: keyof FilterCollection;
  clearLink: RouteLocation;
  appliedFiltersCount: number;
  dataHook?: string;
  isDropdownBorder?: boolean;
  title: string;
  biLoggerStore: BILoggerStore;
  routingStore: RoutingStore;
}

const FilterDropdownCmp: React.FC<FilterDropdownProps> = ({
  biLoggerStore,
  routingStore,
  dataHook,
  filterType,
  clearLink,
  title,
  appliedFiltersCount,
  isDropdownBorder,
  children,
}) => {
  const [isFilterOpened, setIsFilterOpened] = React.useState<boolean>(false);
  const appliedFilters = routingStore.appliedFilters ?? EMPTY_FILTERS;
  const preventFocusOnClick = usePreventFocusOnClick();

  const openFilter = () => {
    biLoggerStore.logOpenFiltersElement({
      type: filterType,
      previousValues: appliedFilters,
    });
    setIsFilterOpened(true);
  };

  const closeFilter = () => {
    if (isFilterOpened) {
      biLoggerStore.logCloseFiltersElement({
        type: filterType,
        previousValues: appliedFilters,
      });

      setIsFilterOpened(false);
    }
  };

  const toggleFilter = () => {
    if (isFilterOpened) {
      closeFilter();
    } else {
      openFilter();
    }
  };

  const onClearFilter = () => {
    const selectedValues = { ...appliedFilters };

    if (filterType === 'colors') {
      selectedValues.colorStyles = [];
    }
    selectedValues[filterType] = [];

    biLoggerStore.logClearFilters({
      type: filterType,
      previousValues: appliedFilters,
      selectedValues,
    });
  };

  const handleMouseEnter = () => {
    biLoggerStore.logHoverFilter({
      type: filterType,
      previousValues: appliedFilters,
    });
  };

  return (
    <div
      data-hook={cs(dataHook, { expanded: isFilterOpened })}
      className={styles.root}
      onMouseLeave={closeFilter}
      onMouseEnter={handleMouseEnter}
      onFocus={preventFocusOnClick}
      onClick={toggleFilter}
    >
      <FilterDropdownTitle
        expanded={isFilterOpened}
        title={title}
        selectedCount={appliedFiltersCount}
        dataHook={filterDropdownDataHooks.title()}
        className={styles.dropdownTitle}
      />
      <FilterDropdownContent
        clearLink={clearLink}
        expanded={isFilterOpened}
        onClear={onClearFilter}
        className={styles.dropdown}
        isBorder={isDropdownBorder}
        isSelected={appliedFiltersCount > 0}
        dataHook={filterDropdownDataHooks.dropdown()}
      >
        {children}
      </FilterDropdownContent>
    </div>
  );
};

export const FilterDropdown = injectStoresV2('biLoggerStore', 'routingStore')(FilterDropdownCmp);
