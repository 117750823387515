import React from 'react';
import cs from 'classnames';
import orderBy from 'lodash/orderBy';
import { ConfigStore } from '../../stores/ConfigStore';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { DEFAULT_IMAGE_TYPES, getImageTypeFromPath, ImageMime } from '../../../utils/imageTypes';
import { getMediaPlatformImageUrl } from '../../utils/getMediaPlatformImageUrl';
import { pictureDataHooks } from './Picture.dataHooks';
import { Image } from './Image/Image';
import s from './Picture.scss';

interface BreakpointConfig {
  maxScreenWidth: number;
  imageWidth: number;
  densityList: number[];
}

interface Breakpoint {
  maxScreenWidth: number;
  densityList: {
    imageWidth: number;
    imageHeight: number;
    density: number;
  }[];
}

interface ImageSize {
  width: number;
  height: number;
}

interface PictureProps {
  imagePath: string;
  sourceImageSize: ImageSize;
  breakpoints: Breakpoint[];
  dataHook?: string;
  alt?: string;
  onLoad?: () => void;
  onLoadStart?: () => void;
  configStore: ConfigStore;
  imageClassName?: string;
  imageTypes?: ImageMime[];
  lazyLoad?: boolean;
}

interface Source {
  screenWidth: number;
  densityList: {
    src: string;
    imageWidth: number;
    imageHeight: number;
    density: number;
  }[];
  type: ImageMime;
}

const DEFAULT_IMAGE_FILTER = 'q_90,usm_0.60_1.00_0.01';

export const createBreakpoints = (breakpoints: BreakpointConfig[], aspectRatio: ImageSize): Breakpoint[] =>
  breakpoints.map((breakpoint) => ({
    maxScreenWidth: breakpoint.maxScreenWidth,
    densityList: breakpoint.densityList.map((density) => {
      const imageWidth = Math.ceil(breakpoint.imageWidth * density);
      const imageHeight = Math.ceil(imageWidth * (aspectRatio.height / aspectRatio.width));

      return {
        imageWidth,
        imageHeight,
        density,
      };
    }),
  }));

const PictureCmp: React.FC<PictureProps> = ({
  configStore,
  imagePath,
  breakpoints,
  sourceImageSize,
  dataHook,
  alt,
  onLoad,
  onLoadStart,
  imageClassName,
  imageTypes = DEFAULT_IMAGE_TYPES,
  lazyLoad,
}) => {
  const { width: sourceWidth, height: sourceHeight } = sourceImageSize;
  const imagesDomain = configStore.config.imagesMediaPlatformDomain;

  const sources: Source[] = [].concat(
    ...imageTypes.map((type) =>
      breakpoints.map((breakpoint) => ({
        screenWidth: breakpoint.maxScreenWidth,
        densityList: breakpoint.densityList.map(({ density, imageWidth, imageHeight }) => ({
          src: getMediaPlatformImageUrl({
            imagesDomain,
            imagePath,
            type,
            width: imageWidth,
            height: imageHeight,
            filters: DEFAULT_IMAGE_FILTER,
          }),
          imageWidth,
          imageHeight,
          density,
        })),
        type,
      })),
    ),
  );

  const baseImageType = getImageTypeFromPath(imagePath);
  const baseSource = sources.find((source) => source.type === baseImageType) ?? sources[0];
  const baseImage = baseSource.densityList[0];

  const getSources = (): {
    key: string;
    media: string;
    srcSet: string;
    type: ImageMime;
  }[] => {
    const originalMedia = `(min-width: ${sources[sources.length - 1].screenWidth + 1}px)`;
    const filters = 'al_c,q_90';
    const originalSources = imageTypes.map((type) => {
      return {
        key: `original-${type}`,
        media: originalMedia,
        srcSet: getMediaPlatformImageUrl({
          imagesDomain,
          imagePath,
          width: sourceWidth,
          height: sourceHeight,
          type,
          filters,
        }),
        type,
      };
    });

    const result = sources
      .map((source) => ({
        key: `${source.screenWidth}-${source.type}`,
        media: `(max-width: ${source.screenWidth}px)`,
        srcSet: source.densityList.map(({ src, density }) => `${src} ${density}x`).join(', '),
        type: source.type,
      }))
      .concat(originalSources);

    return orderBy(result, ['type'], ['desc']);
  };

  return (
    <picture data-hook={dataHook}>
      {getSources().map((sourceProps) => (
        <source data-hook={pictureDataHooks.source()} {...sourceProps} />
      ))}
      <Image
        className={cs(s.image, imageClassName)}
        src={baseImage.src}
        alt={alt}
        dataHook={pictureDataHooks.image()}
        lazyLoad={lazyLoad}
        onLoad={onLoad}
        onLoadStart={onLoadStart}
      />
    </picture>
  );
};

export const Picture = injectStoresV2('configStore')(PictureCmp);
