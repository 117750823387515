import React from 'react';
import { tooltipDataHooks } from './Tooltip.dataHooks';
import s from './Tooltip.scss';

interface TooltipProps {
  content: React.ReactNode;
  dataHook?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ dataHook, content, children }) => (
  <span className={s.tooltipContainer} data-hook={dataHook}>
    <span className={s.tooltipHolder}>
      <span className={s.tooltip} data-hook={tooltipDataHooks.content()}>
        {content}
      </span>
    </span>
    {children}
  </span>
);
