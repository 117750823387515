import React from 'react';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { OverriddenCategoryNamesStore } from '../../../../stores/OverriddenCategoryNamesStore';
import { CategoryGroup } from '../../../stores/CategoriesStore';
import { RoutingStore } from '../../../stores/RoutingStore';
import { BILoggerStore } from '../../../stores/BILoggerStore';
import { MegaMenu } from '../../MegaMenu/MegaMenu';
import { MegaMenuDropdownItem } from '../../MegaMenu/MegaMenuDropdownSection/MegaMenuDropdownSection';
import { ExperimentsStore } from '../../../../stores/ExperimentsStore';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import { Category } from '../../../../web-api/domain/category';

interface HeaderMenuProps extends WithTranslations {
  categoryGroup: CategoryGroup;
  overriddenCategoryNamesStore: OverriddenCategoryNamesStore;
  routingStore: RoutingStore;
  biLoggerStore: BILoggerStore;
  experimentsStore: ExperimentsStore;
  adjustWidth?: boolean;
  setToggleRef?: (element?: HTMLDivElement) => void;
  onItemClick?: () => void;
  isRightAlignedDropdown?: boolean;
  dataHook?: string;
}

const HeaderMenuCmp: React.FC<HeaderMenuProps> = ({
  categoryGroup,
  overriddenCategoryNamesStore,
  routingStore,
  onItemClick,
  biLoggerStore,
  experimentsStore,
  adjustWidth,
  isRightAlignedDropdown,
  setToggleRef,
  dataHook,
  t,
}) => {
  const isFiltersExperiment = experimentsStore.is('specs.funnel.TemplatesGalleryEnableFilters', 'true');
  const { appliedFilters } = routingStore;

  const mapSubCategoryToMenuItem = (category: Category, subCategory: Category): MegaMenuDropdownItem => {
    const isActiveCategory = activeCategorySlug === category.name;
    const link =
      isFiltersExperiment && isActiveCategory
        ? routingStore.rebuildRoute({
            page: 1,
            categorySlug: category.name,
            subCategorySlug: subCategory.name,
            ...appliedFilters,
          })
        : routingStore.locationBuilders.subCategory({
            categorySlug: category.name,
            subCategorySlug: subCategory.name,
          });

    return {
      id: subCategory.name,
      label: overriddenCategoryNamesStore.getMenuCategoryName(subCategory),
      link,
    };
  };

  const mapCategoryToMenuItem = (category: Category): MegaMenuDropdownItem => {
    const isActiveCategory = activeCategorySlug === category.name;
    const link =
      isFiltersExperiment && isActiveCategory
        ? routingStore.rebuildRoute({
            page: 1,
            categorySlug: category.name,
            subCategorySlug: null,
            ...appliedFilters,
          })
        : routingStore.locationBuilders.category({ categorySlug: category.name });

    return {
      id: category.name,
      label: overriddenCategoryNamesStore.getMenuCategoryName(category),
      link,
      items: category.subCategories.map((subCategory) => mapSubCategoryToMenuItem(category, subCategory)),
    };
  };

  const { activeCategorySlug, activeSubCategorySlug } = routingStore;
  const label = t(categoryGroup.displayNameKey);
  const shortLabel = t(categoryGroup?.shortDisplayNameKey) || label;
  const items = categoryGroup.categories.filter((category) => !!category).map(mapCategoryToMenuItem);
  const activeCategoryName = activeSubCategorySlug || activeCategorySlug;

  return (
    <MegaMenu
      label={label}
      items={items}
      shortLabel={shortLabel}
      activeItemId={activeCategoryName}
      onItemClick={onItemClick}
      adjustWidth={adjustWidth}
      isRightAlignedDropdown={isRightAlignedDropdown}
      dataHook={dataHook}
      setToggleRef={setToggleRef}
    />
  );
};

export const HeaderMenu = withTranslations(
  injectStoresV2('overriddenCategoryNamesStore', 'routingStore', 'biLoggerStore', 'experimentsStore')(HeaderMenuCmp),
);
