import React from 'react';
import { CSSTransition } from 'react-transition-group';
import cs from 'classnames';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { HeaderStore } from '../../../stores/HeaderStore';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import SearchCloseIcon from '../../../../Icons/Close.svg';
import { usePreventFocusOnClick } from '../../../hooks/usePreventFocusOnClick';
import styles from './SearchClearButton.scss';

interface SearchClearButtonProps extends WithTranslations {
  headerStore: HeaderStore;
  dataHook: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const transitionTimeout = parseInt(styles.transitionTime, 10);

const SearchClearButtonCmp: React.FC<SearchClearButtonProps> = ({ headerStore, t, dataHook, onClick }) => {
  const preventFocusOnClick = usePreventFocusOnClick();
  const isVisible = headerStore.searchCriteria !== '';
  const isLargeSearchInput = headerStore.isLargeSearchInput;

  return (
    <CSSTransition
      in={isVisible}
      classNames={{
        enter: styles.buttonEnter,
        enterActive: styles.buttonEnterActive,
        enterDone: styles.buttonEnterDone,
        exitActive: styles.buttonLeaveActive,
      }}
      timeout={{ enter: transitionTimeout, exit: transitionTimeout }}
      mountOnEnter
      unmountOnExit
    >
      <button
        onClick={onClick}
        type="button"
        className={cs(styles.button, {
          [styles.buttonLeave]: !isVisible,
          [styles.largeInputClearButton]: isLargeSearchInput,
        })}
        aria-label={t('search.reset')}
        data-hook={dataHook}
        onFocus={preventFocusOnClick}
      >
        <SearchCloseIcon fill="currentColor" className={styles.iconClear} />
      </button>
    </CSSTransition>
  );
};

export const SearchClearButton = withTranslations(injectStoresV2('headerStore')(SearchClearButtonCmp));
