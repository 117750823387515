import React from 'react';
import cs from 'classnames';
import styles from './LayoutIcon.scss';

interface StripLayoutProps {
  active: boolean;
}

export const StripLayout = ({ active = false }: StripLayoutProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96 60"
      className={cs(styles.layoutIcon, { [styles.active]: active })}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 48h96V3.528C96 1.579 94.39 0 92.403 0H3.597C1.61 0 0 1.58 0 3.528V48z"
          fill="#A7CBFC"
          className={styles.fillBlue}
          fillRule="nonzero"
        />
        <path
          d="M93.176.938a1.88 1.88 0 011.883 1.875v54.374a1.88 1.88 0 01-1.883 1.876H2.824A1.88 1.88 0 01.94 57.187V2.813A1.88 1.88 0 012.824.937h90.352m0-.937H2.824A2.818 2.818 0 000 2.813v54.374A2.818 2.818 0 002.824 60h90.352C94.736 60 96 58.74 96 57.187V2.813A2.818 2.818 0 0093.176 0z"
          fill="#A7CBFC"
          className={styles.fillBlue}
          fillRule="nonzero"
        />
        <g fill="#fff" className={styles.fillWhite}>
          <path d="M50.927 30.1h-6.999a2 2 0 110-4h7a2 2 0 010 4z" fillRule="nonzero" />
          <path d="M33 16h29v1H33zM39.397 20H55.83v1H39.397z" />
        </g>
        <path
          d="M5.974 60h24.052c.538 0 .974-.143.974-.32v-7.36c0-.177-.436-.32-.974-.32H5.974c-.538 0-.974.143-.974.32v7.36c0 .177.436.32.974.32zM35.974 60h24.052c.538 0 .974-.143.974-.32v-7.36c0-.177-.436-.32-.974-.32H35.974c-.538 0-.974.143-.974.32v7.36c0 .177.436.32.974.32zM64.974 60h24.052c.538 0 .974-.143.974-.32v-7.36c0-.177-.436-.32-.974-.32H64.974c-.538 0-.974.143-.974.32v7.36c0 .177.436.32.974.32z"
          fill="#A7CBFC"
          className={styles.fillBlue}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
