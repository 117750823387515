import { getImageFilenameAndExtension } from '../v2/utils/getImageFilenameAndExtension';

export const enum ImageMime {
  WEBP = 'image/webp',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}

export const enum ImageExtension {
  WEBP = 'webp',
  JPG = 'jpg',
  PNG = 'png',
}

const IMAGE_TYPE_TO_EXTENSION: Record<ImageMime, ImageExtension> = {
  [ImageMime.WEBP]: ImageExtension.WEBP,
  [ImageMime.JPEG]: ImageExtension.JPG,
  [ImageMime.PNG]: ImageExtension.PNG,
};

export const DEFAULT_IMAGE_TYPES: ImageMime[] = [ImageMime.WEBP, ImageMime.JPEG];

export const getImageExtensionFromType = (imageType: ImageMime): ImageExtension => {
  return IMAGE_TYPE_TO_EXTENSION[imageType] ?? ImageExtension.JPG;
};

const getImageTypeFromExtension = (extension: ImageExtension): ImageMime => {
  return (
    (Object.keys(IMAGE_TYPE_TO_EXTENSION).find(
      (imageType: ImageMime) => IMAGE_TYPE_TO_EXTENSION[imageType] === extension,
    ) as ImageMime) ?? ImageMime.JPEG
  );
};

export function getImageTypeFromPath(imagePath: string): ImageMime {
  const { extension } = getImageFilenameAndExtension(imagePath);

  return getImageTypeFromExtension(extension as ImageExtension);
}
