import React from 'react';
import { CSSTransition } from 'react-transition-group';
import FiltersExpandIcon from '../../../../Icons/FiltersExpand.svg';
import { usePreventFocusOnClick } from '../../../hooks/usePreventFocusOnClick';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import styles from './ShowFiltersButton.scss';
import { showFiltersButtonDataHooks } from './ShowFiltersButton.dataHooks';

interface ShowFiltersButtonProps extends WithTranslations {
  isVisible: boolean;
  onClick: React.MouseEventHandler;
  appliedFiltersCount: number;
  dataHook?: string;
}

const transitionEnterTimeout = parseInt(styles.transitionEnterTimeout, 10);
const transitionLeaveTimeout = parseInt(styles.transitionLeaveTimeout, 10);

const ShowFiltersButtonCmp: React.FC<ShowFiltersButtonProps> = ({
  isVisible,
  onClick,
  appliedFiltersCount,
  dataHook,
  t,
}) => {
  const preventFocusOnClick = usePreventFocusOnClick();

  return (
    <CSSTransition
      in={isVisible}
      classNames={{
        enter: styles.showFiltersButtonEnter,
        enterActive: styles.showFiltersButtonEnterActive,
        exit: styles.showFiltersButtonLeave,
        exitActive: styles.showFiltersButtonLeaveActive,
      }}
      timeout={{ enter: transitionEnterTimeout, exit: transitionLeaveTimeout }}
      unmountOnExit
      mountOnEnter
    >
      <button onClick={onClick} onFocus={preventFocusOnClick} className={styles.showFiltersButton} data-hook={dataHook}>
        <FiltersExpandIcon fill="currentColor" className={styles.expandIcon} />
        {t('filters.expand')}
        {appliedFiltersCount > 0 && (
          <span data-hook={showFiltersButtonDataHooks.appliedFiltersCount()}> ({appliedFiltersCount})</span>
        )}
      </button>
    </CSSTransition>
  );
};

export const ShowFiltersButton = withTranslations(ShowFiltersButtonCmp);
