import { addLeadingStr, stripLeadingStr } from './leadingTrailingStr';

function keyIndex(order: string[], key: string): number {
  const index = order.indexOf(key);
  return index === -1 ? order.length : index;
}

export function stringify(
  params: { [key: string]: undefined | null | string },
  options?: { keysOrder?: string[] },
): string {
  const keysOrder = options?.keysOrder || [];
  const searchParams = new URLSearchParams();

  Object.entries(params)
    .filter(([, value]) => value !== null && value !== undefined)
    .sort(([keyOne], [keyTwo]) => keyIndex(keysOrder, keyOne) - keyIndex(keysOrder, keyTwo))
    .forEach(([key, value]) => searchParams.append(key, value));

  return searchParams.toString();
}

export function parse(query: string): { [key: string]: string } {
  const params = Object.create(null);

  new URLSearchParams(query).forEach((value, key) => (params[key] = value));

  return params;
}

export function joinSearchQuery(...parts: string[]) {
  const searchQuery = addLeadingStr(
    parts
      .map((part) => stripLeadingStr(part, '?'))
      .filter(Boolean)
      .join('&'),
    '?',
  );
  return searchQuery === '?' ? '' : searchQuery;
}
