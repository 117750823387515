import { configure } from 'mobx';
import { History } from 'history';
import { ExperimentsStore, ExperimentsStoreState } from '../../stores/ExperimentsStore';
import { ApiClient } from '../../apiClient/ApiClient';
import { ErrorReporter } from '../../errorHandling/ErrorReporter';
import { PRODUCTION_APPS } from '../utils/appsAndFeatures';
import { i18n } from '../../i18n/i18n';
import { syncHistoryWithStore } from '../../stores/reactRouterStore/syncHistoryWithStore';
import {
  OverriddenCategoryNamesStore,
  OverriddenCategoryNamesStoreState,
} from '../../stores/OverriddenCategoryNamesStore';
import { RoutingStore, RoutingStoreState } from './RoutingStore';
import { RedirectStore } from './RedirectStore';
import { TemplatesStore, TemplatesStoreState } from './TemplatesStore';
import { CategoriesStore, CategoriesStoreState } from './CategoriesStore';
import { BaseURLStore } from './BaseURLStore';
import { BILoggerStore } from './BILoggerStore';
import { ConfigStore, ConfigStoreState } from './ConfigStore';
import { HeaderStore } from './HeaderStore';
import { FedopsLoggerStore } from './FedopsLoggerStore';
import { SearchWithSuggestionsStore } from './SearchWithSuggestionsStore';

configure({ enforceActions: 'observed', safeDescriptors: false });

export interface StoresState {
  config: ConfigStoreState;
  experiments: ExperimentsStoreState;
  categories: CategoriesStoreState;
  templates: TemplatesStoreState;
  routing: RoutingStoreState;
  overriddenCategoryNames: OverriddenCategoryNamesStoreState;
}

export type InitialStoresState = Partial<StoresState> &
  Required<Pick<StoresState, 'experiments' | 'config' | 'routing' | 'overriddenCategoryNames'>>;

export interface Stores {
  headerStore: HeaderStore;
  baseURLStore: BaseURLStore;
  routingStore: RoutingStore;
  redirectStore: RedirectStore;
  experimentsStore: ExperimentsStore;
  templatesStore: TemplatesStore;
  categoriesStore: CategoriesStore;
  biLoggerStore: BILoggerStore;
  fedopsLoggerStore: FedopsLoggerStore;
  configStore: ConfigStore;
  searchWithSuggestionsStore: SearchWithSuggestionsStore;
  overriddenCategoryNamesStore: OverriddenCategoryNamesStore;
}

export function getState(stores: Stores): StoresState {
  return {
    config: stores.configStore.serialize(),
    experiments: stores.experimentsStore.serialize(),
    categories: stores.categoriesStore.serialize(),
    templates: stores.templatesStore.serialize(),
    routing: stores.routingStore.serialize(),
    overriddenCategoryNames: stores.overriddenCategoryNamesStore.serialize(),
  };
}

export function createStores(parameters: {
  i18nInstance: i18n;
  apiClient: ApiClient;
  history: History;
  errorReporter: ErrorReporter;
  initialState: InitialStoresState;
}): Stores {
  const { i18nInstance, history, apiClient, initialState, errorReporter } = parameters;

  const experimentsStore = new ExperimentsStore(initialState.experiments);
  const routingStore = new RoutingStore({ experimentsStore }, initialState.routing);
  syncHistoryWithStore(history, routingStore);
  const configStore = new ConfigStore(initialState.config);
  const overriddenCategoryNamesStore = new OverriddenCategoryNamesStore(
    i18nInstance,
    initialState.overriddenCategoryNames,
  );
  const headerStore = new HeaderStore({ routingStore, experimentsStore, configStore });
  const baseURLStore = new BaseURLStore(configStore);
  const categoriesStore = new CategoriesStore(
    { apiClient, errorReporter, configStore, experimentsStore },
    initialState.categories,
  );
  const fedopsLoggerStore = new FedopsLoggerStore();
  const biLoggerStore = new BILoggerStore();
  const searchWithSuggestionsStore = new SearchWithSuggestionsStore({
    apiClient,
    errorReporter,
    routingStore,
    biLoggerStore,
    categoriesStore,
    fedopsLoggerStore,
    configStore,
    experimentsStore,
  });
  const templatesStore = new TemplatesStore(
    {
      apiClient,
      experimentsStore,
      routingStore,
      errorReporter,
      categoriesStore,
      apps: PRODUCTION_APPS,
      baseURLStore,
      biLoggerStore,
      fedopsLoggerStore,
      headerStore,
      searchWithSuggestionsStore,
      configStore,
    },
    initialState.templates,
  );
  const redirectStore = new RedirectStore({
    categoriesStore,
    routingStore,
    configStore,
    experimentsStore,
    templatesStore,
  });

  biLoggerStore.init({
    routingStore,
    categoriesStore,
    templatesStore,
    headerStore,
    baseURLStore,
    templatePageSessionId: initialState.config.templatePageSessionId,
    configStore,
  });

  return {
    headerStore,
    configStore,
    baseURLStore,
    routingStore,
    redirectStore,
    experimentsStore,
    templatesStore,
    categoriesStore,
    biLoggerStore,
    fedopsLoggerStore,
    searchWithSuggestionsStore,
    overriddenCategoryNamesStore,
  };
}

export async function storesInitialFetch(stores: Stores): Promise<void> {
  await stores.categoriesStore.initialFetch();
  await stores.templatesStore.initialFetch();
}
