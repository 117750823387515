import React from 'react';
import { CSSTransition } from 'react-transition-group';
import FiltersCloseIcon from '../../../../Icons/Close.svg';
import { usePreventFocusOnClick } from '../../../hooks/usePreventFocusOnClick';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import styles from './HideFiltersButton.scss';

interface ShowFiltersButtonProps extends WithTranslations {
  isVisible: boolean;
  onClick: React.MouseEventHandler;
  dataHook?: string;
}

const transitionEnterTimeout = parseInt(styles.transitionEnterTimeout, 10);
const transitionLeaveTimeout = parseInt(styles.transitionLeaveTimeout, 10);

const HideFiltersButtonCmp: React.FC<ShowFiltersButtonProps> = ({ isVisible, onClick, dataHook, t }) => {
  const preventFocusOnClick = usePreventFocusOnClick();

  return (
    <CSSTransition
      in={isVisible}
      classNames={{
        enter: styles.hideFiltersButtonEnter,
        enterActive: styles.hideFiltersButtonEnterActive,
        exit: styles.hideFiltersButtonLeave,
        exitActive: styles.hideFiltersButtonLeaveActive,
      }}
      timeout={{ enter: transitionEnterTimeout, exit: transitionLeaveTimeout }}
      mountOnEnter
      unmountOnExit
    >
      <button onClick={onClick} onFocus={preventFocusOnClick} className={styles.hideFiltersButton} data-hook={dataHook}>
        <FiltersCloseIcon fill="currentColor" className={styles.closeIcon} />
        {t('filters.close')}
      </button>
    </CSSTransition>
  );
};

export const HideFiltersButton = withTranslations(HideFiltersButtonCmp);
