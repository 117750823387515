import React from 'react';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { HeaderStore } from '../../../stores/HeaderStore';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import styles from './SearchSubmitButton.scss';

interface SearchButtonProps extends WithTranslations {
  headerStore: HeaderStore;
  dataHook: string;
}

const SearchSubmitButtonCmp: React.FC<SearchButtonProps> = ({ headerStore, t, dataHook }) => {
  return (
    <button className={styles.button} data-hook={dataHook} type="submit" tabIndex={-1}>
      <span className={styles.text}>{t('search.dropdown.searchFor', { criteria: headerStore.searchCriteria })}</span>
    </button>
  );
};

export const SearchSubmitButton = withTranslations(injectStoresV2('headerStore')(SearchSubmitButtonCmp));
