import React from 'react';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { CategoriesStore } from '../../../stores/CategoriesStore';
import { RoutingStore } from '../../../stores/RoutingStore';
import { SeoLink } from '../../SeoLink/SeoLink';
import { TemplatesStore } from '../../../stores/TemplatesStore';
import s from './SubHeaderBreadcrumbs.scss';
import { subHeaderBreadcrumbsDataHooks } from './SubHeaderBreadcrumbs.dataHooks';

interface SubHeaderBreadCrumbsProps {
  dataHook?: string;
  categoriesStore: CategoriesStore;
  templatesStore: TemplatesStore;
  routingStore: RoutingStore;
}

const SubHeaderBreadCrumbsCmp: React.FC<SubHeaderBreadCrumbsProps> = (props) => {
  const { categoriesStore, templatesStore, routingStore, dataHook } = props;

  const category = categoriesStore.getCategoryBySlug(templatesStore.fetchParams?.categorySlug);

  if (!category) {
    return null;
  }

  const categoryUrl =
    category &&
    routingStore.rebuildRoute({
      page: 1,
      categorySlug: category.name,
      subCategorySlug: null,
    });

  return (
    <div className={s.root} data-hook={dataHook}>
      <SeoLink
        className={s.category}
        to={categoryUrl}
        dataHook={subHeaderBreadcrumbsDataHooks.categoryLink()}
        data-bi-element="breadcrumb"
        data-bi-element-value="breadcrumb"
        data-bi-gallery-doc-index="0"
      >
        {category.displayName}
      </SeoLink>
      &nbsp;/
    </div>
  );
};

export const SubHeaderBreadcrumbs = injectStoresV2(
  'routingStore',
  'categoriesStore',
  'templatesStore',
)(SubHeaderBreadCrumbsCmp);
