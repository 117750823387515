import React from 'react';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { RoutingStore } from '../../../stores/RoutingStore';
import { FilterDropdown } from '../FilterDropdown/FilterDropdown';
import { EMPTY_FILTERS, Feature } from '../../../stores/routes/sortFiltersParams';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import { featuresFilterDataHooks } from './FeaturesFilter.dataHooks';
import { FilterFeaturesItem } from './FeaturesFilterItem/FeaturesFilterItem';
import styles from './FeaturesFilter.scss';

export const FEATURES: Feature[] = [
  'store',
  'blog',
  'booking',
  'gallery',
  'instagram',
  'video',
  'pricing',
  'events',
  'music',
  'forum',
  'restaurant',
];

interface FeatureFilterProps extends WithTranslations {
  routingStore: RoutingStore;
  dataHook?: string;
}

const FeaturesFilterCmp: React.FC<FeatureFilterProps> = ({ routingStore, dataHook, t }) => {
  const appliedFilters = routingStore.appliedFilters ?? EMPTY_FILTERS;
  const appliedFeaturesFilters = appliedFilters.features;
  const clearLink = routingStore.rebuildRoute({
    page: 1,
    filters: {
      ...appliedFilters,
      features: [],
    },
  });

  return (
    <FilterDropdown
      dataHook={dataHook}
      clearLink={clearLink}
      isDropdownBorder={true}
      title={t('filters.title.features')}
      filterType="features"
      appliedFiltersCount={appliedFeaturesFilters.length}
    >
      <ul className={styles.content}>
        {FEATURES.map((feature) => (
          <li key={feature}>
            <FilterFeaturesItem feature={feature} dataHook={featuresFilterDataHooks.filterItem({ id: feature })} />
          </li>
        ))}
      </ul>
    </FilterDropdown>
  );
};

export const FeaturesFilter = withTranslations(injectStoresV2('routingStore')(FeaturesFilterCmp));
