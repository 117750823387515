import { dataHooks } from '../../../libs/dataHooks';

export const templatesPageDataHooks = dataHooks<{
  cbdBanner: {};
  pagination: {};
  numericPagination: {};
  templates: {};
  subTitle: {};
  subHeader: {};
  filters: {};
  filtersNoResults: {};
  categoriesNavigationBar: {};
  networkErrorModal: {};
  sorting: {};
}>('templatesPage');
