import { i18n, TranslationKey } from '../i18n/i18n';
import { AppConfig } from '../config';
import { CategoryModel } from '../v1/model/CategoryModel';
import { Category } from '../web-api/domain/category';

export type OverriddenCategoryNamesStoreState = AppConfig['customCategoriesTranslations'];

type OverriddenCategory = Category | CategoryModel | undefined;

export class OverriddenCategoryNamesStore {
  constructor(
    private i18nInstance: i18n,
    private readonly customCategoriesTranslations: OverriddenCategoryNamesStoreState,
  ) {}

  public getMenuCategoryName(category: OverriddenCategory): string {
    const categorySlug = OverriddenCategoryNamesStore.getCategorySlug(category);
    const translationKey = categorySlug ? this.customCategoriesTranslations.menu[categorySlug] : undefined;
    return this.translateCategory(translationKey, category);
  }

  public getPageTitleCategoryName(category: OverriddenCategory): string {
    const categorySlug = OverriddenCategoryNamesStore.getCategorySlug(category);
    const translationKey = categorySlug ? this.customCategoriesTranslations.title[categorySlug] : undefined;
    return this.translateCategory(translationKey, category);
  }

  private translateCategory(translationKey: TranslationKey | undefined, category: OverriddenCategory): string {
    const displayName = OverriddenCategoryNamesStore.getCategoryDisplayName(category);
    const categoryName = translationKey
      ? this.i18nInstance.t(translationKey, { defaultValue: displayName })
      : displayName;

    return categoryName || this.i18nInstance.t('header.category');
  }

  private static getCategorySlug(category: OverriddenCategory) {
    if (!category) {
      return undefined;
    }
    return 'name' in category ? category.name : category.id;
  }

  private static getCategoryDisplayName(category: OverriddenCategory) {
    if (!category) {
      return undefined;
    }
    return 'displayName' in category ? category.displayName : category.title;
  }

  serialize(): OverriddenCategoryNamesStoreState {
    return this.customCategoriesTranslations;
  }
}
