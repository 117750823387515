import React from 'react';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { RoutingStore } from '../../../stores/RoutingStore';
import { CategoriesStore } from '../../../stores/CategoriesStore';
import { TemplatesStore } from '../../../stores/TemplatesStore';
import { ExperimentsStore } from '../../../../stores/ExperimentsStore';
import { RouteLocation } from '../../../stores/routes/createRoute';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import { CategoryTagList } from './CategoryTagList/CategoryTagList';

interface SubCategoriesProps extends WithTranslations {
  categoriesStore: CategoriesStore;
  routingStore: RoutingStore;
  templatesStore: TemplatesStore;
  experimentsStore: ExperimentsStore;
  dataHook?: string;
}

const SubCategoriesCmp = (props: SubCategoriesProps) => {
  const { categoriesStore, dataHook, routingStore, t, templatesStore, experimentsStore } = props;
  const { activeSubCategorySlug, locationBuilders } = routingStore;

  const currentCategorySlug = templatesStore.fetchParams?.categorySlug;
  const loadedCategorySlug = templatesStore.loadedFetchParams?.categorySlug;

  const categorySlug = currentCategorySlug || loadedCategorySlug;

  const category = categoriesStore.getCategoryBySlug(categorySlug);

  if (!category || !category.subCategories.length) {
    return null;
  }

  const isFiltersExperiment = experimentsStore.is('specs.funnel.TemplatesGalleryEnableFilters', 'true');
  const { appliedFilters } = routingStore;
  const categoryLocation: RouteLocation = isFiltersExperiment
    ? routingStore.rebuildRoute({
        page: 1,
        categorySlug: category.name,
        subCategorySlug: null,
        ...appliedFilters,
      })
    : locationBuilders.category({ categorySlug: category.name });

  const categories = [
    {
      title: t('subHeader.subCategories.all'),
      slug: category.name,
      location: categoryLocation,
      isActive: !activeSubCategorySlug,
      isAll: true,
    },
    ...category.subCategories.map((subCategory) => {
      const isActive = activeSubCategorySlug === subCategory.name;
      let location;

      if (isFiltersExperiment) {
        location = routingStore.rebuildRoute({
          page: 1,
          categorySlug: category.name,
          subCategorySlug: subCategory.name,
          ...appliedFilters,
        });
      } else {
        location = locationBuilders.subCategory({
          categorySlug: category.name,
          subCategorySlug: subCategory.name,
        });
      }

      return {
        title: subCategory.displayName,
        slug: subCategory.name,
        location,
        isActive,
      };
    }),
  ];

  return <CategoryTagList dataHook={dataHook} categories={categories} />;
};

export const SubCategories = withTranslations(
  injectStoresV2('routingStore', 'categoriesStore', 'templatesStore', 'experimentsStore')(SubCategoriesCmp),
);
