import React from 'react';
import cs from 'classnames';
import { CSSTransition } from 'react-transition-group';
import styles from './DropdownWrapper.scss';

export interface DropdownWrapperProps {
  isOpen: boolean;
  children: React.ReactNode;
  isSimpleMenu?: boolean;
  isRightAligned?: boolean;
  dropdownRef?: React.MutableRefObject<HTMLDivElement>;
  className?: string;
  dataHook?: string;
}

const dropdownMenuEnterTimeout = parseInt(styles.dropdownMenuEnterTimeout, 10);
const dropdownMenuLeaveTimeout = parseInt(styles.dropdownMenuLeaveTimeout, 10);

export const DropdownWrapper: React.FC<DropdownWrapperProps> = ({
  isOpen,
  children,
  dropdownRef,
  className,
  dataHook,
  isSimpleMenu,
  isRightAligned,
}) => (
  <CSSTransition
    timeout={{ enter: dropdownMenuEnterTimeout, exit: dropdownMenuLeaveTimeout }}
    classNames={{
      enter: styles.dropdownEnter,
      enterActive: styles.dropdownEnterActive,
      enterDone: styles.dropdownEnterDone,
      exit: styles.dropdownLeave,
      exitActive: styles.dropdownLeaveActive,
      exitDone: styles.dropdownLeaveDone,
    }}
    in={isOpen}
  >
    <div
      ref={dropdownRef}
      className={cs(
        styles.dropdown,
        isSimpleMenu ? styles.simpleMenuDropdown : styles.megaMenuDropdown,
        { [styles.rightAlignedDropdown]: isRightAligned },
        className,
      )}
      data-hook={dataHook}
    >
      {children}
    </div>
  </CSSTransition>
);
