import React from 'react';
import cs from 'classnames';
import { EMPTY_FILTERS, Layout } from '../../../../stores/routes/sortFiltersParams';
import { SeoLink } from '../../../SeoLink/SeoLink';
import FilterCheckmark from '../../../../../Icons/FilterCheckmark.svg';
import { injectStoresV2 } from '../../../../stores/injectStoresV2';
import { RoutingStore } from '../../../../stores/RoutingStore';
import { FilterLayoutName } from '../../../FilterLayoutName/FilterLayoutName';
import { BILoggerStore } from '../../../../stores/BILoggerStore';
import styles from './LayoutsFiltersItem.scss';
import { HeroImageLayout } from './LayoutIcons/HeroImageLayout';
import { TextLayout } from './LayoutIcons/TextLayout';
import { InlineLayout } from './LayoutIcons/InlineLayout';
import { FramedLayout } from './LayoutIcons/FramedLayout';
import { GridLayout } from './LayoutIcons/GridLayout';
import { SplitScreenLayout } from './LayoutIcons/SplitScreenLayout';
import { StripLayout } from './LayoutIcons/StripLayout';
import { UniqueLayout } from './LayoutIcons/UniqueLayout';

const IMAGES_CONFIG: Record<Layout, React.FunctionComponent<{ active: boolean }>> = {
  'hero-image': HeroImageLayout,
  strip: StripLayout,
  framed: FramedLayout,
  inline: InlineLayout,
  'split-screen': SplitScreenLayout,
  grid: GridLayout,
  text: TextLayout,
  unusual: UniqueLayout,
};

interface FilterLayoutsItemProps {
  routingStore: RoutingStore;
  biLoggerStore: BILoggerStore;
  filterId: Layout;
  dataHook?: string;
}

export const FilterLayoutsItemCmp: React.FC<FilterLayoutsItemProps> = ({
  filterId,
  dataHook,
  routingStore,
  biLoggerStore,
}) => {
  const appliedFilters = routingStore.appliedFilters ?? EMPTY_FILTERS;
  const isActiveFilter = appliedFilters.layouts.includes(filterId);

  const Icon = IMAGES_CONFIG[filterId];
  const valuesOnFilterSelect = isActiveFilter
    ? appliedFilters.layouts.filter((id) => id !== filterId)
    : [...appliedFilters.layouts, filterId];

  const link = routingStore.rebuildRoute({
    page: 1,
    filters: {
      ...appliedFilters,
      layouts: valuesOnFilterSelect,
    },
  });

  const onSelect = (event: React.MouseEvent) => {
    event.stopPropagation();

    const params = {
      previousValues: appliedFilters,
      selectedValues: {
        ...appliedFilters,
        layouts: valuesOnFilterSelect,
      },
      clickedItemValue: filterId,
    };

    isActiveFilter
      ? biLoggerStore.logUnselectFilter({ type: 'layouts', ...params })
      : biLoggerStore.logSelectFilter({ type: 'layouts', ...params });
  };

  return (
    <SeoLink
      rel="nofollow"
      className={cs(styles.filterLink, { [styles.filterLinkActive]: isActiveFilter })}
      to={link}
      onClick={(event) => onSelect(event)}
      dataHook={dataHook}
    >
      <Icon active={isActiveFilter} />
      <span className={styles.title}>
        <FilterCheckmark fill="currentColor" className={styles.checkbox} />
        <FilterLayoutName layout={filterId} />
      </span>
    </SeoLink>
  );
};

export const FilterLayoutsItem = injectStoresV2('routingStore', 'biLoggerStore')(FilterLayoutsItemCmp);
