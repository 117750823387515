import React, { FC, useEffect, useState } from 'react';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { ConfigStore } from '../../stores/ConfigStore';
import { WithTranslations, withTranslations, Trans } from '../../../utils/withTranslations';
import { BILoggerStore } from '../../stores/BILoggerStore';
import CloseIcon from './CloseIcon.svg';
import s from './HirePartnerBanner.scss';
import { hirePartnerBannerDataHooks } from './HirePartnerBanner.dataHooks';

interface HirePartnerBannerProps extends WithTranslations {
  configStore: ConfigStore;
  biLoggerStore: BILoggerStore;
}

const HirePartnerBannerCmp: FC<HirePartnerBannerProps> = ({ configStore, biLoggerStore, t }) => {
  const [isShown, setIsShown] = useState<boolean>(configStore.config.hirePartnerBanner);

  useEffect(() => {
    if (isShown) {
      biLoggerStore.logSawElement({ elementName: 'hire_professional_banner' });
    }
  }, []);

  if (!isShown) {
    return null;
  }

  const onCloseButtonClick = () => {
    setIsShown(false);
  };

  return (
    <div className={s.container} data-hook={hirePartnerBannerDataHooks.banner()} data-bi-element="hire_professional">
      <div className={s.mainBlock}>
        <span className={s.bannerText}>
          <Trans i18nKey="banner.hire-partner.body" />
        </span>
        <a
          href="https://www.wix.com/marketplace/brief-auto-match?entry=wix-funnel&origin=wix-templates-page&startStep=CATEGORIES&deep=false"
          target="_self"
          className={s.link}
          data-hook={hirePartnerBannerDataHooks.mainCTA()}
          data-bi-element="hire_professional_cta"
        >
          {t('banner.hire-partner.cta')}
        </a>
      </div>
      <button
        onClick={onCloseButtonClick}
        className={s.closeButton}
        data-hook={hirePartnerBannerDataHooks.closeButton()}
        data-bi-element="hire_professional_close_banner"
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export const HirePartnerBanner = withTranslations(injectStoresV2('configStore', 'biLoggerStore')(HirePartnerBannerCmp));
