interface BiElementDataProps {
  clicked_element: string | 'other';
  clicked_element_identifier: string | 'NULL';
  galleryDocIndex: number;
  clickedTemplateHasVideo?: boolean;
}

export function getBiElementData(target: HTMLElement): BiElementDataProps {
  const galleryDocIndexElement = target.closest('[data-bi-gallery-doc-index]');
  const galleryDocIndex = Number(galleryDocIndexElement?.getAttribute('data-bi-gallery-doc-index'));

  const biElement = target.closest('[data-bi-element]');
  const clickedElement = biElement?.getAttribute('data-bi-element') ?? 'other';
  const clickedElementIdentifier = biElement?.getAttribute('data-bi-element-value') ?? 'NULL';

  const templateHasVideoElement = target.closest('[data-bi-template-has-video]');
  const templateHasVideoDataValue = templateHasVideoElement?.getAttribute('data-bi-template-has-video');
  const clickedTemplateHasVideo = templateHasVideoDataValue ? templateHasVideoDataValue === 'true' : undefined;

  return {
    clicked_element: clickedElement,
    clicked_element_identifier: clickedElementIdentifier,
    galleryDocIndex,
    clickedTemplateHasVideo,
  };
}
