import React from 'react';
import { CSSTransition } from 'react-transition-group';
import cs from 'classnames';
import SearchNewIcon from '../../../../Icons/SearchNew.svg';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { HeaderStore } from '../../../stores/HeaderStore';
import styles from './SearchLabel.scss';

interface SearchLabelProps {
  dataHook: string;
  headerStore: HeaderStore;
}

const transitionTimeout = parseInt(styles.transitionTime, 10);

const SearchLabelCmp: React.FC<SearchLabelProps> = ({ dataHook, headerStore }) => {
  const isVisible = headerStore.searchCriteria === '';

  return (
    <CSSTransition
      in={isVisible}
      classNames={{
        enter: styles.labelEnter,
        enterActive: styles.labelEnterActive,
        enterDone: styles.labelEnterDone,
        exitActive: styles.labelLeaveActive,
      }}
      timeout={{ enter: transitionTimeout, exit: transitionTimeout }}
      mountOnEnter
      unmountOnExit
    >
      <label htmlFor="criteria" className={cs(styles.label, { [styles.labelLeave]: !isVisible })} data-hook={dataHook}>
        <SearchNewIcon fill="currentColor" className={styles.iconSearch} />
      </label>
    </CSSTransition>
  );
};

export const SearchLabel = injectStoresV2('headerStore')(SearchLabelCmp);
