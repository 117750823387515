import React from 'react';
import cs from 'classnames';
import { RoutingStore } from '../../../stores/RoutingStore';
import { SeoLink } from '../../SeoLink/SeoLink';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import s from './PageLink.scss';
import { pageLinkDataHooks } from './PageLink.dataHooks';

interface PageLinkProps {
  dataHook?: string;
  className: string;
  page: number;
  title: string;
  onPageClick: () => void;
  routingStore: RoutingStore;
}

const PageLinkCmp: React.FC<PageLinkProps> = (props) => {
  const { routingStore, page, children, className, onPageClick, dataHook, title } = props;
  const isDisabled = page === routingStore.currentPage;

  return (
    <li className={cs(className, { [s.disabled]: isDisabled })} data-hook={dataHook}>
      {isDisabled ? (
        children
      ) : (
        <SeoLink
          className={s.link}
          to={routingStore.rebuildRoute({ page })}
          onClick={onPageClick}
          dataHook={pageLinkDataHooks.link()}
          children={children}
          title={title}
        />
      )}
    </li>
  );
};

export const PageLink = injectStoresV2('routingStore')(PageLinkCmp);
