import { v4 as uuidv4 } from 'uuid';
import { InteractionType } from './BILoggerStore';

export class Interaction {
  public type: InteractionType;
  public key: string;
  public startTime: number;

  constructor(interactionType: InteractionType, startTime?: number) {
    this.type = interactionType;
    this.key = uuidv4();

    if (startTime) {
      this.startTime = startTime;
    }
  }

  public reportLoadingStart() {
    this.startTime = Date.now();
  }
}
