import React from 'react';
import { LinkProps } from 'react-router-dom';
import { Link } from '../../../v1/components/SeoLink/Link';
import { RouteLocation } from '../../stores/routes/createRoute';
import { BaseURLStore } from '../../stores/BaseURLStore';
import { injectStoresV2 } from '../../stores/injectStoresV2';

interface SeoLinkProps extends Omit<LinkProps, 'href' | 'to'> {
  dataHook?: string;
  baseURLStore: BaseURLStore;
  to: RouteLocation;
  setRef?: (element?: HTMLAnchorElement) => void;
}

const SeoLinkCmp: React.FC<SeoLinkProps> = ({ to, baseURLStore, dataHook, setRef, ...rest }) => (
  <Link {...rest} data-hook={dataHook} to={to} href={baseURLStore.buildURL(to)} ref={setRef} />
);

export const SeoLink = injectStoresV2('baseURLStore')(SeoLinkCmp);
