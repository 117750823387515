import { observe } from 'mobx';
import { History, Location, LocationListener } from 'history';
import { RouterStore } from './RouterStore';

/**
 * Copied from https://github.com/alisd23/mobx-react-router.
 * Adapted to typescript and mobx6.
 */
export const syncHistoryWithStore = (history: History, store: RouterStore) => {
  // Initialise store
  store.history = history;

  // Handle update from history object
  const handleLocationChange = (location: Location) => {
    store._updateLocation(location);
  };

  const unsubscribeFromHistory = history.listen(handleLocationChange);
  handleLocationChange(history.location);

  // @ts-expect-error
  history.subscribe = (listener: LocationListener) => {
    const onStoreChange = () => {
      const rawLocation = { ...store.location };
      listener(rawLocation, history.action);
    };

    // Listen for changes to location state in store
    const unsubscribeFromStore = observe(store, 'location', onStoreChange);

    listener(store.location, history.action);

    return unsubscribeFromStore;
  };
  // @ts-expect-error
  history.unsubscribe = unsubscribeFromHistory;

  return history;
};
