import React from 'react';

export const useOnWindowResize = (onResize: (event: Event) => void) => {
  React.useEffect(() => {
    if (window) {
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }
  }, [onResize]);
};
