import React, { useRef, useEffect } from 'react';

interface ImageProps {
  src: string;
  alt: string;
  dataHook?: string;
  className?: string;
  onLoad?: () => void;
  onLoadStart?: () => void;
  lazyLoad?: boolean;
}

export const Image: React.FC<ImageProps> = ({
  src,
  alt,
  dataHook,
  onLoad,
  onLoadStart,
  className,
  lazyLoad = true,
}) => {
  const imageRef = useRef<HTMLImageElement>();
  useEffect(() => {
    if (imageRef.current && !imageRef.current.complete && onLoadStart) {
      onLoadStart();
    }
  }, [onLoadStart, src]);

  const loading = lazyLoad ? 'lazy' : 'eager';

  return (
    <img
      src={src}
      alt={alt}
      data-hook={dataHook}
      loading={loading}
      onLoad={() => onLoad && onLoad()}
      ref={imageRef}
      className={className}
    />
  );
};
