import React from 'react';
import { __RouterContext as RouterContext } from 'react-router';
import { LinkProps as ReactLinkProps } from 'react-router-dom';
import { createLocation } from 'history';

export const resolveToLocation = (to, currentLocation) => (typeof to === 'function' ? to(currentLocation) : to);

export const normalizeToLocation = (to, currentLocation) => {
  return typeof to === 'string' ? createLocation(to, null, null, currentLocation) : to;
};

// React 15 compat
const forwardRefShim = (C) => C;
let { forwardRef } = React;
if (typeof forwardRef === 'undefined') {
  forwardRef = forwardRefShim;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

interface LinkAnchorProps extends Omit<ReactLinkProps, 'to'> {
  navigate: any;
  ref?: any;
}

const LinkAnchor = forwardRef(
  (
    {
      innerRef, // TODO: deprecate
      navigate,
      onClick,
      ...rest
    }: LinkAnchorProps,
    forwardedRef,
  ) => {
    const { target } = rest;

    const props = {
      ...rest,
      onClick: (event) => {
        try {
          if (onClick) {
            onClick(event);
          }
        } catch (ex) {
          event.preventDefault();
          throw ex;
        }

        if (
          !event.defaultPrevented && // onClick prevented default
          event.button === 0 && // ignore everything but left clicks
          (!target || target === '_self') && // let browser handle "target=_blank" etc.
          !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
          event.preventDefault();
          navigate();
        }
      },
    };

    // React 15 compat
    if (forwardRefShim !== forwardRef) {
      props.ref = forwardedRef || innerRef;
    } else {
      props.ref = innerRef;
    }

    /* eslint-disable-next-line jsx-a11y/anchor-has-content */
    return <a {...props} />;
  },
);

export interface LinkProps extends ReactLinkProps {
  component?: any;
}

/**
 * The public API for rendering a history-aware <a>.
 */
export const Link = forwardRef(
  (
    {
      component = LinkAnchor,
      replace,
      to,
      innerRef, // TODO: deprecate
      ...rest
    }: LinkProps,
    forwardedRef,
  ) => {
    return (
      <RouterContext.Consumer>
        {(context) => {
          const { history } = context;

          const location = normalizeToLocation(resolveToLocation(to, context.location), context.location);

          const href = rest.href || (location ? history.createHref(location) : '');
          const props: LinkAnchorProps = {
            ...rest,
            href,
            navigate() {
              const location = resolveToLocation(to, context.location);
              const method = replace ? history.replace : history.push;

              method(location);
            },
          };

          // React 15 compat
          if (forwardRefShim !== forwardRef) {
            props.ref = forwardedRef || innerRef;
          } else {
            props.innerRef = innerRef;
          }

          return React.createElement(component, props);
        }}
      </RouterContext.Consumer>
    );
  },
);
