import { RefObject, useEffect, useState } from 'react';

export function useInViewport<T extends HTMLElement>(nodeRef: RefObject<T>, options?: IntersectionObserverInit) {
  const [inViewport, setInViewport] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setInViewport(entry.isIntersecting);
    }, options);
    const currentRef = nodeRef.current;
    if (nodeRef.current) {
      observer.observe(currentRef as any);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [nodeRef, options]);

  return inViewport;
}
