import React from 'react';

import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { RouteLocation } from '../../../stores/routes/createRoute';
import { BILoggerStore } from '../../../stores/BILoggerStore';
import { SeoLink } from '../../SeoLink/SeoLink';
import { megaMenuDropdownSectionDataHooks } from './MegaMenuDropdownSection.dataHooks';

import s from './MegaMenuDropdownSection.scss';

export interface MegaMenuDropdownItem {
  id: string;
  label: string;
  link: RouteLocation;
  items?: MegaMenuDropdownItem[];
}

interface MegaMenuDropdownSectionProps extends MegaMenuDropdownItem {
  groupLabel: string;
  biLoggerStore: BILoggerStore;
  onItemClick?: (e: React.SyntheticEvent) => void;
  dataHook?: string;
}

const MegaMenuDropdownSectionCmp: React.FC<MegaMenuDropdownSectionProps> = ({
  id: sectionId,
  label,
  link,
  items,
  groupLabel,
  onItemClick,
  biLoggerStore,
  dataHook,
}) => (
  <li className={s.section} data-hook={dataHook}>
    <SeoLink
      className={s.headingLink}
      to={link}
      dataHook={megaMenuDropdownSectionDataHooks.headingItemLink()}
      onClick={(event) => {
        biLoggerStore.logCategorySelect({ clickSource: 'header', category: sectionId });
        onItemClick && onItemClick(event);
      }}
      data-bi-element="category"
      data-bi-element-value={sectionId}
    >
      {label}
    </SeoLink>
    {items && items.length !== 0 && (
      <ul className={s.list}>
        {items.map(({ id: itemId, label, link: itemLink }) => (
          <li key={itemId} className={s.item}>
            <SeoLink
              to={itemLink}
              className={s.itemLink}
              dataHook={megaMenuDropdownSectionDataHooks.itemLink({ id: itemId })}
              onClick={(event) => {
                biLoggerStore.logCategorySelect({
                  clickSource: 'header',
                  category: sectionId,
                  subCategory: itemId,
                });
                onItemClick && onItemClick(event);
              }}
              data-bi-element="sub_category"
              data-bi-element-value={itemId}
            >
              {label}
            </SeoLink>
          </li>
        ))}
      </ul>
    )}
  </li>
);

export const MegaMenuDropdownSection = injectStoresV2('biLoggerStore')(MegaMenuDropdownSectionCmp);
