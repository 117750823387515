import { dataHooks } from '../../../libs/dataHooks';

export const headerMenuPanelDataHooks = dataHooks<{
  headerMenuPanel: {};
  search: {};
  headerMenu: { key: string };
  allTemplatesLink: {};
  blankTemplatesLink: {};
  moreCategoriesMenu: {};
}>('headerMenuPanel');
