import Sentry from '@sentry/browser';
import { Logger } from '@wix/wix-log';

export type ErrorToReport = Error | { message: string };
export type CustomAttributes = { [key: string]: string | number | boolean };

export interface ErrorReporter {
  reportError: (error: ErrorToReport, customAttributes?: CustomAttributes) => void;
}

export class LoggerErrorReporter implements ErrorReporter {
  constructor(private readonly logger: Logger) {}

  reportError(error: ErrorToReport, customAttributes?: CustomAttributes) {
    this.logger.error(error.message, error, customAttributes);
  }
}

export class SentryErrorReporter implements ErrorReporter {
  constructor(private readonly sentry: typeof Sentry) {}

  reportError(error: ErrorToReport, customAttributes?: CustomAttributes) {
    this.sentry.withScope((scope) => {
      Object.entries(customAttributes || {}).forEach(([key, value]) => scope.setExtra(key, value));
      this.sentry.captureException(error);
    });
  }
}

export class ConsoleErrorReporter implements ErrorReporter {
  reportError(error: ErrorToReport, customAttributes?: CustomAttributes) {
    console.error(error, customAttributes);
  }
}
