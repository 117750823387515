import { RoutingStore } from '../../stores/RoutingStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { OverriddenCategoryNamesStore } from '../../../stores/OverriddenCategoryNamesStore';
import { TFunction } from '../../../i18n/i18n';

interface GenerateTitleParams {
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
  overriddenCategoryNamesStore: OverriddenCategoryNamesStore;
  t: TFunction;
}

export function generateTitle({
  routingStore,
  categoriesStore,
  overriddenCategoryNamesStore,
  t,
}: GenerateTitleParams): string {
  const { matchedRoute, primaryCategorySlug } = routingStore;

  if (matchedRoute.routeName === 'category') {
    const { categorySlug, page } = matchedRoute.params;
    const category = categoriesStore.getCategoryBySlug(categorySlug);

    const options = {
      category: overriddenCategoryNamesStore.getPageTitleCategoryName(category),
    };

    if (categorySlug === primaryCategorySlug) {
      if (page === 1) {
        return t('seo.title.home');
      }
      return t('seo.title.category.allPage', { page });
    }

    if (category.name === 'design') {
      return t('seo.title.category.v2.design', { page });
    }
    return t('seo.title.category.v2', { ...options, page });
  }

  if (matchedRoute.routeName === 'subCategory') {
    const { subCategorySlug, categorySlug, page } = matchedRoute.params;

    const category = categoriesStore.getCategoryBySlug(categorySlug);
    const subCategory = categoriesStore.getCategoryBySlug(subCategorySlug);

    const options = {
      category: overriddenCategoryNamesStore.getPageTitleCategoryName(category),
      subCategory: overriddenCategoryNamesStore.getPageTitleCategoryName(subCategory),
    };

    return t('seo.title.subCategory.v2', { ...options, page });
  }

  if (matchedRoute.routeName === 'search') {
    const { criteria } = matchedRoute.params;
    return t('seo.title.searchResults', { query: criteria });
  }

  return t('seo.title.home');
}
