import cs from 'classnames';
import React from 'react';
import { useThrottleCallback } from '@react-hook/throttle';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { useOnWindowScroll } from '../../hooks/useOnWindowScroll';
import { CategoriesStore, CategoryGroup } from '../../stores/CategoriesStore';
import { RoutingStore } from '../../stores/RoutingStore';
import { HeaderStore } from '../../stores/HeaderStore';
import { SeoLink } from '../SeoLink/SeoLink';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { Search } from '../Search/Search';
import { useScrollToTop } from '../../contexts/ScrollToTopContext';
import { BILoggerStore } from '../../stores/BILoggerStore';
import { ConfigStore } from '../../stores/ConfigStore';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { headerMenuPanelDataHooks } from './HeaderMenuPanel.dataHooks';
import { HeaderMenu } from './HeaderMenu/HeaderMenu';
import styles from './HeaderMenuPanel.scss';

interface HeaderMenuPanelProps extends WithTranslations {
  categoriesStore: CategoriesStore;
  routingStore: RoutingStore;
  headerStore: HeaderStore;
  biLoggerStore: BILoggerStore;
  configStore: ConfigStore;
  experimentsStore: ExperimentsStore;
  dataHook?: string;
  focusOnSubTitle?: () => void;
}

const HeaderMenuPanelCmp: React.FC<HeaderMenuPanelProps> = ({
  categoriesStore,
  focusOnSubTitle,
  dataHook,
  routingStore,
  headerStore,
  biLoggerStore,
  configStore,
  experimentsStore,
  t,
}) => {
  const { isSticky, isSearchInputFocused } = headerStore;
  const { activeCategorySlug, primaryCategorySlug, locationBuilders } = routingStore;
  const { categoryGroups, getCategoryBySlug } = categoriesStore;
  const { currentLanguage } = configStore.config;
  const isSortingEnabled = currentLanguage === 'en';

  const moreCategoryGroup: CategoryGroup = {
    key: 'more',
    displayNameKey: isSortingEnabled
      ? 'categoriesMenu.genericCategories.collections'
      : 'categoriesMenu.genericCategories.more',
    categories: [
      getCategoryBySlug('new'),
      getCategoryBySlug('most-popular'),
      getCategoryBySlug('ntt-exclusives'),
    ].filter(Boolean),
  };
  const headerPanelRef = React.useRef<HTMLDivElement>();
  const menuItemsRef = React.useRef<HTMLElement[]>([]);
  menuItemsRef.current = [];
  const { scrollToTop } = useScrollToTop();

  const addItemRef = (element: HTMLElement) => {
    if (element && !menuItemsRef.current.includes(element)) {
      menuItemsRef.current.push(element);
    }
  };

  const handleMenuKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;
    const isArrowLeft = key === 'ArrowLeft';
    const isArrowRight = key === 'ArrowRight';
    if (!isArrowLeft && !isArrowRight) {
      return;
    }

    const activeMenuItemIndex = menuItemsRef.current.findIndex((menuItem) => menuItem === document.activeElement);

    if (activeMenuItemIndex === -1) {
      return;
    }

    const getNewActiveMenuIndex = () => {
      if (isArrowLeft) {
        return activeMenuItemIndex > 0 ? activeMenuItemIndex - 1 : menuItemsRef.current.length - 1;
      }

      return activeMenuItemIndex + 1 < menuItemsRef.current.length ? activeMenuItemIndex + 1 : 0;
    };

    const newActiveMenuItem = menuItemsRef.current[getNewActiveMenuIndex()];
    newActiveMenuItem.focus();
  };

  const handleMenuItemClick = () => {
    scrollToTop();
    focusOnSubTitle && focusOnSubTitle();
  };

  const onAllTemplatesClick = () => {
    handleMenuItemClick();
    biLoggerStore.logCategorySelect({ clickSource: 'header', category: 'all' });
  };

  const onBlankTemplatesClick = () => {
    handleMenuItemClick();
    biLoggerStore.logCategorySelect({ clickSource: 'header', category: 'blank' });
  };

  const recalcSticky = React.useCallback(() => {
    if (headerPanelRef.current) {
      const headerPanelPlace = parseInt(window.getComputedStyle(headerPanelRef.current).top, 10);
      const headerPanelPositionOnScreen = headerPanelRef.current.getBoundingClientRect().top;
      const isHeaderMoved = headerPanelPositionOnScreen <= headerPanelPlace;
      headerStore.setIsSticky(isHeaderMoved);
    }
  }, [headerStore]);
  const throttleTimeout = 200;
  useOnWindowScroll(useThrottleCallback(recalcSticky, 1000 / throttleTimeout));

  const isAllTemplates = activeCategorySlug === primaryCategorySlug;
  const isBlankTemplates = activeCategorySlug === 'blank';
  const isSingleHeader = currentLanguage !== 'en';

  return (
    <nav
      className={cs(styles.menuHolder, {
        [styles.sticky]: isSticky,
        [styles.singleHeader]: isSingleHeader,
      })}
      onKeyDown={handleMenuKeyDown}
      data-hook={dataHook}
      ref={headerPanelRef}
      aria-label={t('header.menu.navigationHint')}
    >
      <ul className={styles.menu} aria-label={t('categoriesMenu.categoriesBySubject')}>
        {categoryGroups.map((categoryGroup) => (
          <HeaderMenu
            dataHook={headerMenuPanelDataHooks.headerMenu({ key: categoryGroup.key })}
            categoryGroup={categoryGroup}
            key={categoryGroup.key}
            onItemClick={handleMenuItemClick}
            setToggleRef={addItemRef}
            adjustWidth
          />
        ))}
      </ul>
      <ul className={styles.rightMenu} aria-label={t('categoriesMenu.genericCategories')}>
        <li>
          <SeoLink
            className={cs(styles.menuLink, { [styles.active]: isAllTemplates })}
            dataHook={headerMenuPanelDataHooks.allTemplatesLink()}
            onClick={onAllTemplatesClick}
            to={locationBuilders.home({})}
            setRef={addItemRef}
            data-bi-element="category"
            data-bi-element-value="all"
          >
            <span
              className={cs(styles.linkLabel, {
                [styles.hiddenOnMediumScreen]: isSticky && isSearchInputFocused,
                [styles.visibleOnMediumScreen]: isSticky && !isSearchInputFocused,
                [styles.noAnimation]: !isSticky,
              })}
            >
              {t('overrides.menu.all')}
            </span>
            <span
              className={cs(styles.shortLinkLabel, {
                [styles.visibleOnMediumScreen]: isSticky && isSearchInputFocused,
                [styles.hiddenOnMediumScreen]: isSticky && !isSearchInputFocused,
                [styles.noAnimation]: !isSticky,
              })}
              aria-hidden="true"
            >
              {t('overrides.menu.smallScreen.all')}
            </span>
          </SeoLink>
        </li>
        <li className={styles.delimiter} aria-hidden="true" />
        {!isSticky && (
          <li>
            <SeoLink
              className={cs(styles.menuLink, { [styles.active]: isBlankTemplates })}
              dataHook={headerMenuPanelDataHooks.blankTemplatesLink()}
              onClick={onBlankTemplatesClick}
              to={locationBuilders.category({ categorySlug: 'blank' })}
              setRef={addItemRef}
              data-bi-element="category"
              data-bi-element-value="blank"
            >
              <span>{t('header.menu.blankTemplates')}</span>
            </SeoLink>
          </li>
        )}
        {!isSticky && <li className={styles.delimiter} aria-hidden="true" />}
        {!isSticky && (
          <HeaderMenu
            dataHook={headerMenuPanelDataHooks.moreCategoriesMenu()}
            onItemClick={handleMenuItemClick}
            categoryGroup={moreCategoryGroup}
            setToggleRef={addItemRef}
            isRightAlignedDropdown
          />
        )}
      </ul>
      {isSticky && <Search dataHook={headerMenuPanelDataHooks.search()} focusOnSubTitle={focusOnSubTitle} />}
    </nav>
  );
};

export const HeaderMenuPanel = withTranslations(
  injectStoresV2(
    'categoriesStore',
    'headerStore',
    'routingStore',
    'biLoggerStore',
    'configStore',
    'experimentsStore',
  )(HeaderMenuPanelCmp),
);
