import cs from 'classnames';
import React from 'react';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { ConfigStore } from '../../stores/ConfigStore';
import { DesignSiteLaterButton } from '../DesignSiteLaterButton';
import { Search } from '../Search/Search';
import { HeaderStore } from '../../stores/HeaderStore';
import { RoutingStore } from '../../stores/RoutingStore';
import { BILoggerStore } from '../../stores/BILoggerStore';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { headerTitleDataHooks } from './HeaderTitle.dataHooks';
import styles from './HeaderTitle.scss';

interface HeaderTitleProps extends WithTranslations {
  children?: React.ReactNode;
  dataHook?: string;
  focusOnSubTitle?: () => void;
  headerStore: HeaderStore;
  routingStore: RoutingStore;
  experimentsStore: ExperimentsStore;
  configStore: ConfigStore;
  biLoggerStore: BILoggerStore;
}

const HeaderTitleCmp: React.FC<HeaderTitleProps> = ({
  t,
  dataHook,
  headerStore,
  routingStore,
  experimentsStore,
  configStore,
  biLoggerStore,
}) => {
  const HeadingTagName = routingStore.matchedRoute.routeName === 'home' ? 'h1' : 'div';
  const { isLargeSearchInput, isSearchInputFocused } = headerStore;

  const { dashboardFirstMetaSiteId } = routingStore.getGlobalUrlParams();
  const isLoggedIn = configStore.config.identity.loggedIn;
  const isShowDesignLaterBtn =
    isLoggedIn &&
    dashboardFirstMetaSiteId &&
    experimentsStore.is('specs.funnel.TemplatesGalleryDesignSiteLaterButton', 'true');

  const onDesignLaterClick = () => {
    biLoggerStore.logClickElement({
      clicked_element: 'design site later',
      clicked_element_identifier: 'design_site_later_button',
    });
  };

  return (
    <div className={cs(styles.header, { [styles.column]: isLargeSearchInput })} data-hook={dataHook}>
      <HeadingTagName
        className={cs(styles.title, {
          [styles.smallText]: !isLargeSearchInput && isSearchInputFocused,
          [styles.centerAligned]: isLargeSearchInput,
        })}
        data-hook={headerTitleDataHooks.title()}
      >
        {t('header.title.v2')}
      </HeadingTagName>
      {!headerStore.isSticky && <Search dataHook={headerTitleDataHooks.search()} />}
      {isShowDesignLaterBtn && <DesignSiteLaterButton msid={dashboardFirstMetaSiteId} onClick={onDesignLaterClick} />}
    </div>
  );
};

export const HeaderTitle = withTranslations(
  injectStoresV2('headerStore', 'routingStore', 'experimentsStore', 'configStore', 'biLoggerStore')(HeaderTitleCmp)
);
