import React from 'react';
import cs from 'classnames';
import DropdownArrow from '../../../../Icons/DropdownArrow.svg';
import styles from './FilterDropdownTitle.scss';

interface FilterDropdownTitleProps {
  title: string;
  selectedCount?: number;
  onClick?: () => void;
  onFocus?: (event: React.FocusEvent<HTMLSpanElement>) => void;
  expanded?: boolean;
  dataHook?: string;
  className?: string;
}

export const FilterDropdownTitle: React.FC<FilterDropdownTitleProps> = ({
  title,
  selectedCount,
  expanded,
  onClick,
  onFocus,
  dataHook,
  className,
}) => {
  return (
    <span
      onClick={onClick}
      onFocus={(event) => onFocus && onFocus(event)}
      data-hook={dataHook}
      tabIndex={0}
      className={cs(className, styles.dropdownTitle, {
        [styles.expanded]: expanded,
      })}
    >
      <span className={styles.text}>{`${title}${selectedCount ? ` (${selectedCount})` : ''}`}</span>
      <DropdownArrow fill="currentColor" height="1em" className={styles.arrow} />
    </span>
  );
};
