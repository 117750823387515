import React from 'react';
import { GallerySearchSuggestionItem } from '../../../stores/SearchWithSuggestionsStore';
import { SuggestionsListItem } from './SuggestionsListItem/SuggestionsListItem';

import styles from './SuggestionsList.scss';
import { suggestionsListDataHooks } from './SuggestionsList.dataHooks';

interface SuggestionsListProps {
  dataHook: string;
  onSearch: () => void;
  suggestions: GallerySearchSuggestionItem[];
}

export const SuggestionsList: React.FC<SuggestionsListProps> = ({ dataHook, onSearch, suggestions }) => {
  return (
    <ul className={styles.suggestionsList} role="listbox" data-hook={dataHook}>
      {suggestions.map((option, index) => (
        <SuggestionsListItem
          onSearch={onSearch}
          key={`${index}${option.text}`}
          option={option}
          dataHook={suggestionsListDataHooks.item()}
        />
      ))}
    </ul>
  );
};
