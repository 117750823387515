import React from 'react';
import cs from 'classnames';
import styles from './LayoutIcon.scss';

interface GridLayoutProps {
  active: boolean;
}

export const GridLayout = ({ active = false }: GridLayoutProps) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="92px"
      height="58px"
      viewBox="0 0 92 58"
      enableBackground="new 0 0 92 58"
      className={cs(styles.layoutIcon, { [styles.active]: active })}
    >
      <switch>
        <g>
          <g>
            <g>
              <path
                fill="#D6E6FF"
                className={styles.fillBlue}
                d="M62.667,32.139h21.807c0.552,0,1-0.448,1-1V8.171c0-0.552-0.448-1-1-1H62.667c-0.552,0-1,0.448-1,1
					v22.969C61.667,31.692,62.115,32.139,62.667,32.139z"
              />
            </g>
            <g>
              <path
                fill="#D6E6FF"
                className={styles.fillBlue}
                d="M35.333,32.139H57.14c0.552,0,1-0.448,1-1V8.171c0-0.552-0.448-1-1-1H35.333c-0.552,0-1,0.448-1,1
					v22.969C34.333,31.692,34.781,32.139,35.333,32.139z"
              />
            </g>
            <g>
              <path
                fill="#D6E6FF"
                className={styles.fillBlue}
                d="M8,32.139h21.807c0.552,0,1-0.448,1-1V8.171c0-0.552-0.448-1-1-1H8c-0.552,0-1,0.448-1,1v22.969
					C7,31.692,7.448,32.139,8,32.139z"
              />
            </g>
            <g>
              <path
                fill="#D6E6FF"
                className={styles.fillBlue}
                d="M62.667,58h21.807c0.552,0,1-0.448,1-1V36.706c0-0.552-0.448-1-1-1H62.667c-0.552,0-1,0.448-1,1V57
					C61.667,57.552,62.115,58,62.667,58z"
              />
            </g>
            <g>
              <path
                fill="#D6E6FF"
                className={styles.fillBlue}
                d="M35.333,58H57.14c0.552,0,1-0.448,1-1V36.706c0-0.552-0.448-1-1-1H35.333c-0.552,0-1,0.448-1,1V57
					C34.333,57.552,34.781,58,35.333,58z"
              />
            </g>
            <g>
              <path
                fill="#D6E6FF"
                className={styles.fillBlue}
                d="M8,58h21.807c0.552,0,1-0.448,1-1V36.706c0-0.552-0.448-1-1-1H8c-0.552,0-1,0.448-1,1V57
					C7,57.552,7.448,58,8,58z"
              />
            </g>
          </g>
          <path
            fill="none"
            stroke="#0C6EFC"
            className={styles.strokeBlue}
            strokeMiterlimit="10"
            d="M3.5,57.5h85c1.657,0,3-1.343,3-3v-51c0-1.657-1.343-3-3-3h-85
			c-1.657,0-3,1.343-3,3v51C0.5,56.157,1.843,57.5,3.5,57.5z"
          />
        </g>
      </switch>
    </svg>
  );
};
