import React, { RefObject } from 'react';
import cs from 'classnames';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import { SearchWithSuggestionsStore } from '../../../stores/SearchWithSuggestionsStore';
import { HeaderStore } from '../../../stores/HeaderStore';
import { dropdownId } from '../SuggestionsDropdown/SuggestionsDropdown';
import styles from './SearchInput.scss';

interface SearchInputProps extends WithTranslations {
  searchWithSuggestionsStore: SearchWithSuggestionsStore;
  headerStore: HeaderStore;
  dataHook: string;
  forwardedRef: RefObject<HTMLInputElement>;
}

const SearchInputCmp: React.FC<SearchInputProps> = ({
  dataHook,
  searchWithSuggestionsStore,
  headerStore,
  forwardedRef,
  t,
}) => {
  const {
    preSelectNextSuggestion,
    preSelectPreviousSuggestion,
    setHighlightedSuggestionIndex,
    isDropdownOpened,
    preSelectedSuggestion,
  } = searchWithSuggestionsStore;
  const { searchCriteria, isSticky, isLargeSearchInput, setSearchInputFocused, setSearchCriteria } = headerStore;
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isDropdownOpened) {
      return;
    }

    if (event.key === 'ArrowDown') {
      preSelectNextSuggestion();
      event.preventDefault();
    }
    if (event.key === 'ArrowUp') {
      preSelectPreviousSuggestion();
      event.preventDefault();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHighlightedSuggestionIndex(null);
    setSearchCriteria(event.target.value);
  };

  const inputValue = preSelectedSuggestion?.text ?? searchCriteria;

  return (
    <input
      id="criteria"
      name="criteria"
      ref={forwardedRef}
      className={cs(styles.searchInput, {
        [styles.fullHeight]: isLargeSearchInput,
        [styles.extraVerticalPadding]: isSticky,
        [styles.smallerText]: isSticky,
      })}
      autoComplete="off"
      autoFocus={isLargeSearchInput && !searchCriteria}
      onChange={handleInputChange}
      onFocus={() => setSearchInputFocused(true)}
      onKeyDown={handleInputKeyDown}
      aria-label={t('search.label')}
      data-hook={dataHook}
      data-bi-element="search"
      data-bi-element-value="NULL"
      type="search"
      value={inputValue}
      placeholder={t('header.searchPlaceholder')}
      aria-autocomplete="list"
      aria-haspopup="true"
      role="combobox"
      aria-controls={dropdownId}
      aria-expanded={isDropdownOpened}
    />
  );
};

export const SearchInput = withTranslations(
  injectStoresV2('searchWithSuggestionsStore', 'headerStore')(SearchInputCmp),
);
