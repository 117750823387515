import React from 'react';
import cs from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { RoutingStore } from '../../stores/RoutingStore';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import FiltersResetIcon from '../../../Icons/FiltersReset.svg';
import { SeoLink } from '../SeoLink/SeoLink';
import { BILoggerStore } from '../../stores/BILoggerStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { EMPTY_FILTERS } from '../../stores/routes/sortFiltersParams';
import { getActiveCategorySlug } from '../../stores/routes/route';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { filtersDataHooks } from './Filters.dataHooks';
import { LayoutsFilter } from './LayoutsFilter/LayoutsFilter';
import { ColorsFilter } from './ColorsFilter/ColorsFilter';
import { FeaturesFilter } from './FeaturesFilter/FeaturesFilter';
import styles from './Filters.scss';
import { ShowFiltersButton } from './ShowFiltersButton/ShowFiltersButton';
import { HideFiltersButton } from './HideFiltersButton/HideFiltersButton';

interface FiltersProps extends WithTranslations {
  routingStore: RoutingStore;
  biLoggerStore: BILoggerStore;
  templatesStore: TemplatesStore;
  dataHook?: string;
}
const filtersTransitionEnterTimeout = parseInt(styles.filtersTransitionEnterTimeout, 10);
const appliedFiltersTransitionEnterTimeout = parseInt(styles.appliedFiltersTransitionEnterTimeout, 10);
const filtersTransitionLeaveTimeout = parseInt(styles.filtersTransitionLeaveTimeout, 10);

const FiltersCmp: React.FC<FiltersProps> = ({ routingStore, biLoggerStore, templatesStore, dataHook, t }) => {
  const { appliedFiltersCount, appliedFilters, matchedRoute, primaryCategorySlug, previousMatchedRoute } = routingStore;
  const activeCategorySlug = getActiveCategorySlug(matchedRoute, primaryCategorySlug);
  const isAllTemplates = activeCategorySlug === 'all';
  const [isFiltersPanelOpen, setIsFilterPanelOpen] = React.useState<boolean>(isAllTemplates);
  const filtersWrapperRef = React.useRef<HTMLDivElement>();
  const hasAppliedFilters = appliedFiltersCount !== 0;
  const isBlankTemplates = activeCategorySlug === 'blank';
  const previousActiveCategorySlug = getActiveCategorySlug(previousMatchedRoute, primaryCategorySlug);
  const filterPanelOpenAndFiltersApplied = isFiltersPanelOpen && hasAppliedFilters;

  const resetLink = routingStore.rebuildRoute({
    page: 1,
    filters: EMPTY_FILTERS,
  });

  React.useEffect(() => {
    if (activeCategorySlug !== previousActiveCategorySlug) {
      setIsFilterPanelOpen(isAllTemplates);
    }
  }, [activeCategorySlug, previousActiveCategorySlug, isAllTemplates]);

  const handleFiltersOpen = () => {
    biLoggerStore.logShowFiltersPanel({
      type: 'all',
      previousValues: appliedFilters ?? EMPTY_FILTERS,
    });
    setIsFilterPanelOpen(true);
  };

  const handleFiltersClose = () => {
    biLoggerStore.logHideFiltersPanel({
      type: 'all',
      previousValues: appliedFilters ?? EMPTY_FILTERS,
    });
    setIsFilterPanelOpen(false);
  };

  const logClearFilters = () => {
    biLoggerStore.logClearFilters({
      type: 'all',
      previousValues: appliedFilters,
      selectedValues: EMPTY_FILTERS,
    });
  };

  if (isBlankTemplates) {
    return null;
  }

  return (
    <div
      className={cs({
        [styles.expandedAppliedFiltersPanelWrapper]: filterPanelOpenAndFiltersApplied,
        [styles.filtersPanelWrapper]: !filterPanelOpenAndFiltersApplied,
        [styles.reducedTopPadding]: isAllTemplates,
      })}
      data-hook={dataHook}
    >
      <div className={styles.filtersPanel} data-hook={filtersDataHooks.filtersWrapper()} ref={filtersWrapperRef}>
        <HideFiltersButton
          isVisible={isFiltersPanelOpen}
          onClick={handleFiltersClose}
          dataHook={filtersDataHooks.collapseButton()}
        />
        <ShowFiltersButton
          isVisible={!isFiltersPanelOpen}
          onClick={handleFiltersOpen}
          appliedFiltersCount={appliedFiltersCount}
          dataHook={filtersDataHooks.expandButton()}
        />
        <CSSTransition
          in={isFiltersPanelOpen}
          classNames={{
            enter: styles.filtersEnter,
            enterActive: styles.filtersEnterActive,
            exit: styles.filtersLeave,
            exitActive: styles.filtersLeaveActive,
          }}
          timeout={{
            enter: hasAppliedFilters ? appliedFiltersTransitionEnterTimeout : filtersTransitionEnterTimeout,
            exit: filtersTransitionLeaveTimeout,
          }}
          unmountOnExit
          mountOnEnter
        >
          <div className={styles.filters}>
            {hasAppliedFilters && !templatesStore.isNoFiltersResult && (
              <div
                className={styles.description}
                data-hook={filtersDataHooks.description({ visible: hasAppliedFilters && isFiltersPanelOpen })}
              >
                {t('filters.description')}
              </div>
            )}
            <ul className={styles.filtersList}>
              <li className={styles.filterDropdown}>
                <FeaturesFilter dataHook={filtersDataHooks.featuresFilter()} />
              </li>
              <li className={styles.filterDropdown}>
                <LayoutsFilter dataHook={filtersDataHooks.layoutsFilter()} />
              </li>
              <li className={styles.filterDropdown}>
                <ColorsFilter dataHook={filtersDataHooks.colorsFilter()} />
              </li>
            </ul>
            {hasAppliedFilters && (
              <>
                <div className={styles.divider} />
                <SeoLink
                  to={resetLink}
                  onClick={logClearFilters}
                  className={styles.resetButton}
                  dataHook={filtersDataHooks.resetLink()}
                >
                  <FiltersResetIcon fill="currentColor" className={styles.iconReset} />
                  {t('filters.reset')}
                </SeoLink>
              </>
            )}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export const Filters = withTranslations(injectStoresV2('routingStore', 'biLoggerStore', 'templatesStore')(FiltersCmp));
