import { computed, makeObservable } from 'mobx';
import { ExperimentsStore } from '../../stores/ExperimentsStore';
import { RouterStore } from '../../stores/reactRouterStore/RouterStore';
import { RenamedCategory } from '../../config';
import { RoutingStore } from './RoutingStore';
import { stripUnknownQueryParams } from './routes/queryParams';
import { CategoriesStore } from './CategoriesStore';
import { RouteLocation } from './routes/createRoute';
import { ConfigStore } from './ConfigStore';
import { TemplatesStore } from './TemplatesStore';

interface RedirectStoreParams {
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
  configStore: ConfigStore;
  experimentsStore: ExperimentsStore;
  templatesStore: TemplatesStore;
}

export class RedirectStore extends RouterStore {
  private readonly categoriesStore: CategoriesStore;
  private readonly routingStore: RoutingStore;
  private readonly configStore: ConfigStore;
  private readonly experimentsStore: ExperimentsStore;
  private readonly templatesStore: TemplatesStore;

  constructor({ categoriesStore, routingStore, configStore, experimentsStore, templatesStore }: RedirectStoreParams) {
    super();

    makeObservable(this, {
      redirect: computed,
    });

    this.categoriesStore = categoriesStore;
    this.routingStore = routingStore;
    this.configStore = configStore;
    this.experimentsStore = experimentsStore;
    this.templatesStore = templatesStore;
  }

  public get redirect(): {
    location: RouteLocation;
    reason:
      | 'ProperLocation'
      | 'RenamedCategory'
      | 'TemporaryRenamedCategory'
      | 'CategoryNotFound'
      | 'HasParentCategory'
      | 'MainCategory'
      | 'NotExistingPageNumber';
  } | null {
    if (!this.routingStore.location) {
      return null;
    }
    const properLocation = this.redirectProperLocation();
    if (
      properLocation !== null &&
      (properLocation.pathname !== this.routingStore.location.pathname ||
        stripUnknownQueryParams(properLocation.search) !== stripUnknownQueryParams(this.routingStore.location.search))
    ) {
      return {
        location: properLocation,
        reason: 'ProperLocation',
      };
    }

    const parentCategory = this.redirectToParentCategory();
    if (parentCategory) {
      return {
        location: parentCategory,
        reason: 'HasParentCategory',
      };
    }

    const subCategory = this.redirectMainCategory();
    if (subCategory) {
      return {
        location: subCategory,
        reason: 'MainCategory',
      };
    }

    const renamedCategory = this.redirectRenamedCategory(this.configStore.config.renamedCategories);
    if (renamedCategory) {
      return {
        location: renamedCategory,
        reason: 'RenamedCategory',
      };
    }

    const temporaryRenamedCategory = this.redirectRenamedCategory(
      this.configStore.config.temporaryRenamedCategories || {},
    );
    if (temporaryRenamedCategory) {
      return {
        location: temporaryRenamedCategory,
        reason: 'TemporaryRenamedCategory',
      };
    }

    const wrongPageRequested = this.notExistingPageNumber();
    if (wrongPageRequested) {
      return {
        location: wrongPageRequested,
        reason: 'NotExistingPageNumber',
      };
    }

    return null;
  }

  private notExistingPageNumber(): RouteLocation | undefined {
    if (this.routingStore.matchedRoute && this.routingStore.matchedRoute.routeName !== 'home') {
      const matchedRoute = this.routingStore.matchedRoute;
      const requestedPage = matchedRoute.params.page;
      const totalPages = this.templatesStore.totalPages || 1;

      if (requestedPage > totalPages) {
        return this.routingStore.rebuildRoute({
          ...matchedRoute.params,
          page: 1,
        });
      }
    }
  }

  private redirectToParentCategory(): RouteLocation {
    if (this.routingStore.matchedRoute?.routeName === 'category') {
      const { categorySlug } = this.routingStore.matchedRoute.params;
      const parentCategory = this.categoriesStore.getParentCategoryBySlug(categorySlug);

      if (parentCategory) {
        return this.routingStore.locationBuilders.subCategory({
          ...this.routingStore.matchedRoute.params,
          categorySlug: parentCategory.name,
          subCategorySlug: categorySlug,
          page: 1,
        });
      }
    }
  }

  private redirectMainCategory(): RouteLocation {
    if (this.routingStore.matchedRoute?.routeName === 'subCategory') {
      const { subCategorySlug } = this.routingStore.matchedRoute.params;
      const category = this.categoriesStore.getCategoryBySlug(subCategorySlug);
      const subCategoriesCount = (category?.subCategories ?? []).length;

      if (subCategoriesCount > 0) {
        return this.routingStore.locationBuilders.category({
          ...this.routingStore.matchedRoute.params,
          categorySlug: category.name,
          page: 1,
        });
      }
    }
  }

  private redirectProperLocation(): RouteLocation | null {
    if (this.routingStore.matchedRoute?.routeName === 'category') {
      return this.routingStore.locationBuilders.category(this.routingStore.matchedRoute.params);
    }

    if (this.routingStore.matchedRoute?.routeName === 'subCategory') {
      return this.routingStore.locationBuilders.subCategory(this.routingStore.matchedRoute.params);
    }

    if (this.routingStore.matchedRoute?.routeName === 'search') {
      return this.routingStore.locationBuilders.search(this.routingStore.matchedRoute.params);
    }

    return this.routingStore.matchedRoute?.routeName === 'home'
      ? this.routingStore.locationBuilders.home(this.routingStore.matchedRoute.params)
      : null;
  }

  private redirectRenamedCategory(renamedCategories: RenamedCategory): RouteLocation | null {
    let renamedCategoryPathname: string | null = null;

    if (this.routingStore.matchedRoute?.routeName === 'category') {
      const { categorySlug } = this.routingStore.matchedRoute.params;
      renamedCategoryPathname = renamedCategories[categorySlug];
    }

    if (this.routingStore.matchedRoute?.routeName === 'subCategory') {
      const { categorySlug, subCategorySlug } = this.routingStore.matchedRoute.params;
      renamedCategoryPathname = renamedCategories[`${categorySlug}/${subCategorySlug}`];
    }

    if (renamedCategoryPathname) {
      const [renamedCategorySlug, renamedSubCategorySlug] = renamedCategoryPathname.split('/');
      return this.routingStore.rebuildRoute({
        categorySlug: renamedCategorySlug,
        subCategorySlug: renamedSubCategorySlug,
        page: 1,
      });
    }

    return null;
  }
}
