import React, { useMemo } from 'react';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { sanitizeSeoSectionHtml } from '../../../utils/textUtils';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { ConfigStore } from '../../stores/ConfigStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import s from './SeoSection.scss';

interface SeoSectionProps {
  dataHook?: string;
  templatesStore: TemplatesStore;
  experimentsStore: ExperimentsStore;
  configStore: ConfigStore;
}

const SeoSectionCmp: React.FC<SeoSectionProps> = (props) => {
  const {
    dataHook,
    templatesStore: { activeCategory, activeSubCategory },
    configStore: {
      config: { identity: { loggedIn } = {} },
    },
    templatesStore: { loadedFetchParams },
  } = props;

  const category = activeSubCategory || activeCategory;

  const seoSection = category?.seoSection ?? '';
  const page = loadedFetchParams?.page ?? 1;
  const isFirstPage = page === 1;

  const cleanSeoSection = useMemo(() => {
    return sanitizeSeoSectionHtml(seoSection);
  }, [seoSection]);

  if (!cleanSeoSection || loggedIn || !isFirstPage) {
    return null;
  }

  return <div data-hook={dataHook} className={s.wrapper} dangerouslySetInnerHTML={{ __html: `${cleanSeoSection}` }} />;
};

export const SeoSection = injectStoresV2('templatesStore', 'experimentsStore', 'configStore')(SeoSectionCmp);
