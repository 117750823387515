import React from 'react';
import { MegaMenuDropdownItem } from '../MegaMenuDropdownSection/MegaMenuDropdownSection';
import { DropdownWrapper } from '../DropdownWrapper/DropdownWrapper';
import { SeoLink } from '../../SeoLink/SeoLink';
import { BILoggerStore } from '../../../stores/BILoggerStore';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import styles from './SimpleMenuDropdown.scss';
import { simpleMenuDropdownDataHooks } from './SimpleMenuDropdown.dataHooks';

export interface SimpleMenuDropdownProps {
  isOpen: boolean;
  items: MegaMenuDropdownItem[];
  className: string;
  biLoggerStore: BILoggerStore;
  onItemClick?: (e: React.SyntheticEvent) => void;
  isRightAligned?: boolean;
  dataHook?: string;
}

export const SimpleMenuDropdownCmp: React.FC<SimpleMenuDropdownProps> = ({
  isOpen,
  className,
  isRightAligned,
  items,
  onItemClick,
  biLoggerStore,
  dataHook,
}) => {
  return (
    <DropdownWrapper
      isOpen={isOpen}
      isRightAligned={isRightAligned}
      className={className}
      dataHook={dataHook}
      isSimpleMenu
    >
      <ul className={styles.menuList}>
        {items.map(({ link, label, id }) => (
          <li className={styles.menuItem} key={id}>
            <SeoLink
              className={styles.menuItemLink}
              to={link}
              onClick={(event) => {
                biLoggerStore.logCategorySelect({ clickSource: 'header', category: id });
                onItemClick && onItemClick(event);
              }}
              data-bi-element="category"
              data-bi-element-value={label}
              dataHook={simpleMenuDropdownDataHooks.itemLink({ id })}
            >
              <span>{label}</span>
            </SeoLink>
          </li>
        ))}
      </ul>
    </DropdownWrapper>
  );
};

export const SimpleMenuDropdown = injectStoresV2('biLoggerStore')(SimpleMenuDropdownCmp);
