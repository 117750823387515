import { Feature } from '../stores/routes/sortFiltersParams';

export type CmsFeature = 'services' | 'gallery' | 'podcast' | 'accessibility';
export type AppName =
  | 'store'
  | 'gallery'
  | 'booking'
  | 'blog'
  | 'music'
  | 'video'
  | 'instagram'
  | 'restaurantsMenus'
  | 'restaurantsOrders'
  | 'reservations'
  | 'events'
  | 'pricing'
  | 'forum'
  | 'contentManager'
  | 'podcastPlayer'
  | 'photoAlbums'
  | 'artStore'
  | 'challenges'
  | 'groups'
  | 'forms'
  | 'chat'
  | 'fileShare'
  | 'faq';

export type Apps = Record<AppName, string>;

export const PRODUCTION_APPS: Apps = {
  store: '1380b703-ce81-ff05-f115-39571d94dfcd',
  gallery: '14271d6f-ba62-d045-549b-ab972ae1f70e',
  booking: '13d21c63-b5ec-5912-8397-c3a5ddb27a97',
  blog: '14bcded7-0066-7c35-14d7-466cb3f09103',
  music: '13bb5d67-1add-e770-a71f-001277e17c57',
  video: '14409595-f076-4753-8303-9a86f9f71469',
  instagram: '14635256-b183-1c71-a4d2-f55179b80e8a',
  restaurantsMenus: '13c1402c-27f2-d4ab-7463-ee7c89e07578',
  restaurantsOrders: '13e8d036-5516-6104-b456-c8466db39542',
  reservations: '1475ab65-206b-d79a-856d-fa10bdb479ea',
  forum: '14724f35-6794-cd1a-0244-25fd138f9242',
  events: '140603ad-af8d-84a5-2c80-a0f60cb47351',
  pricing: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
  podcastPlayer: '153e1aeb-2ec2-58c4-9715-1b25977bd56f',
  photoAlbums: '13ff8629-c1fc-e289-e81f-bc8c8968e9d6',
  artStore: '147ab90e-91c5-21b2-d6ca-444c28c8a23b',
  challenges: '2936472a-a1ed-4ae5-9f71-614313a9f4e7',
  groups: '148c2287-c669-d849-d153-463c7486a694',
  forms: '14ce1214-b278-a7e4-1373-00cebd1bef7c',
  chat: '14517e1a-3ff0-af98-408e-2bd6953c36a2',
  fileShare: '1537b24e-29d1-6d8f-b8e1-d6860f2f70b9',
  faq: '14c92d28-031e-7910-c9a8-a670011e062d',
  contentManager: '9f87f125-e7d5-4ed9-a16c-999d862e96ab',
};

export const APPS_BY_GALLERY_FEATURE: Record<Feature, AppName[]> = {
  store: ['store'],
  booking: ['booking'],
  events: ['events'],
  blog: ['blog'],
  forum: ['forum'],
  gallery: ['gallery'],
  music: ['music'],
  video: ['video'],
  pricing: ['pricing'],
  instagram: ['instagram'],
  restaurant: ['restaurantsMenus', 'restaurantsOrders', 'reservations'],
};

export const CMS_FEATURES_BY_GALLERY_FEATURE: Record<Feature, CmsFeature[]> = {
  gallery: ['gallery'],
  blog: [],
  booking: [],
  store: [],
  events: [],
  pricing: [],
  video: [],
  instagram: [],
  music: [],
  forum: [],
  restaurant: [],
};

export const getFeatureByAppName = (app: AppName): Feature | undefined => {
  const [feature] =
    Object.entries(APPS_BY_GALLERY_FEATURE).find(([, appsByFeature]) => appsByFeature.includes(app)) ?? [];
  return feature as Feature | undefined;
};

export const getFeatureByAppDefId = (appDefId: string): Feature | undefined => {
  const appName = getAppNameByDefId(PRODUCTION_APPS, appDefId);

  return appName ? getFeatureByAppName(appName) : undefined;
};

export const getAppNameByDefId = (apps: Apps, appDefId: string): AppName | undefined => {
  const [appName] = Object.entries(apps).find(([, currentAppDefId]) => currentAppDefId === appDefId) ?? [];
  return appName as AppName | undefined;
};
