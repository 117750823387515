import React, { useEffect } from 'react';
import cs from 'classnames';
import { injectStoresV2 } from '../../../../stores/injectStoresV2';
import { GallerySearchSuggestionItem, SearchWithSuggestionsStore } from '../../../../stores/SearchWithSuggestionsStore';
import { WithTranslations, withTranslations } from '../../../../../utils/withTranslations';
import { suggestionsListItemDataHooks } from './SuggestionsListItem.dataHooks';
import { HighlightSearchCriteria } from './HighlightSearchCriteria/HighlightSearchCriteria';

import styles from './SuggestionsListItem.scss';

interface SuggestionsListItemProps extends WithTranslations {
  searchWithSuggestionsStore: SearchWithSuggestionsStore;
  onSearch: () => void;
  option: GallerySearchSuggestionItem;
  dataHook?: string;
}

const SuggestionsListItemCmp: React.FC<SuggestionsListItemProps> = ({
  onSearch,
  dataHook,
  option: { index, categoryOrSubCategorySlug, text },
  searchWithSuggestionsStore: { setHighlightedSuggestionIndex, preSelectedSuggestionIndex },
  t,
}) => {
  const itemRef = React.createRef<HTMLLIElement>();
  const isSelected = preSelectedSuggestionIndex === index;
  const onItemSelect = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setHighlightedSuggestionIndex(index);
    onSearch();
  };

  useEffect(() => {
    if (isSelected) {
      itemRef.current.scrollIntoView({ block: 'nearest', inline: 'start' });
    }
  }, [isSelected, itemRef]);

  return (
    <li
      className={cs(styles.suggestionsItem, { [styles.selected]: isSelected })}
      role="option"
      aria-selected={isSelected}
      ref={itemRef}
      onClick={onItemSelect}
      tabIndex={-1}
      data-hook={cs(dataHook, suggestionsListItemDataHooks.root({ selected: isSelected }))}
    >
      <span className={styles.suggestionsItemText}>
        <HighlightSearchCriteria
          text={text}
          isBlue={isSelected}
          className={styles.highlightedSymbols}
          dataHook={suggestionsListItemDataHooks.text()}
        />
        {categoryOrSubCategorySlug && (
          <span className={styles.categoryLabel} data-hook={suggestionsListItemDataHooks.categoryLabel()}>
            {t('search.dropdown.categoryLabel')}
          </span>
        )}
      </span>
    </li>
  );
};

export const SuggestionsListItem = withTranslations(
  injectStoresV2('searchWithSuggestionsStore')(SuggestionsListItemCmp),
);
